<template>
  <div
    ref="postItemRef"
    :style="{maxHeight:`${maxHeight}px`}"
    class="com-post-item"
  >
    <PostTitle
      :title="title"
      :flag="postInfo ? Number(postInfo.flag) : 0"
      class="post-title"
    />
    <PostAuthor
      ref="postAuthorRef"
      :creator-info="creatorInfo"
      :uid="uid"
      :avatar="avatar"
      :nickname="nickname"
      :view-num="viewNum"
      :user-level="userLevel"
      :follow-status.sync="followStatus"
      :account-type="accountType"
      :vip-type="vipType"
      :publish-time="publishTime"
      :current-time="currentTime"
      :data-source="dataSource"
      @changeUserFollowStatus="onChangeUserFollowStatusHandler"
      class="post-author"
    />
    <!--  <PostSummary-->
    <!--    v-if="!postInfo.content && postInfo.summary"-->
    <!--    :summary="postInfo.summary"-->
    <!--  />-->
    <PostText
      :content="contentComputed"
      :pics="postInfo.pics"
      :channel="postInfo.channel"
      :post-main="postMain"
      :vote-info="voteInfo"
      @clickVoteOption="handleClickVoteOption"
      @clickCommentButton="handleClickCommentButton"
    />

    <template
      v-if="postInfo.components && postInfo.components.components"
    >
      <template
        v-for="(comp, index) in postInfo.components.components"
      >
        <!-- 隐私保护需求 隐藏游戏组件 -->
        <!-- <GameDownload v-if="Number(comp.type)===1 && !isAndroidSwitchOn"
        :key="index"
        :game-code="comp.gameCode"
        :index="index"
        class="post-component-item"
      /> -->
        <PostGift
          v-if="Number(comp.type)===2 && !isSwitchOn"
          :key="index"
          :game-code="comp.gameCode"
          :package-id="comp.packageId"
          :index="index"
          class="post-component-item"
        />
        <PostTopic
          v-else-if="Number(comp.type)===3 && $compareVersion('1.2') > -1"
          :id="comp.topicId"
          :key="index"
          :index="index"
          class="post-component-item"
        />
      </template>
    </template>
    <game-lottery
      v-if="postInfo.components && postInfo.components.lotteryId && !isSwitchOn"
      :post-id="postInfo.id"
      :activity-id="postInfo.components && postInfo.components.lotteryId"
    />

    <span class="ip-location-info-text">发布于 {{ postInfo.ipRegionStr || '未知' }}</span>
    <div
      class="group-info"
    >
      <IconLabel
        v-if="groupInfo && groupInfo.name"
        v-show="dataSource === 'cgi' || (postInfo.extent && postInfo.extent.ifComplete === true)"
        :pic-url="groupInfo.iconUrl"
        :text="groupInfo.name"
        @click.native="$checkAppDeco(onGroupClickHandler);onGroupClickHandlerReport()"
        @updated.once="onGroupLabelLayout"
        style="margin-right:4px"
      />
      <IconLabel
        v-if="topicInfo && topicInfo.title"
        :text="topicInfo.title"
        @click.native="$checkAppDeco(onTopicClickHandler, topicInfo.id);onTopicClickHandlerReport();"
        @updated.once="onTopicLabelLayout"
        icon="topic"
        style="max-width:154px"
      />
    </div>

    <!-- 展开全文 -->
    <template v-if="isShow">
      <div
        v-if="!isOpenText"
        class="post-status-open"
      >
        <div class="post-status-open-top" />
        <div class="post-status-open-bottom">
          <span @click="onOpenTextHandler">展开全文</span>
          <img
            @click="onOpenTextHandler"
            src="./img/icon-arrow-bottom.png"
            class="post-status-icon"
          >
        </div>
      </div>
      <div
        v-else
        class="post-status-close"
      >
        <span @click="onCloseTextHandler">收起</span>
        <img
          @click="onCloseTextHandler"
          src="./img/icon-arrow-top.png"
          class="post-status-icon"
        >
      </div>
    </template>
  </div>
</template>

<script>
import PostTitle from '@/component/post-title/index.vue';
import PostAuthor from '@/component/post-author/index.vue';
import PostText from '@/component/post-text/index.vue';
// import PostSummary from '@/component/post-summary/index.vue';
import PostGift from '@/component/post-gift/index.vue';
import PostTopic from '@/component/post-topic/index.vue';
import GameLottery from '@/component/game-lottery/index.vue';
import { checkEnv } from '@/util/browser-env';
import { openView } from '@/jsbridge';
import IconLabel from '@/ui/label/icon-label.vue';
import { isServerRender } from '@/util/env';

export default {
  name: 'ComPostItem',
  components: {
    PostTitle,
    PostAuthor,
    PostText,
    PostGift,
    // PostSummary,
    PostTopic,
    GameLottery,
    IconLabel,
  },
  props: {
    creatorInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    uid: {
      type: [String, Number],
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    avatar: {
      type: String,
      default: '',
    },
    nickname: {
      type: String,
      default: '',
    },
    viewNum: {
      type: [String, Number],
      default: 0,
    },
    userLevel: {
      type: Number,
      default: 1,
    },
    followStatus: {
      type: Number,
      default: 0,
    },
    accountType: {
      type: Number,
      default: 0,
    },
    vipType: {
      type: Number,
      default: 0,
    },
    publishTime: {
      type: Number,
      default: 0,
    },
    currentTime: {
      type: Number,
      default: 0,
    },
    content: {
      type: String,
      default: '',
    },
    postInfo: {
      type: Object,
      default() {
        return null;
      },
    },
    postMain: {
      type: String,
      default: '',
    },
    dataSource: { // 数据来源
      type: String,
      default: 'cgi',
    },
    /** 投票信息 */
    voteInfo: {
      type: Object,
      default: () => null,
    },
    groupInfo: {
      type: Object,
      default: () => null,
    },
    topicInfo: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    return {
      isSwitchOn: checkEnv().isSwitchOn,
      isAndroidSwitchOn: checkEnv().isAndroidSwitchOn,
      isOpenText: false,
      // 内容默认高度 是不限制高度
      defaultHeight: 1000000000,
      maxHeight: this.defaultHeight,
      // 是否展示 展开全文
      isShow: false,
    };
  },
  computed: {
    isApp() {
      return this.$store.state.isApp;
    },
    contentComputed() {
      // console.log('contentComputed', this.postInfo);
      if (this.postInfo.content) {
        return this.postInfo.content;
      } if (this.postInfo.extent && this.postInfo.extent.preRichText) {
        // console.log('this.postInfo.extent.preRichText', this.postInfo.extent.preRichText);
        let html = this.postInfo.extent.preRichText;
        const htmlAllIndex = html.length - 1;
        const rightArrowIndex = html.lastIndexOf('>');
        const lastImgIndex = html.lastIndexOf('<img');
        // 如果最后一个标签只有四个字符 或者img标签最后且不完整 直接去掉
        if (((htmlAllIndex - rightArrowIndex) <= 4) || (rightArrowIndex < lastImgIndex)) {
          html = html.slice(0, rightArrowIndex + 1);
        }
        return html;
      }
      // console.log('this.postInfo.summary', this.postInfo.summary);
      return this.postInfo.summary;
    },
  },
  watch: {
    isOpenText: {
      handler() {
        const { isApp, isOpenText } = this;
        if (!isApp && !isServerRender) {
          const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
          const height = clientHeight - 80;
          this.maxHeight = isOpenText ? this.defaultHeight : height;
        }
      },
      immediate: true,
    },
  },
  mounted() {
    if (!this.isApp) {
      this.$nextTick(() => {
        const height = this.$refs.postItemRef.offsetHeight;
        //  20 是 展开全文遮挡的高度
        if (Number(height) >= Number(this.maxHeight) - 20) this.isShow = true;
      });
    }
  },
  inject: {
    parentReportData: {
      from: 'reportData',
      default: null,
    },
  },
  // 用于修改子组件参数
  provide() {
    if (this.parentReportData) {
      return {
        reportData: (params, ...rest) => this.parentReportData({
          ...params,
        }, ...rest),
      };
    }
    return null;
  },
  methods: {
    onChangeUserFollowStatusHandler(payload) {
      this.$emit('changeUserFollowStatus', payload);
    },
    handleClickCommentButton(commentPrefix, needDelayPostComment) {
      this.$emit('clickCommentButton', commentPrefix, needDelayPostComment);
    },
    handleClickVoteOption(voteInfo) {
      this.$emit('clickVoteOption', voteInfo);
    },

    onOpenTextHandler() {
      this.isOpenText = true;
    },
    onCloseTextHandler() {
      this.isOpenText = false;
    },
    onGroupClickHandler() {
      this.$linkToGroup(this.groupInfo.id);
    },

    onTopicClickHandler(topicId) {
      openView({
        options: `tgc://native?moduleName=topic&topicId=${topicId}`,
      });
    },

    onGroupClickHandlerReport() {
      this.parentReportData({
        moduleid: 33,
        eventtype: 3,
        extendid: 1,
        ext2: 3,
      }, '帖子详情页所属圈子点击');
    },
    onTopicClickHandlerReport() {
      this.parentReportData({
        pageid: 1501,
        moduleid: 52,
        eventtype: 3,
        extendid: 2,
      }, '所有内容详情页话题入口点击');
      this.parentReportData({
        moduleid: 52,
        eventtype: 3,
        extendid: 2,
      }, '图文详情页话题入口点击');
    },
    onTopicLabelLayout() {
      this.parentReportData({
        pageid: 1501,
        moduleid: 52,
        eventtype: 2,
        extendid: 1,
      }, '所有内容详情页话题入口曝光');
      this.parentReportData({
        moduleid: 52,
        eventtype: 2,
        extendid: 1,
      }, '图文详情页话题入口曝光');
    },
    onGroupLabelLayout() {
      this.parentReportData({
        moduleid: 33,
        eventtype: 2,
        extendid: 1,
        ext2: 3,
      }, '帖子详情页所属圈子曝光');
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
