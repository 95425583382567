<template>
  <div
    :style="{background:bgColor}"
    class="com-game-info"
  >
    <div class="com-game-info-top">
      <img
        :src="innerGameInfo.iconUrl"
        @click="$checkAppDeco(goIntroducePage)"
        class="com-game-info-logo"
      >
      <section class="com-game-info-game">
        <span
          :style="{fontSize:`${isGameIntroduce ? 20 : 14}px`}"
          class="com-game-info-game-nickname"
        >{{ innerGameInfo.name }}</span>
        <span class="com-game-info-game-nickname-info">
          <span
            v-if="!isGameIntroduce"
            class="com-game-info-game-nickname-info-line com-game-info-game-nickname-info-ellipsis"
          >{{ innerGameInfo.sortTags[0] }}</span>
          <span class="com-game-info-game-nickname-info-ellipsis">
            {{ gameInfo.subscribeStats.subscribeNumDesc || gameInfo.subscribeStats.subscribeNum }}人想玩
          </span>
        </span>
      </section>
      <div class="com-game-info-button">
        <Button
          v-if="getEnterGroup"
          :width="isGameIntroduce? 75 : 55"
          @click="$checkAppDeco(onGroupBtnClick)"
          class="com-game-info-button-enter"
        >
          进圈
        </Button>
        <Button
          v-show="!isWant"
          :width="isGameIntroduce? 75 : 55"
          :type="'secondary'"
          @click="$checkAppDeco(onBtnClick)"
          class="com-game-info-button-enter-margin com-new-game-want"
        >
          想玩
        </Button>
        <Button
          v-show="isWant"
          :width="isGameIntroduce? 75 : 55"
          @click="$checkAppDeco(onBtnClick)"
          :style="{background:isWantBgColor}"
          class="com-game-info-button-enter-margin com-new-game-no-want"
        >
          已想玩
        </Button>
      </div>
    </div>

    <div
      v-if="isShowHotInfo && !!gameInfo.contents.length"
      class="com-game-info-info"
    >
      <NewGameHot
        :hot-info="gameInfo.contents[0]"
        :show-game-name="false"
      />
    </div>
  </div>
</template>

<script>
import Button from '@/ui/button/index.vue';
import NewGameHot from '@/component/new-game-hot/index.vue';
import newGameApi from '@/api/newgame';
import reportApi from '@/api/report';
import jsbridge from '@/jsbridge';

export default {
  name: 'ComGameInfo',
  components: {
    Button,
    NewGameHot,
  },
  props: {
    gameInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    isGameIntroduce: {
      type: Boolean,
      default: false,
    },
    isShowHotInfo: {
      type: Boolean,
      default: false,
    },
    bgColor: {
      type: String,
      default: '#fff',
    },
    isShowEnterGroup: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      isLock: false,
    };
  },
  inject: ['onIsWantHandler', 'dialogShowHandler'],
  computed: {
    isLogin() {
      const { userInfo } = this.$store.state.user;
      if (userInfo?.uid && userInfo.uid !== 0) {
        return true;
      }
      return false;
    },
    innerGameInfo() {
      return this.gameInfo?.gameInfo;
    },
    isWant() {
      return this.gameInfo.subscribeStats.selfSubscribe;
    },
    isWantBgColor() {
      return this.bgColor === '#fff' ? '#f2f2f2' : this.bgColor;
    },
    getEnterGroup() {
      return this.gameInfo.groupId !== '0' && !this.isGameIntroduce && this.isShowEnterGroup;
    },
  },
  methods: {
    onBtnClick() {
      if (this.isLock) return;
      this.isLock = true;
      if (!this.isLogin) {
        jsbridge.user.login().then(() => {
          this.$store.dispatch('user/getUserInfo');
        });
        this.isLock = false;
        return;
      }
      if (!this.isWant) {
        newGameApi.subscribeNewGame(this.innerGameInfo.gameCode).then(() => {
          this.dialogShowHandler(true);
          this.onIsWantHandler();
          this.dataReport(true);
        })
          .catch((err) => {
            this.$toast(`预约失败~  errCode:${err.data.ret}`);
            this.logger.err('subscribeNewGame err', err);
          })
          .finally(() => {
            this.isLock = false;
          });
      } else {
        newGameApi.cancelSubscribeNewGame(this.innerGameInfo.gameCode).then(() => {
          this.dialogShowHandler(false);
          this.onIsWantHandler();
          this.dataReport(false);
        })
          .catch((err) => {
            this.$toast(`取消预约失败~  errCode:${err.data.ret}`);
            this.logger.err('cancelSubscribeNewGame err', err);
          })
          .finally(() => {
            this.isLock = false;
          });
      }
    },
    onGroupBtnClick() {
      this.$linkToGroup(this.gameInfo.groupId);
      reportApi({
        pageid: '2901',
        moduleid: 92,
        eventtype: 3,
        extendid: 1,
        gameCode: this.innerGameInfo.gameCode,
      }, '新游预约页-进圈按钮点击');
    },
    goIntroducePage() {
      if (!this.isGameIntroduce) {
        this.$router.push({ name: 'community-game-introduce', query: { gameCode: this.innerGameInfo.gameCode, isFromReservation: true, _gcwv: 2 } });
        reportApi({
          pageid: '2901',
          moduleid: 94,
          eventtype: 3,
          extendid: 1,
          gameCode: this.innerGameInfo.gameCode,
        }, '新游预约页-游戏图标点击');
      }
    },
    dataReport(isSubscribe) {
      const { isGameIntroduce } = this;
      const pageid = isGameIntroduce ? '2902' : '2901';
      const moduleid = isGameIntroduce ? 96 : 93;
      reportApi({
        pageid,
        moduleid,
        eventtype: 3,
        extendid: isSubscribe ? 1 : 2,
        gameCode: this.innerGameInfo.gameCode,
      }, `新游${isGameIntroduce ? '介绍详情' : '预约'}页-想玩按钮点击 (${isSubscribe ? '' : '取消'}预约)`);
    },
  },
};
</script>

<style lang="scss"  src="./scss/index.scss"></style>
