<template>
  <button
    :class="[disabled ? 'disabled' : '', type]"
    :disabled="disabled"
    class="lottery-button"
  >
    <slot />
  </button>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss" src="./scss/index.scss"></style>
