import { gameplusApi } from '@/util/http';

/**
 * 获取话题组件信息
 */
// eslint-disable-next-line import/prefer-default-export
export function getTopicInfo({ topicId }) {
  return gameplusApi.request({
    url: '/community.PostSrv/GetOneTopic',
    data: {
      topicId,
    },
  });
}
