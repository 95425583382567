<template>
  <div
    v-if="giftPackageInfo"
    class="c-post-gift"
  >
    <div class="c-post-gift__header">
      <div class="c-post-gift__header-left">
        <div class="c-post-gift__header-title">
          {{ giftPackageInfo.packageTitle }}
        </div>
        <div class="c-post-gift__header-tag-list">
          <div
            v-for="(tag, tagIndex) in giftPackageInfo.limitDesc"
            :key="tagIndex"
            class="c-post-gift__header-tag-item"
          >
            {{ tag }}
            <span class="c-post-gift__header-tag-item__text">{{ tag }}</span>
          </div>
        </div>
      </div>
      <div class="c-post-gift__header-right">
        <PillButton
          v-if="giftPackageInfo.giftStatus===0 && giftPackageInfo.giftType === 2"
          :left-text="`${giftPackageInfo.point}积分`"
          @click="$checkAuthDeco(onGiftBtnClickHandler);onGiftBtnClickHandlerReport()"
          right-text="兑换"
        />
        <Button
          v-else-if="giftPackageInfo.giftStatus===0"
          @click="$checkAuthDeco(onGiftBtnClickHandler);onGiftBtnClickHandlerReport()"
          type="secondary"
          class="c-post-gift__button"
          size="m"
        >
          {{ giftPackageInfo.btnDefaultText }}
        </Button>
        <Button
          v-else-if="giftPackageInfo.giftStatus===1"
          class="c-post-gift__button"
          size="m"
          type="disabled"
          disabled
        >
          {{ giftPackageInfo.btnUnavailableText }}
        </Button>
      </div>
    </div>
    <div class="c-post-gift__divider" />
    <div class="c-post-gift__list">
      <div
        v-for="(giftItem, itemIndex) in giftPackageInfo.giftItems"
        :key="giftItem.title+itemIndex"
        class="c-post-gift__item"
      >
        <div class="c-post-gift__item-cover">
          <img :src="giftItem.url">
          <div
            v-if="giftItem.description"
            class="c-post-gift__item-desc"
          >
            {{ giftItem.description }}
          </div>
        </div>
        <div class="c-post-gift__item-name">
          {{ giftItem.title }}
        </div>
      </div>
    </div>
    <Dialog
      v-model="dialogShow"
      :confirm-text="giftPackageInfo.giftType === 2 ? '确定兑换' : '确定领取'"
      :confirm-close="false"
      :confirm-text-style="{color: computedSelectedRole?'#02A2FF':'#999'}"
      :content-style="{padding: showResentRole?'0':''}"
      @confirm="onGiftDialogConfirm"
      confirm
    >
      <template v-if="!showResentRole">
        <div class="c-post-gift__dialog-title">
          选择区服角色
        </div>
        <div class="c-post-gift__dialog-group">
          <!-- 游戏区服（大区） -->
          <div
            v-if="areaChannelList.length > 0"
            class="c-post-gift__dialog-cell"
          >
            <Select
              :value="currentSelectAreaChannel && currentSelectAreaChannel.value"
              :class="{'placeholder': !currentSelectAreaChannel}"
              :list="areaChannelList"
              @change="onAreaChannelSelectChange"
              class="text"
              placeholder="请选择游戏区服（大区）"
              key-name="value"
              value-name="name"
            />
            <div class="icon" />
          </div>
          <div class="c-post-gift__dialog-cell">
            <!-- <div class="text"
            :class="{'placeholder': !currentSelectPartition}"
            @click="onPartionCellClick"
          >
            {{ currentSelectPartition ? currentSelectPartition.name
              : partitionError || '请选择游戏大区' }}
          </div> -->
            <Select
              :value="currentSelectPartition && currentSelectPartition.value"
              :class="{'placeholder': !currentSelectPartition}"
              :list="partitionList"
              @change="onPartitionSelectChange"
              class="text"
              placeholder="请选择游戏区服"
              key-name="value"
              value-name="name"
            />
            <div class="icon" />
          </div>
          <div class="c-post-gift__dialog-cell ui-border-t">
            <!-- <div class="text"
            :class="{'placeholder': !currentSelectRole}"
            @click="onRoleCellClick"
          >
            {{ currentSelectRole ? currentSelectRole.name
              : roleError || '请选择角色' }}
          </div> -->
            <Select
              :value="currentSelectRole && currentSelectRole.value"
              :class="{'placeholder': !currentSelectRole}"
              :placeholder="roleError || '请选择角色'"
              :list="roleList"
              @change="onRoleSelectChange"
              class="text"
              key-name="value"
              value-name="name"
            />
            <div class="icon" />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="c-post-gift__dialog--resent-header">
          <div class="c-post-gift__dialog--resent-header-title">
            最近活跃角色
          </div>
          <div
            @click="onChooseOtherPartitionClickHandler"
            class="c-post-gift__dialog--resent-header-other-btn"
          >
            选择其他
          </div>
        </div>
        <div class="c-post-gift__dialog--resent-list">
          <div
            v-for="(role, roleIndex) in recentRoleList"
            :key="role.id+roleIndex"
            :class="{active: computedSelectedRole&&computedSelectedRole.id===role.id}"
            @click="onResentRoleCellClickHandler(role)"
            class="c-post-gift__dialog--resent-item"
          >
            <div class="c-post-gift__dialog--resent-item-inner">
              <div class="c-post-gift__dialog--resent-item-main">
                <div class="c-post-gift__dialog--resent-item-cell">
                  角色: <span class="c-post-gift__dialog--resent-item-cell-name">{{ role.roleName }}</span>
                </div>
                <div class="c-post-gift__dialog--resent-item-cell c-post-gift__dialog--resent-item-cell-info">
                  {{ role.partitionName }}
                </div>
              </div>
              <div
                v-show="computedSelectedRole&&computedSelectedRole.id===role.id"
                class="c-post-gift__dialog--resent-item-icon-check"
              />
            </div>
          </div>
        </div>
      </template>
    </Dialog>
    <!-- 旧版本，废弃 -->
    <!-- <Dialog
    v-model="pickDialogShow"
    confirm
    :confirm-text-style="{color:selectedPick?'#02A2FF':'#999'}"
    :confirm-close="false"
    @confirm="onPickDialogConfirm"
    @cancel="onPickDialogCancel"
  >
    <div class="c-post-gift__picker-list">
      <div
        v-for="(item) in pickList"
        :key="item.vkey"
        class="c-post-gift__picker-item"
        @click="selectedPick = item"
      >
        <Radio :checked="selectedPick&&selectedPick.vkey===item.vkey">
          <div class="name">
            {{ item.name }}
          </div>
        </Radio>
      </div>
    </div>
  </Dialog> -->
    <!-- 领取结果提示 -->
    <Dialog
      v-model="giftResultShow"
      type="celebrate"
    >
      <div class="c-post-gift__result-dialog">
        <div
          v-if="resultDialogInfo.title"
          class="title"
        >
          {{ resultDialogInfo.title }}
        </div>
        <div class="msg">
          {{ resultDialogInfo.msg }}
        </div>
        <div
          v-if="resultDialogInfo.packageTitle"
          class="package-title"
        >
          {{ resultDialogInfo.packageTitle }}
        </div>
        <div class="gift-list">
          <div
            v-for="(gift, giftIndex) in resultDialogInfo.gifts"
            :key="giftIndex"
            class="gift-item"
          >
            <div class="name">
              {{ gift.title }}
            </div>
            <div class="desc">
              *{{ gift.description }}
            </div>
          </div>
        </div>
        <div
          slot="footer"
          v-if="resultDialogInfo.btn"
          class="btn"
        >
          <Button
            @click="onResultDialogBtnClickHandler"
            type="primary"
            size="l"
          >
            {{ resultDialogInfo.btn }}
          </Button>
        </div>
      </div>
    </Dialog>
    <!-- 条件不足弹窗 start -->
    <Dialog
      :show="failDialogInfo.show"
      :content-title="failDialogInfo.title"
      :content-text="failDialogInfo.msg"
      :cancel-text="(!failDialogInfo.url && failDialogInfo.btn) || '好的哦'"
      :confirm-text="failDialogInfo.btn"
      :show-confirm-btn="(!!failDialogInfo.btn && !!failDialogInfo.url)"
      @cancel="failDialogInfo.show = false"
      @confirm="onFailDailogConfirm"
      confirm
    />
    <!-- 条件不足弹窗 end -->
    <!-- 切换账号弹窗 start -->
    <Dialog
      :show="switchDialogInfo.show"
      :content-title="switchDialogInfo.title"
      :content-text="switchDialogInfo.msg"
      :cancel-text="switchDialogInfo.cancelText"
      :confirm-text="switchDialogInfo.confirmText"
      :show-confirm-btn="true"
      @cancel="switchDialogInfo.show = false"
      @confirm="onSwitchDialogConfirm"
      confirm
    />
  <!-- 切换账号弹窗 end -->
  </div>
</template>

<script>
import Button from '@/ui/button/index.vue';
import giftApi from '@/api/gift';
import Dialog from '@/ui/dialog/index.vue';
import Select from '@/ui/select/index.vue';
import { decode } from '@/util/base64';
import { pushView } from '@/jsbridge';
import { logger } from '@/util/logger';
import PillButton from './component/pill-button/index.vue';
import {
  getAreaChannelList,
  getPartitionList,
  getPartitionRoleList,
  getUserRoleList,
  userAddRole,
} from '@/util/http/ams-api';

export default {
  name: 'CPostGift',
  components: {
    Button,
    Dialog,
    Select,
    PillButton,
  },
  inject: ['reportData'],
  props: {
    gameCode: {
      type: String,
      default: '',
    },
    packageId: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 1,
    },
  },
  data() {
    return {
      giftPackageInfo: null,
      gameInfo: null,
      dialogShow: false,
      pickDialogShow: false,
      areaChannelList: [],
      currentSelectAreaChannel: null,
      partitionList: [],
      selectedPick: null,
      currentSelectPartition: null,
      currentSelectRole: null,
      pickList: [],
      pickType: '',
      partitionError: null,
      roleError: null,
      roleList: [],
      recentRoleList: [],
      giftResultShow: false,
      resultDialogInfo: {
        title: '领取成功',
        gifts: [],
        btn: '知道啦',
      },
      showResentRole: false,
      currentSelectResentRole: null,
      failDialogInfo: {
        show: false,
        title: '温馨提示',
        msg: '系统异常，请稍后再试',
        btn: '',
        url: '',
      },
      checkLoading: false,
      switchDialogInfo: {
        show: false,
        title: '账号切换',
        msg: '兑换端游礼包需切换至你的"QQ账号"，同时会退出当前"微信账号"。',
        cancelText: '取消',
        confirmText: '去切换',
        url: '',
      },
    };
  },
  computed: {
    computedSelectedRole() {
      if (this.showResentRole && this.currentSelectResentRole) {
        return {
          id: this.currentSelectResentRole.id,
          roleId: this.currentSelectResentRole.id,
          partitionId: this.currentSelectResentRole.data.partitionId,
          roleName: this.currentSelectResentRole.roleName,
          partitionName: this.currentSelectResentRole.partitionName,
          data: this.currentSelectResentRole.data,
        };
      } if (this.currentSelectRole) {
        return {
          id: this.currentSelectRole.roleId,
          roleId: this.currentSelectRole.roleId,
          partitionId: this.currentSelectRole.data.partitionId,
          roleName: this.currentSelectRole.roleName,
          partitionName: this.currentSelectRole.partitionName,
          data: this.currentSelectRole.data,
        };
      }
      return null;
    },
    isApp() {
      return this.$store.state.isApp;
    },
    userInfo() {
      return this.$store.state.user.userInfo;
    },
  },
  mounted() {
    this.getGifts();
  },
  methods: {
    getGifts() {
      giftApi.getGifts({
        gameCode: this.gameCode,
        packageIds: [this.packageId],
      }).then((res) => {
        if (res.gift && res.gift[0]) {
          [this.giftPackageInfo] = res.gift;
          this.gameInfo = res.gameInfo || {};
          if (this.reportData) {
            this.reportData({
              moduleid: 43,
              eventtype: 2,
              extendid: 1,
              gamename: this.gameInfo.name || '',
              indexid: this.index,
            }, '礼包组件曝光');
          }
          if (this.isApp) {
            this.getUserRole();
          }
        } else {
          logger.info('getGifts 获取礼包为空', res);
        }
      })
        .catch((err) => {
          logger.err('getGift error', err);
        });
    },
    // 获取用户近期活跃角色
    getUserRole() {
      if (this.recentRoleList.length > 0) {
        return Promise.resolve(true);
      }
      return getUserRoleList({
        gameCode: this.gameCode,
      }).then((res) => {
        if (res.roles && res.roles.length > 0) {
          this.recentRoleList = res.roles.map(item => ({
            id: `${item.roleId}:${item.roleName}`, // 唯一标识
            roleName: decode(item.roleName),
            partitionName: decode(item.partitionName),
            data: item,
          }));
          [this.currentSelectResentRole] = this.recentRoleList;
          return Promise.resolve(res);
        }
        return Promise.resolve(res);
      })
        .catch((err) => {
          if (err && err.data && err.data.msg) {
            this.$toast(err.data.msg);
          }
          logger.info('获取最近游戏角色失败', err);
          return Promise.reject(err);
        });
    },
    getPartitionList(areaChannelId) {
      this.partitionError = null;
      return getPartitionList({
        gameCode: this.gameCode,
        areaChannelId,
      }).then((res) => {
        if (res.partitionList && res.partitionList.length > 0) {
          this.partitionList = res.partitionList.map(item => ({
            value: `${item.id}:${item.name}`,
            id: item.id,
            name: decode(item.name),
          }));
          if (this.partitionList.length === 1 && this?.areaChannelList?.length > 0) {
            [this.currentSelectPartition] = this.partitionList;
            this.getRoleList();
          }
          // if (this.partitionList.length === 1) {
          //   this.currentSelectPartition = this.partitionList[0];
          //   this.selectedPick = this.currentSelectPartition;
          //   this.pickType = 'partition';
          // }
          // console.log('partitionList', this.partitionList);
        } else {
          this.partitionError = '未查到游戏区服';
        }
        return Promise.resolve(res);
      })
        .catch((err) => {
          if (err && err.data && err.data.msg) {
            this.$toast(err.data.msg);
          } else {
            this.$toast('获取游戏区服失败');
          }
          return Promise.reject(err);
        });
    },
    // 游戏区服（大区）
    getAreaChannelList() {
      this.partitionError = null;
      return getAreaChannelList({
        gameCode: this.gameCode,
      }).then((res) => {
        if (res.areaChannelList && res.areaChannelList.length > 0) {
          this.areaChannelList = res.areaChannelList.map(item => ({
            value: `${item.id}:${item.name}`,
            id: item.id,
            name: decode(item.name),
          }));
        } else {
          this.partitionError = '未查到游戏区服（大区）';
        }
        return res;
      })
        .catch((err) => {
          if (err && err.data && err.data.msg) {
            this.$toast(err.data.msg);
          }
          logger.info('获取游戏区服（大区）失败', err);
        });
    },
    onGiftBtnClickHandlerReport() {
      if (this.reportData) {
        this.reportData({
          moduleid: 43,
          eventtype: 3,
          extendid: 1,
          gamename: this.gameInfo.name || '',
          indexid: this.index,
        }, '礼包组件按钮点击');
      }
    },
    checkGift() {
      return giftApi.checkGift({
        gameCode: this.gameCode,
        packageId: this.packageId,
      }).then(res => Promise.resolve(res))
        .catch((err) => {
          this.dialogShow = false;
          if (err && err.data && err.data.failInfo) {
            this.failDialogInfo = {
              title: '温馨提示',
              msg: err.data.failInfo.msg || '系统异常，请稍后再试',
              btn: err.data.failInfo.buttonText,
              url: err.data.failInfo.jumpUrl,
              show: true,
            };
          } else if (err && err.data && err.data.msg) {
            this.failDialogInfo = {
              title: '温馨提示',
              msg: err.data.msg || '系统异常，请稍后再试',
              btn: '',
              url: '',
              show: true,
            };
          } else {
            this.$toast('检查礼包失败，请稍后再试');
            logger.error('检查礼包checkGift失败', err);
          }
          return Promise.reject(err);
        });
    },
    onGiftBtnClickHandler() {
      logger.info('onGiftBtnClickHandler 礼包按钮点击', this.checkLoading);
      if (!this.giftPackageInfo || this.checkWxUserAndPcGift() || this.checkLoading) return;
      this.checkLoading = true;
      this.checkGift().then(() => {
        this.getUserRole().finally(() => {
          if (this.recentRoleList.length > 0) {
            this.showResentRole = true;
            this.dialogShow = true;
            this.checkLoading = false;
          } else {
            this.getAreaChannelList().then(() => {
              this.showResentRole = false;
              this.dialogShow = true;
              if (this?.areaChannelList?.length <= 0) {
                this.getPartitionList().then(() => {
                  this.showResentRole = false;
                  this.dialogShow = true;
                })
                  .finally(() => {
                    this.checkLoading = false;
                  });
              }
            })
              .finally(() => {
                this.checkLoading = false;
              });
          }
        });
      })
        .catch(() => {
          this.checkLoading = false;
        });
    },
    // 旧版本，废弃
    onPartionCellClick() {
      if (this.partitionList.length > 0) {
        this.dialogShow = false;
        this.pickDialogShow = true;
        this.selectedPick = this.currentSelectPartition;
        this.pickList = this.partitionList.map((item, index) => ({
          ...item,
          vkey: `key_${index}`,
        }));
        this.pickType = 'partition';
        if (this.partitionList.length === 1) {
          [this.selectedPick] = this.partitionList;
        }
      } else {
        this.getPartitionList();
      }
    },
    onPickDialogConfirm() {
      if (!this.selectedPick) return;
      if (this.pickType === 'partition') {
        this.currentSelectPartition = this.selectedPick;
        this.getRoleList();
      } else if (this.pickType === 'role') {
        this.currentSelectRole = this.selectedPick;
      }
      this.selectedPick = null;
      this.dialogShow = true;
      this.pickDialogShow = false;
    },
    onPickDialogCancel() {
      this.selectedPick = null;
    },
    onRoleCellClick() {
      if (this.roleList.length > 0) {
        this.dialogShow = false;
        this.selectedPick = this.currentSelectRole;
        this.pickList = this.roleList.map((item, index) => ({
          ...item,
          vkey: `key_${index}`,
        }));
        this.pickType = 'role';
        this.pickDialogShow = true;
        if (this.roleList.length === 1) {
          [this.selectedPick] = this.roleList;
        }
      }
    },
    getRoleList() {
      this.roleError = null;
      return getPartitionRoleList({
        gameCode: this.gameCode,
        areaChannelId: String(this?.currentSelectAreaChannel?.id) === 'undefined' ? '' : String(this?.currentSelectAreaChannel?.id),
        partitionId: this.currentSelectPartition.id,
      }).then((res) => {
        if (res.roles && res.roles.length > 0) {
          this.roleList = res.roles.map(item => ({
            value: `${item.roleId}:${item.roleName}`, // roleId会重复，这里加个唯一标识
            id: item.roleId,
            name: decode(item.roleName),
            data: item,
          }));
          if (this.roleList.length === 1) {
            [this.currentSelectRole] = this.roleList;
          }
          // console.log('roleList', this.roleList);
        } else {
          this.roleList = [];
          this.roleError = '未查到角色';
        }
      })
        .catch(() => {
          this.roleList = [];
          this.currentSelectRole = null;
          this.roleError = '未查到角色';
        });
    },
    onGiftDialogConfirm() {
      if (!this.computedSelectedRole) {
        this.$toast('请先选择角色');
        return;
      }
      this.receiveGift();
    },
    // 领取礼包
    receiveGift() {
      if (!this.computedSelectedRole) return;
      giftApi.receiveGift({
        gameCode: this.gameCode,
        packageId: this.packageId,
        role: this.computedSelectedRole.data,
      }).then((res) => {
        userAddRole({
          gameCode: this.gameCode,
          role: this.computedSelectedRole.data,
        });
        this.resultDialogInfo = {
          title: (res.gift && res.gift.giftType === 1) ? '领取成功' : '兑换成功',
          msg: decode(res.tips),
          packageTitle: res.gift.packageTitle,
          gifts: res.gift && res.gift.giftItems,
          btn: '知道啦',
        };
        this.giftPackageInfo.giftStatus = 1;
        this.giftResultShow = true;
        this.dialogShow = false;
      })
        .catch((err) => {
          this.dialogShow = false;
          if (err && err.data && err.data.failInfo) {
            this.resultDialogInfo = {
              msg: err.data.failInfo.msg,
              gifts: [],
              btn: err.data.failInfo.buttonText,
              url: err.data.failInfo.jumpUrl,
            };
            this.giftResultShow = true;
          } else if (err && err.data && err.data.msg) {
            this.$toast(err.data.msg);
          } else {
            this.$toast('领取失败');
          }
        });
    },
    onResentRoleCellClickHandler(role) {
      this.currentSelectResentRole = role;
    },
    onChooseOtherPartitionClickHandler() {
      this.getPartitionList().then(() => {
        this.showResentRole = false;
      });
    },
    onResultDialogBtnClickHandler() {
      if (this.resultDialogInfo.url) {
        pushView(this.resultDialogInfo.url);
      }
      this.giftResultShow = false;
    },
    onFailDailogConfirm() {
      if (this.failDialogInfo.url) {
        const { url } = this.failDialogInfo;
        setTimeout(() => {
          pushView(url);
        }, 100);
      }
      logger.info('onFailDailogConfirm 点击跳转', this.failDialogInfo);
      this.failDialogInfo = {
        show: false,
        title: '温馨提示',
        msg: '系统异常，请稍后再试',
        btn: '',
        url: '',
      };
    },
    // 点击选择游戏区服（大区）
    onAreaChannelSelectChange(value) {
      const currentSelect = this.areaChannelList.find(item => item.value === value);
      logger.info('点击选择游戏区服（大区） onAreaChannelSelectChange', value, currentSelect);
      if (currentSelect) {
        this.roleError = null;
        this.currentSelectPartition = '';
        this.currentSelectRole = '';
        this.currentSelectAreaChannel = currentSelect;
        this.getPartitionList(String(currentSelect?.id));
      }
    },
    // 点击选择游戏区服
    onPartitionSelectChange(value) {
      const currentSelect = this.partitionList.find(item => item.value === value);
      logger.info('点击选择游戏区服 onPartitionSelectChange', value, currentSelect);
      if (currentSelect) {
        this.currentSelectPartition = currentSelect;
        this.getRoleList();
      }
    },
    // 点击选择角色
    onRoleSelectChange(value) {
      const currentSelect = this.roleList.find(item => item.value === value);
      logger.info('点击选择角色 onRoleSelectChange', value, currentSelect);

      this.currentSelectRole = currentSelect;
    },
    /**
     * 检测用户是否微信账号且是端游礼包，是则提示用户切换账号
     * */
    checkWxUserAndPcGift() {
      if (this.$compareVersion('1.8.4') > -1 && this?.userInfo?.loginType === 1 && this?.gameInfo?.type === 2 && !this?.gameInfo?.isPcGameAndSupportWx) {
        this.switchDialogInfo.show = true;
        this.reportData({
          operid: '1408000010204',
          gamename: this?.gameInfo?.name || '',
          appid: this?.gameInfo?.gameCode || '',
          ext1: 3,
        }, '切换账号弹窗曝光');
        return true;
      }
      return false;
    },
    onSwitchDialogConfirm() {
      this.switchDialogInfo.show = false;
      this.reportData({
        operid: '1408000010306',
        gamename: this?.gameInfo?.name || '',
        appid: this?.gameInfo?.gameCode || '',
        ext1: 3,
      }, '切换账号弹窗点击切换');
      try {
        this.$store.dispatch('user/login', { type: 'QQ' }).then(() => {
          // console.log('登录回调：', result);
          this.getGifts();
        });
      } catch (e) {
        logger.error('切换QQ登录异常：', e);
      }
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
