<template>
  <div
    v-if="topicInfo"
    class="com-post-topic"
  >
    <div class="com-post-topic__inner">
      <div class="com-post-topic__cover">
        <img
          :src="topicInfo.iconImg"
          class="com-post-topic__cover-img"
        >
        <div class="com-post-topic__cover-icon" />
      </div>
      <div class="com-post-topic__content">
        <div class="com-post-topic__text">
          #{{ topicInfo.title }}
        </div>
      </div>
      <div class="com-post-topic__button">
        <Button @click="$checkAppDeco(jumpToTopic)">
          参与话题
        </Button>
      </div>
    </div>
  </div>
</template>

<script>
import Button from '@/ui/button';
import { getTopicInfo } from '@/api/topic';
import { openView } from '@/jsbridge';

export default {
  name: 'ComPostTopic',
  components: {
    Button,
  },
  props: {
    id: {
      type: [String, Number],
      default: '',
    },
  },
  data() {
    return {
      topicInfo: null,
    };
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      if (!this.id) return;
      getTopicInfo({ topicId: this.id })
        .then((res) => {
          this.topicInfo = res.topic;
        })
        .catch(() => {
          //
        });
    },
    jumpToTopic() {
      openView({
        options: `tgc://native?moduleName=topic&topicId=${this.topicInfo.id}`,
      });
    },
  },
};
</script>

<style lang="scss">
@import '~@/ui/style/mixin.scss';
@import '~@/ui/style/variable.scss';

.com-post-topic {
  box-sizing: border-box;
  width: 100%;
  height: 58px;
  box-shadow: 0 1px 6px 0 rgba(0,0,0,0.1);
  border-radius: 6px;
  padding: 9px 10px;
  &__inner {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
  }
  &__cover {
    position: relative;
    width: 40px;
    height: 40px;
  }
  &__cover-img {
    width: 100%;
    height: 100%;
    border-radius: 6px;
    border: 0.5px solid rgba(0,0,0,0.05);
    overflow: hidden;
  }
  &__cover-icon {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 14px;
    height: 14px;
    background: url('./img/icon_28_topic_circle.png') center /100% 100% no-repeat;
  }
  &__content {
    flex: 1;
    padding: 0 18px 0 10px;
    height: 100%;
  }
  &__text {
    @include ui-line-2;
    font-size: 14px;
    color: #303133;
    font-weight: bold;
  }
}
</style>
