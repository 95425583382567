<template>
  <div
    :class="animationBegin ? 'animate' : ''"
    class="com-lottery-rolling-letter"
  >
    <div class="com-lottery-rolling-letter__back" />
    <div class="com-lottery-rolling-letter__button" />
    <div class="com-lottery-rolling-letter__content">
      <!-- 游戏道具 -->
      <div
        v-if="rollingResult.type===0"
        class="com-lottery-result result-ticket"
      >
        <h3 class="com-lottery-result__title">
          恭喜你！中奖了
        </h3>
        <p class="com-lottery-result__name">
          {{ rollingResult.info.name }}
        </p>
        <div class="com-lottery-result__img">
          <img :src="rollingResult.info.img">
        </div>
        <p class="com-lottery-result__tips">
          奖品将会发放至你的游戏账户，请注意查收哦~
        </p>
      </div>
      <!-- cd-key -->
      <div
        v-else-if="rollingResult.type===2"
        class="com-lottery-result result-cdkey"
      >
        <h3 class="com-lottery-result__title">
          恭喜你！中奖了
        </h3>
        <p class="com-lottery-result__name">
          {{ rollingResult.info.name }}
        </p>
        <div class="com-lottery-result__img">
          <img :src="rollingResult.info.img">
        </div>
        <p class="com-lottery-result__key">
          <input
            ref="cdkeyInput"
            :value="rollingResult.info.cdkey"
            class="com-lottery-result__key-value"
            readonly
          >
          <i class="com-lottery-result__key-split" />
          <span
            @click="copyCdkey"
            class="com-lottery-result__key-copy"
          >复制</span>
        </p>
        <p class="com-lottery-result__tips">
          请尽快兑换Codekey中相应奖励
        </p>
      </div>
      <!-- Q币 -->
      <div
        v-else-if="rollingResult.type===3"
        class="com-lottery-result result-qbi"
      >
        <h3 class="com-lottery-result__title">
          恭喜你！中奖了
        </h3>
        <p class="com-lottery-result__name">
          {{ rollingResult.info.name }}
        </p>
        <div class="com-lottery-result__img">
          <img :src="rollingResult.info.img || qbDefaultIcon">
        </div>
        <p class="com-lottery-result__tips">
          Q币将会发放至指定QQ号中，注意查收哦
        </p>
      </div>
      <!-- 实物 -->
      <div
        v-else-if="rollingResult.type===1"
        class="com-lottery-result result-package"
      >
        <h3 class="com-lottery-result__title">
          恭喜你！中奖了
        </h3>
        <p class="com-lottery-result__name">
          {{ rollingResult.info.name }}
        </p>
        <div class="com-lottery-result__img">
          <img :src="rollingResult.info.img">
        </div>
        <div class="com-lottery-result__address">
          <p class="com-lottery-result__address-title">
            请填写领取信息
          </p>
          <input
            v-model.trim="realGoodAddressInfo.name"
            class="com-lottery-result__address-input"
            type="text"
            placeholder="请输入姓名"
            maxlength="30"
          >
          <input
            v-model.trim="realGoodAddressInfo.mobile"
            class="com-lottery-result__address-input"
            type="number"
            placeholder="请填写手机号码"
            maxlength="11"
          >
          <input
            v-model.trim="realGoodAddressInfo.address"
            class="com-lottery-result__address-input"
            type="text"
            placeholder="请输入省市区街道地址"
            maxlength="500"
          >
        </div>
        <p class="com-lottery-result__tips">
          <span v-if="!hasRecordAddress">可在活动结束前完善领取信息，如需修改，可在“查看中奖记录”中操作。</span>
          <span v-else>已填写信息，活动结束后，所有实物礼品将寄到上述地址。如修改信息，修改后将覆盖之前填写的地址哦。</span>
        </p>
      </div>
      <!-- 未中奖 -->
      <div
        v-else
        class="com-lottery-result result-nowin"
      >
        <h3 class="com-lottery-result__title">
          啊哦~<br>
          大奖与你擦肩而过
        </h3>
      </div>
    </div>
    <div class="com-lottery-rolling-letter__front">
      <div class="result-button-box">
        <div
          v-if="rollingResult.type !== 1"
          class="result-common-button"
        >
          <lottery-button-base
            @click.native="$emit('closeDialog')"
            type="white"
          >
            我知道了
          </lottery-button-base>
        </div>
        <div
          v-else
          class="result-address-button"
        >
          <lottery-button-base
            :disabled="!realGoodAddressInfo.name || !realGoodAddressInfo.mobile || !realGoodAddressInfo.address"
            @click.native="saveReadGoodAddress"
            type="white"
          >
            提交
          </lottery-button-base>
          <lottery-button-base
            @click.native="$emit('closeDialog')"
            class="text"
          >
            我知道了
          </lottery-button-base>
        </div>
      </div>
    </div>
    <div class="com-lottery-rolling-letter__header">
      <span class="com-lottery-rolling-letter__header-spark" />
    </div>
  </div>
</template>

<script>
import cookie from 'js-cookie';
import lotteryApi from '@/api/lottery';
import { logger } from '@/util/logger';
import LotteryButtonBase from '../lottery-button-base/index.vue';
import qbDefaultIcon from './img/icon-letter-gift-qbi.png';

const userLogid = cookie.get('uid');

export default {
  components: {
    LotteryButtonBase,
  },
  props: {
    rollingResult: {
      type: Object,
      default() {
        return {
          type: 0, // 0-虚拟物品，1-实际物品，2-cdkey，3-q币，4-未中奖
          info: {},
        };
      },
    },
  },
  inject: ['flowInfo'],
  data() {
    return {
      animationBegin: false,
      timeoutId: '',
      qbDefaultIcon,
      realGoodAddressInfo: {
        name: '',
        mobile: '',
        address: '',
      },
      amsLock: false,
      hasRecordAddress: false,
    };
  },
  mounted() {
    this.$emit('resetDialogDefault', 'add', 'lottery-theme');
    if (this.rollingResult.type === 1) {
      this.getRocordAddress();
    }
    this.$nextTick(() => {
      this.setDomAnimation();
    });
  },
  beforeDestroy() {
    clearTimeout(this.timeoutId);
    this.$emit('resetDialogDefault', 'del', 'lottery-theme');
    this.$emit('resetDialogDefault', 'del', 'animate');
  },
  methods: {
    setDomAnimation() {
      this.animationBegin = true;
      this.$emit('resetDialogDefault', 'add', 'animate');
    },
    copyCdkey() {
      const selection = window.getSelection();
      const range = document.createRange();
      range.selectNodeContents(this.$refs.cdkeyInput);
      selection.removeAllRanges();
      selection.addRange(range);
      document.execCommand('copy', false, this.$refs.cdkeyInput.select());
      this.$toast('复制成功！');
    },
    saveReadGoodAddress() {
      // ams地址填写调用
      const hasInputInfo = this.realGoodAddressInfo;
      if (!hasInputInfo.name) {
        this.$toast('姓名不能为空');
        return;
      }
      if (window.milo.getByteLength(hasInputInfo.name) > 30) {
        this.$toast('姓名长度不能超过30个字节');
        return;
      }
      if (!hasInputInfo.mobile || !/^1\d{10}$/.test(hasInputInfo.mobile)) {
        this.$toast('请输入正确格式的手机号码');
        return;
      }
      if (!hasInputInfo.address) {
        this.$toast('详细地址不能为空');
        return;
      }
      if (window.milo.getByteLength(hasInputInfo.address) > 500) {
        this.$toast('详细地址不能超过500个字节');
        return;
      }

      this.amsLock = true;
      const flowInfo = lotteryApi.getFlowIdByName('填写实物中奖个人信息(支持显示填写的历史记录)', this.flowInfo());
      logger.info(`抽奖模块：保存地址信息(${userLogid})`, flowInfo.flowId, this.rollingResult.info.id, this.rollingResult.info.name);
      if (flowInfo) {
        lotteryApi.saveGiftAddress({
          activityId: flowInfo.activityId,
          flowId: flowInfo.flowId,
          packageInfo: `${this.rollingResult.info.id}|${this.rollingResult.info.name}`,
          name: this.realGoodAddressInfo.name,
          mobile: this.realGoodAddressInfo.mobile,
          address: this.realGoodAddressInfo.address,
        }).then(() => {
          this.$emit('closeDialog', 'addressSubmitSuccess');
        })
          .catch((e) => {
            logger.info(`抽奖模块：保存地址出错(${userLogid})`, e.message, e);
            this.$toast(e.message || '服务器繁忙，请稍后重试');
          })
          .finally(() => {
            this.amsLock = false;
          });
      }
    },
    // 获取用户实物地址填写状态
    getRocordAddress() {
      const flowInfo = lotteryApi.getFlowIdByName('填写实物中奖个人信息(支持显示填写的历史记录)', this.flowInfo());
      if (flowInfo) {
        return lotteryApi.getGiftAddress({
          activityId: flowInfo.activityId,
          flowId: flowInfo.flowId,
        }).then((result) => {
          const recordResult = result.jData;
          logger.info(`抽奖模块：拉取地址(${userLogid})`, result.iRet, result.jData);
          if (Number(result.iRet) !== 0 || !recordResult) {
            this.hasRecordAddress = false;
            return;
          }
          this.realGoodAddressInfo.name = recordResult.sName;
          this.realGoodAddressInfo.mobile = recordResult.sMobile;
          this.realGoodAddressInfo.address = recordResult.sAddress;
          this.hasRecordAddress = true;
        })
          .catch((e) => {
            logger.info(`抽奖模块：拉取地址出错(${userLogid})`, e.message, e);
            this.$toast('地址信息拉取失败');
            this.hasRecordAddress = false;
          });
      }
      this.hasRecordAddress = false;
      return false;
    },
  },
};
</script>
<style lang="scss" src="./scss/index.scss"></style>
