<template>
  <div>
    <div
      v-if="!isFromGroup"
      class="com-group-or-game-header__top"
    />
    <div
      :style="{backgroundColor: backgroundColor}"
      :class="{'is-dark':isDarkMode}"
      class="com-group-or-game-header"
    >
      <div class="com-group-or-game-header__inner">
        <slot name="left-btn">
          <div class="com-group-or-game-header__blank">
            <div
              @click="back()"
              class="icon-back"
            />
          </div>
        </slot>
        <div class="com-group-or-game-header__main">
          <slot>
            <span
              v-if="showNewGameHeader"
              class="com-group-or-game-header__main-new"
            >{{ titleContent }}</span>
            <div
              v-else-if="showGroupHeader"
              class="com-group-or-game-header__main-slot"
            >
              <img
                :src="groupInfo.iconUrl"
                @click="onGroupBtnClick"
                class="com-group-or-game-header__main-icon"
              >
              <div
                @click="onGroupBtnClick"
                class="com-group-or-game-header__main-content"
              >
                <div class="com-group-or-game-header__main-content__group-name">
                  {{ groupInfo.name }}
                </div>
                <div class="com-group-or-game-header__main-content__group-desc">
                  {{ groupInfo.latestMessage }}
                </div>
              </div>
              <Button
                :size="'s'"
                @click.native="onGroupBtnClick"
                width="53px"
              >
                进圈子
              </Button>
            </div>
          </slot>
        </div>
        <slot name="right-btn">
          <div
            class="com-group-or-game-header__blank"
          >
            <div
              v-show="menuStatus"
              @click="onMenuClickHandler"
              class="icon-menu"
            />
          </div>
        </slot>
      </div>
      <div
        v-if="!isFromGroup"
        :style="{backgroundColor: backgroundColor}"
        class="com-group-or-game-header__bottom"
      >
      <!-- <div class="com-group-or-game-header__bottom-conner left" :style="{backgroundColor: backgroundColor}">
        <div class="inner"></div>
      </div>
      <div class="com-group-or-game-header__bottom-conner right" :style="{backgroundColor: backgroundColor}">
        <div class="inner"></div>
      </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import jsbridge, { popBack } from '@/jsbridge';
import { calculateLuminance } from '@/util/color';
import Button from '@/ui/button';
import { isServerRender } from '@/util/env';

export default {
  name: 'ComGroupOrGameHeader',
  components: {
    Button,
  },
  inject: {
    reportData: {
      form: 'reportData',
      default: () => {},
    },
  },
  props: {
    menuStatus: {
      type: Boolean,
      default: true,
    },
    autoBack: {
      type: Boolean,
      default: true,
    },
    groupInfo: {
      type: Object,
      default: null,
    },
    isFromGroup: { // 是否来自圈子
      type: Boolean,
      default: false,
    },
    titleContent: {
      type: String,
      default: '',
    },
    showNewGameHeader: {
      type: Boolean,
      default: false,
    },
    showGroupHeader: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    backgroundColor() {
      // 不是新游展示背景颜色
      if (this.showGroupHeader && this.groupInfo && this.groupInfo.colorValue) {
        return this.groupInfo?.colorValue;
      }
      return '#FFFFFF';
    },
    isAndroid() {
      return this.$store.state.isAndroid;
    },
    // 根据背景色
    isDarkMode() {
      if (isServerRender) return false;
      const { backgroundColor: bgColor } = this;
      if (!this.isFromGroup && bgColor) {
        const isDarkMode = calculateLuminance(bgColor) < 0.5;
        const textColor = !isDarkMode ? '#303133' : '#FFFFFF';
        if (this.isAndroid) {
          // 安卓和ios都有相应接口，ios状态栏是webview接管的，所以不用设置
          // ios 1.6版本有bug，设置后会导致其他webview的导航栏状态默认设置成这个，1.7会修复
          jsbridge.ui.setTitleBar({ bgColor, textColor });
        }
        return isDarkMode;
      }
      return false;
    },
  },
  watch: {
    groupInfo(val) {
      if (val && !this.isFromGroup) {
        this.reportData({
          moduleid: 33,
          eventtype: 2,
          extendid: 1,
          ext2: 1,
        }, '帖子详情页所属圈子曝光');
      }
    },
  },
  methods: {
    back() {
      if (this.autoBack) {
        popBack();
      } else {
        this.$emit('back');
      }
    },
    onMenuClickHandler() {
      this.$emit('menuClick');
    },
    onGroupBtnClick() {
      this.$linkToGroup(this.groupInfo.id);
      this.reportData({
        moduleid: 33,
        eventtype: 3,
        extendid: 1,
        ext2: 1,
      }, '帖子详情页所属圈子点击');
    },
  },
};
</script>
<style lang="scss" src="./scss/index.scss"></style>
