import util from '@/util/util';
import { checkEnv } from '@/util/browser-env';
import Http from './http';
import { errorLogger, retFilter, universalErrorHandler } from './middleware';
import { logger } from '../logger';

const AMS_SCENE = 'spark';
const NETWORK_TIMEOUT_DELAY = 20000; // 超时时间
const host = getAMSHost();

const amsHttp = new Http({
  baseURL: host,
  timeout: NETWORK_TIMEOUT_DELAY,
  withCredentials: false,
  credentials: 'same-origin', // 允许携带cookie
});
amsHttp.use('response', retFilter);
amsHttp.use('error', universalErrorHandler);
amsHttp.use('error', errorLogger); // 打印错误日志

function getHeaderMap() {
  const headers = {
    device: (checkEnv().ios) ? 'ios' : 'and',
    openid: util.cookie.get('openId') || '',
    acct_type: util.cookie.get('loginType') || '',
    appid: util.cookie.get('appId') || '',
    access_token: util.cookie.get('accessToken') || '',
    commReq: util.cookie.get('commReq') || '',
    cookie: document.cookie,
    userAgent: navigator.userAgent,
  };
  return headers;
}

function getAMSHost() {
  if (checkEnv().isTest) {
    return 'https://agw-t.xinyue.qq.com';
  } if (checkEnv().isDebug) {
    return 'https://agw-t.xinyue.qq.com';
  }
  return 'https://agw.xinyue.qq.com';
}

// eslint-disable-next-line import/prefer-default-export
async function postAMS(url, params) {
  const headerMap = getHeaderMap();
  const accountType = headerMap.acct_type === '1' ? 'wx' : 'qc';
  const headers = {
    'Content-Type': 'application/json',
    'T-ACCESS-TOKEN': headerMap.access_token,
    'T-ACCOUNT-TYPE': accountType,
    'T-APPID': headerMap.appid,
    'T-OPENID': headerMap.openid,
    'T-MODE': 'true',
    commReq: headerMap.commReq,
    Cookie: headerMap.cookie,
    'User-Agent': headerMap.userAgent,
  };

  logger.info('AMS-API Request', { headers, url, params });
  const host = getAMSHost();
  return amsHttp.request({
    url: `${host}${url}`,
    data: JSON.stringify(params),
    credentials: 'same-origin', // 允许携带cookie
    headers,
    options: {
      errorHandler: false,
    },
  });
}

// 拉取大区列表
export function getAreaChannelList({ gameCode }) {
  const device = (checkEnv().ios) ? 'ios' : 'android';
  const headerMap = getHeaderMap();
  const area = headerMap.acct_type === '1' ? 'wx' : 'qq';
  return postAMS('/amp2.RoleSrv/GetAreaChannelList', {
    game_code: gameCode,
    device,
    area,
  }).then((res) => {
    const data = {
      ret: res.ret,
      areaChannelList: res.list,
      msg: res.msg,
    };
    return data;
  })
    .catch((err) => {
      Promise.reject(err);
    });
}

// 拉取区服列表
export function getPartitionList({ gameCode, areaChannelId }) {
  const device = (checkEnv().ios) ? 'ios' : 'android';
  const headerMap = getHeaderMap();
  const area = headerMap.acct_type === '1' ? 'wx' : 'qq';
  return postAMS('/amp2.RoleSrv/GetPartitionList', {
    game_code: gameCode,
    area_channel_id: areaChannelId,
    device,
    area,
  }).then((res) => {
    const data = {
      ret: res.ret,
      partitionList: res.list,
      msg: res.msg,
    };
    return Promise.resolve(data);
  })
    .catch((err) => {
      Promise.reject(err);
    });
}

// 拉取指定区服角色
export function getPartitionRoleList({ gameCode, areaChannelId, partitionId }) {
  const device = (checkEnv().ios) ? 'ios' : 'android';
  return postAMS('/amp2.RoleSrv/GetSpecifyRoleList', {
    game_code: gameCode,
    device,
    area_channel_id: areaChannelId,
    partition_id: partitionId,
  }).then((res) => {
    const data = {
      ret: res.ret,
      roles: res.roles.map(item => util.humps.camelizeKeys(item)),
      msg: res.msg,
    };
    return Promise.resolve(data);
  })
    .catch((err) => {
      Promise.reject(err);
    });
}

// 拉取最近登录区服角色
export function getUserRoleList({ gameCode }) {
  const device = (checkEnv().ios) ? 'ios' : 'android';
  return postAMS('/amp2.RoleSrv/GetRecentRoleList', {
    scene: AMS_SCENE, // 区分场景，避免多业务互相干扰
    game_code: gameCode,
    device,
  }).then((res) => {
    const data = {
      ret: res.ret,
      roles: res.roles.map(item => util.humps.camelizeKeys(item)),
      msg: res.msg,
    };
    return Promise.resolve(data);
  })
    .catch((err) => {
      Promise.reject(err);
    });
}

// 绑定角色
export function userAddRole({ gameCode, role }) {
  const device = (checkEnv().ios) ? 'ios' : 'android';
  return postAMS('/amp2.RoleSrv/BindRole', {
    scene: AMS_SCENE, // 区分场景，避免多业务互相干扰
    game_code: gameCode,
    device,
    role: util.humps.decamelizeKeys(role),
  }).then((res) => {
    const data = {
      ret: res.ret,
      msg: res.msg,
    };
    return Promise.resolve(data);
  })
    .catch((err) => {
      Promise.reject(err);
    });
}
