<template>
  <div
    v-if="whiteStatus"
    class="com-game-lottery"
  >
    <div class="com-game-lottery__header">
      <h4 class="com-game-lottery__header-title">
        <span>{{ htmlInfo.title || '抽奖狂欢' }}</span>
        <span
          @click="openLotteryRule"
          class="com-game-lottery__header-help"
        />
      </h4>
      <span class="com-game-lottery__header-time">{{ formatLotteryTime() }}</span>
    </div>
    <p
      v-if="htmlInfo.ruleText"
      class="com-game-lottery__desc"
    >
      {{ htmlInfo.ruleText }}
    </p>
    <div class="com-game-lottery__list mask">
      <ul :class="realGiftInfo.length > 3 ? '' : 'gift-list-center'">
        <li
          v-for="item in realGiftInfo"
          :key="item.id"
        >
          <div class="com-game-lottery__list-img">
            <img
              :src="item.img"
              @error="item.img=iconGiftDefault"
            >
          </div>
          <div class="com-game-lottery__list-name">
            {{ item.name }}
          </div>
        </li>
      </ul>
    </div>
    <lottery-button-group
      :lottery-type="htmlInfo.lotteryType"
      @openRollingDialog="openRollingDialog"
    />

    <!-- 抽奖前选择角色及qq弹窗  -->
    <Dialog
      v-model="roleDialogStatus"
      :class="dialogResetClass"
      :content-style="{padding: '0'}"
      @openRollingDialog="openRollingDialog"
      @closeDialog="closeDialog"
    >
      <lottery-dialog-role
        :contains-game="Boolean(htmlInfo.giftContainsGame)"
        :need-show-qq-input="htmlInfo.needShowQQInput"
        :game-code="activityInfo.gameServiceType"
        :near-role-list="nearRoleList"
        @openRollingDialog="openRollingDialog"
        @setBindRoleInfo="setBindRoleInfo"
        @closeDialog="closeDialog"
      />
    </Dialog>

    <!-- 抽奖弹窗 -->
    <Dialog
      v-model="rollingDialogStatus"
      :class="dialogResetClass"
    >
      <lottery-dialog-rolling
        :rolling-result="rollingResult"
        @resetDialogDefault="resetDialogDefault"
        @openRollingDialog="openRollingDialog"
        @closeDialog="closeDialog"
      />
    </Dialog>

    <!-- 抽奖规则弹窗 -->
    <Dialog
      v-model="lotteryRuleStatus"
    >
      <h2 class="lottery-dialog-role__title">
        抽奖规则说明
      </h2>
      <span
        @click="closeDialog"
        class="lottery-dialog-role__close"
      />
      <p class="lottery-dialog-rule-tips">
        <span
          v-for="(item,index) in htmlInfo.description"
          :key="index"
        >
          {{ item }}
          <br>
        </span>
      </p>
      <lottery-button-base
        @click.native="closeDialog"
        type="primary"
      >
        我知道了
      </lottery-button-base>
    </Dialog>

    <!-- 抽奖失败弹窗 -->
    <!-- <Dialog
    v-model="lotteryFailStatus"
  >
    <h2 class="lottery-dialog-role__title">
      温馨提示
    </h2>
    <span class="lottery-dialog-role__close"
      @click="lotteryFailStatus=false"
    />
    <p class="lottery-dialog-rule-tips">
      {{ lotteryFailText }}
    </p>
    <lottery-button-base type="primary"
      @click.native="lotteryFailStatus=false"
    >
      我知道了
    </lottery-button-base>
  </Dialog> -->

    <!-- 提交通知 -->
    <Dialog
      v-model="addressSubmitSuccess"
    >
      <h2 class="lottery-dialog-role__title">
        提交成功
      </h2>
      <span
        @click="addressSubmitSuccess=false"
        class="lottery-dialog-role__close"
      />
      <p class="lottery-dialog-rule-tips">
        信息提交成功，小编会尽快寄出礼品的，请耐心等待~
      </p>
      <lottery-button-base
        @click.native="closeDialog"
        type="primary"
      >
        我知道了
      </lottery-button-base>
    </Dialog>
  </div>
</template>

<script>
import cookie from 'js-cookie';
import Dialog from '@/ui/dialog/index.vue';
import {
  getUserRoleList,
} from '@/util/http/ams-api';
import lotteryApi from '@/api/lottery';
import userApi from '@/api/user';
import { checkEnv } from '@/util/browser-env';
import { decode } from '@/util/base64';
import { logger } from '@/util/logger';
import LotteryButtonBase from './component/lottery-button-base/index.vue';
import LotteryButtonGroup from './component/lottery-button-group/index.vue';
import LotteryDialogRole from './component/lottery-dialog-role/index.vue';
import LotteryDialogRolling from './component/lottery-dialog-rolling/index.vue';
import iconGiftDefault from './img/icon-gift-default.png';
import qbDefaultIcon from './img/icon-letter-gift-qbi.png';
import { isServerRender } from '@/util/env';

const userLogid = cookie.get('uid');

export default {
  name: 'ComGameLottery',
  components: {
    Dialog,
    LotteryButtonBase,
    LotteryButtonGroup,
    LotteryDialogRole,
    LotteryDialogRolling,
  },
  props: {
    activityId: {
      type: [Number, String],
      default: 0, // 临时开发调试
    },
    postId: {
      type: [Number, String],
      default: 0,
    },
  },
  inject: ['reportData'],
  provide() {
    return {
      buttonStatus: this.buttonStatus,
      flowInfo: () => this.flowInfo,
      activityInfo: () => this.activityInfo,
      lotteryReportData: (...arg) => this.lotteryReportData(...arg),
    };
  },
  data() {
    return {

      iconGiftDefault,
      env: checkEnv(),
      buttonStatus: {
        hasJoinLottery: 0, // 本次抽奖是否已参与(本次抽奖频率资格，次、日、周、月等)
        hasWinPrize: 0, // 历史上是否中奖
        needGameOrQQInfo: 0, // 本次抽奖是否需要填写游戏角色信息或绑定qq号
        activityEndStatus: 0, // 活动时间是否已结束
        activityStartStatus: 0, // 活动时间是否已开始
        activityExpireStatus: 0, // 活动时间是否已过期（活动结束后90天外）
        TimeRangeEndStatus: 0, // 活动时段是否已结束  预留，后续迭代
        TimeRangeStartStatus: 1, // 活动时段是否已开始  预留，后续迭代
      },
      roleDialogStatus: false,
      rollingDialogStatus: false,
      lotteryRuleStatus: false,
      addressSubmitSuccess: false,
      dialogResetClass: {},

      activityInfo: {},
      flowInfo: [],
      htmlInfo: {},
      packageInfo: {},
      realGiftInfo: [],
      nearRoleList: [],
      whiteStatus: false,
      rollingResult: {
        type: 4,
        info: {},
      },
      bindRoleInfo: {},
      rollingResultClass: {
        0: 'lottery-ticket',
        1: 'lottery-package',
        2: 'lottery-cdkey',
        3: 'lottery-qbi',
        4: 'lottery-nowin',
      },
      amsLock: false,
      lotteryTimeout: '',
      lotteryFailText: '',
      lotteryFailStatus: false,
    };
  },
  created() {
    if (isServerRender) return;
    logger.info(`抽奖模块：初始化请求(${userLogid})`, this.activityId, this.env.isApp);
    if (this.activityId) {
      Promise.all([
        this.getLotteryConfig(this.activityId),
        this.env.isApp ? lotteryApi.checkMiloLoadAndLogin() : Promise.resolve('no_check_env'),
      ]).then((result) => {
        logger.info(`抽奖模块：配置拉取成功(${userLogid})`, this.env.isApp);
        this.computeActTimeStatus();
        // 哪些情况下跳过状态查询，直接显示
        // app外，app内未登录，活动未开始，活动已过期超出90天
        // 活动预发环境，进行白名单判断(bug)
        const whitelistStatus = this.htmlInfo.whitelist && cookie.get('openId') && this.htmlInfo.whitelist.includes(cookie.get('openId'));
        const authPower = this.htmlInfo.isProd ? true : whitelistStatus; // 正式环境权限不限，预发布环境权限根据白名单判断
        // 如果[1-不是app登录]或者[2-app内未登录]，再[3-或者app内登录了但是有权限看到，且此时时间状态允许看到，则显示简单版抽奖组件]
        if (result[1] === 'no_check_env' || (authPower && (!this.buttonStatus.activityStartStatus || this.buttonStatus.activityExpireStatus))) {
          this.whiteStatus = true;
          this.lotteryReportData({
            eventtype: '01',
            extendid: '01',
          }, '抽奖模块曝光');
          logger.info(`初始化完成(${userLogid})`, this.lotteryType, this.lotteryAction, this.buttonStatus);
          return Promise.resolve();
        }
        // 否则判断用户是否有权限看到，有的话拉取状态显示复杂版抽奖组件
        if (authPower) {
          return this.updateUserLotteryStatus().then(() => {
            this.whiteStatus = true;
            const needGameOrQQInfo = Number(this.htmlInfo.giftContainsGame) || Number(this.htmlInfo.needShowQQInput);
            this.buttonStatus.needGameOrQQInfo = needGameOrQQInfo;
            this.lotteryReportData({
              eventtype: '01',
              extendid: '01',
            }, '抽奖模块曝光');
          });
        }
        logger.info(`初始化完成(${userLogid})`, this.lotteryType, this.lotteryAction, this.buttonStatus);
        return Promise.resolve();
      })
        .catch((e) => {
          logger.info(`抽奖模块：初始化失败(${userLogid})`, e.message);
          logger.error('抽奖模块初始化失败', this.htmlInfo.whitelist, e);
        });
    }
  },
  methods: {
    // 活动展示规则，具体http://tapd.oa.com/GamingCommunity/prong/stories/view/1020419237857951267
    formatLotteryTime() {
      // eslint-disable-next-line no-unused-vars
      const [begin, startYear, startMonth, startDay, startHours, startMinutes] = this.activityInfo.beginTime.match(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/);
      // eslint-disable-next-line no-unused-vars
      const [end, endYear, endMonth, endDay, endHours, endMinutes] = this.activityInfo.endTime.match(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/);

      if (startYear !== endYear) {
        return `${startYear}-${startMonth}-${startDay}~${endYear}-${endMonth}-${endDay}`;
      } if (startMonth !== endMonth || startDay !== endDay) {
        return `${startMonth}-${startDay} ${startHours}:${startMinutes}~${endMonth}-${endDay} ${endHours}:${endMinutes}`;
      }
      return `${startMonth}-${startDay} ${startHours}:${startMinutes}~${endHours}:${endMinutes}`;
    },
    // 此方法用于抽奖过程及展示的时候重置弹窗的默认样式 type: add/del
    resetDialogDefault(type, name) {
      if (type === 'add') {
        this.$set(this.dialogResetClass, name, true);
      } else {
        this.$set(this.dialogResetClass, name, false);
      }
    },
    // 计算活动时间状态
    computeActTimeStatus() {
      // 此处需要先计算活动时间，超出了时间则不发起ams查询状态请求
      const nowDate = new Date();
      // eslint-disable-next-line no-unused-vars
      // eslint-disable-next-line prefer-const
      let [begin, startYear, startMonth, startDay, startHours, startMinutes, startSecond] = this.activityInfo.beginTime.match(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/);
      // eslint-disable-next-line no-unused-vars
      // eslint-disable-next-line prefer-const
      let [end, endYear, endMonth, endDay, endHours, endMinutes, endSecond] = this.activityInfo.endTime.match(/(\d{4})-(\d{2})-(\d{2}) (\d{2}):(\d{2}):(\d{2})/);
      // 此步用于兼容ios下的时间格式
      begin = new Date(startYear, startMonth - 1, startDay, startHours, startMinutes, startSecond);
      end = new Date(endYear, endMonth - 1, endDay, endHours, endMinutes, endSecond);
      const beginDateFlag = Number(nowDate.valueOf() > begin.valueOf());
      this.buttonStatus.activityStartStatus = beginDateFlag;
      const endDateFlag = Number(nowDate.valueOf() > end.valueOf());
      this.buttonStatus.activityEndStatus = endDateFlag;
      // 活动结束时间后90天，兜底时段因素，计算时用89天
      this.buttonStatus.activityExpireStatus = Number(nowDate.valueOf() > (end.valueOf() + 86400000 * 89));

      logger.info(`抽奖模块：时间计算(${userLogid})`, this.activityInfo.beginTime, this.activityInfo.endTime, this.activityInfo.openTime, this.activityInfo.closeTime);
      logger.info(`抽奖模块：时段计算结果(${userLogid})`, beginDateFlag, endDateFlag, this.buttonStatus);
    },
    // 用于拉起录入角色信息或开始抽奖
    openRollingDialog(focus) {
      // 没有q币和游戏资源，或者只有q币资源但绑定过了qq或不需要绑定qq，或者强制拉起弹窗
      const { htmlInfo } = this;
      const condition = !htmlInfo.giftContainsGame && htmlInfo.giftContainsQB && ! htmlInfo.needShowQQInput;
      if (focus || (!htmlInfo.giftContainsGame && !htmlInfo.giftContainsQB) || condition) {
        this.doLottery().then((result) => {
          if (result) {
            this.roleDialogStatus = false;
            this.rollingDialogStatus = true;
          }
          this.updateUserLotteryStatus();
        });
      } else {
        let needNearRole = [];
        if (this.htmlInfo.giftContainsGame) {
          needNearRole = this.getUserNearRole();
        }
        Promise.resolve(needNearRole).then((result) => {
          this.nearRoleList = result;
          this.roleDialogStatus = true;
          this.rollingDialogStatus = false;
        });
      }
    },
    // 关闭所有弹窗
    closeDialog(exclude) {
      this.roleDialogStatus = false;
      this.rollingDialogStatus = false;
      this.lotteryRuleStatus = false;
      this.addressSubmitSuccess = false;
      this[exclude] = true;
    },
    // 更新用户的中奖操作状态
    updateUserLotteryStatus() {
      // 状态其中一个获取失败，那么就直接报错，前端组件也不展示
      return Promise.all([
        this.getUserJoinStatus(),
        this.getUserLotteryhistory(),
        this.getBindQQStatus(),
      ]).then((result) => {
        // this.buttonStatus.hasJoinLottery = result[0];
        // this.buttonStatus.hasWinPrize = result[1];
        [this.buttonStatus.hasJoinLottery, this.buttonStatus.hasWinPrize] = result;
      });
    },
    // 设置用户绑定角色信息
    setBindRoleInfo(value) {
      logger.info(`抽奖模块：绑定用户角色信息(${userLogid})`, value);
      this.bindRoleInfo = value;
    },

    // 拉取礼包配置文件
    getLotteryConfig() {
      return lotteryApi.getLotteryConfig(this.activityId).then((lotteryConfig) => {
        // 此处进行数据处理及格式转换
        let { htmlInfo } = lotteryConfig;
        const { flowInfo, packageInfo } = lotteryConfig;
        htmlInfo = (htmlInfo && htmlInfo[0] && htmlInfo[0].attributes) || {};
        const htmlInfoGiftTypeInfo = htmlInfo.giftTypeInfo && htmlInfo.giftTypeInfo[0];
        if (!htmlInfoGiftTypeInfo || !htmlInfo.giftButton || !htmlInfo.giftButton.value) {
          throw new Error(`没有配置活动 ${this.activityId} 的 H5 信息`);
        }
        // 自定义礼包信息
        const customGitList = [];
        const customGiftItemInfo = (htmlInfo.giftItemInfo || []).reduce((ret, v) => {
          customGitList.push(v.giftItemId.value);
          if (v.giftItemId.value) {
            // eslint-disable-next-line no-param-reassign
            ret[v.giftItemId.value] = {
              id: v.giftItemId.value,
              img: v.giftItemImg.value,
              name: v.giftItemName.value,
            };
          }
          return ret;
        }, {});
        // 按钮对应的流程信息
        const buttonFlowInfo = flowInfo.find(item => Number(htmlInfo.giftButton.value) === Number(item.flowId));
        if (!buttonFlowInfo) {
          throw new Error(`没有找到活动 ${this.activityId} 按钮对应的流程信息`);
        }
        // 按钮对应礼包信息
        const buttonPackageGroupInfo = packageInfo.find((item) => {
          const isIncludes = buttonFlowInfo.packageGroupIdList.includes(item.iPackageGroupId);
          return buttonFlowInfo.packageGroupIdList && isIncludes;
        });
        if (!buttonPackageGroupInfo || !buttonPackageGroupInfo.arrPackageInfo) {
          throw new Error(`没有找到活动 ${this.activityId} 按钮对应的礼包信息`);
        }

        htmlInfo = {
          isProd: lotteryConfig.isProd,
          customGiftItemInfo,
          description: (htmlInfo.description && htmlInfo.description.split('\n')) || [],
          ruleText: htmlInfo.ruleText,
          title: htmlInfo.title,
          lotteryAction: htmlInfo.giftButton && htmlInfo.giftButton.value,
          giftContainsGame: Boolean(Number(htmlInfoGiftTypeInfo.giftContainsGame.value)),
          giftContainsQB: Boolean(Number(htmlInfoGiftTypeInfo.giftContainsQB.value)),
          lotteryType: Number(buttonFlowInfo.limitType) === 1 ? 'O' : buttonFlowInfo.limitSubType, // limitType：1:一次性限制, 2:周期性限制, 3:动态限制,  limitSubType：D:每日资格, W:每周资格, M:每月资格
          needShowQQInput: Boolean(Number(htmlInfoGiftTypeInfo.giftContainsQB.value)),
        };

        // 只有活动预发布环境，才会有白名单
        if (!htmlInfo.isProd) {
          htmlInfo.whitelist = [];
          if (lotteryConfig.whitelist) {
            // 最大程度容忍白名单填写不规范，保证白名单都是非空 openId，并且都是正常的 openId
            htmlInfo.whitelist = lotteryConfig.whitelist.replace(/\s/ig, '').split(/,|，/)
              .filter((item) => {
                const openId = item.trim();
                return !!openId && openId.length > 5;
              });
          }
        }

        this.realGiftInfo = (buttonPackageGroupInfo.arrPackageInfo || []).reduce((ret, v) => {
          const index = customGitList.indexOf(v.iPackageId);
          const vImg = (customGiftItemInfo[v.iPackageId]?.img) || (v.itemPics && v.itemPics[0]);
          const data = {
            id: v.iPackageId,
            name: (customGiftItemInfo[v.iPackageId] && customGiftItemInfo[v.iPackageId].name) || v.sPackageName,
            img: vImg || (Number(v.iPackageType) === 1 ? qbDefaultIcon : iconGiftDefault),
            isQB: Boolean(Number(v.iPackageType)),
          };
          if (index >= 0) {
            if (ret[index]) {
              ret.push(ret[index]);
            }
            // eslint-disable-next-line no-param-reassign
            ret[index] = data;
          } else {
            ret.push(data);
          }
          return ret;
        }, []);

        this.activityInfo = lotteryConfig.activityInfo;
        this.flowInfo = lotteryConfig.flowInfo || [];
        this.htmlInfo = htmlInfo;
        this.packageInfo = buttonPackageGroupInfo || {};
      });
    },
    // 获取用户参与状态
    getUserJoinStatus() {
      if (!this.buttonStatus.activityStartStatus || this.buttonStatus.activityEndStatus) {
        return 0;
      }
      const flowInfo = lotteryApi.getFlowIdByName('礼包状态查询', this.flowInfo);
      return lotteryApi.getUserLotteryStatus({
        activityId: flowInfo.activityId,
        flowId: flowInfo.flowId,
        gameCode: this.activityInfo.gameServiceType,
      }).then((res) => {
        // 一个礼包的周期属性只能为1个，所以资格状态只取第一个。
        // 格式为 `资格1 资格总数 资格剩余|资格2 资格总数 资格剩余|资格3 资格总数 资格剩余`。
        // 找不到文档解释的，一般人我不告诉他~
        // 要考虑从未参与时 0 0 的情况

        logger.info(`抽奖模块：查询用户参与状态(${userLogid})`, res);
        let hasJoinLottery = res.sOutValue1.split('|');
        hasJoinLottery = hasJoinLottery && hasJoinLottery[0];
        hasJoinLottery = hasJoinLottery.split(' ');
        if (Number(hasJoinLottery[1]) > 0 && Number(hasJoinLottery[2]) === 0) {
          return 1;
        }
        return 0;
      });
    },
    // 获取用户历史中奖状态
    getUserLotteryhistory() {
      if (!this.buttonStatus.activityStartStatus || this.buttonStatus.activityExpireStatus) {
        return 0;
      }
      const flowInfo = lotteryApi.getFlowIdByName('查询个人获奖记录', this.flowInfo);
      return lotteryApi.getLotteryHistory({
        activityId: flowInfo.activityId,
        flowId: flowInfo.flowId,
        gameCode: this.activityInfo.gameServiceType,
        size: 3,
      }).then((result) => {
        logger.info(`抽奖模块：查询用户中奖状态(${userLogid})`, result.list.length);
        // 临时调试，记得恢复
        if (result.list && result.list.length) {
          return 1;
        }
        return 0;
      });
    },

    // 开始抽奖
    doLottery() {
      logger.info(`抽奖模块：用户发起抽奖(${userLogid})`, this.amsLock);
      if (this.amsLock) {
        return Promise.reject(new Error('lottery not end'));
      }

      this.amsLock = true;
      clearTimeout(this.lotteryTimeout);
      const loadingStatus = this.$loading({
        message: '正在加载...',
      });
      this.lotteryTimeout = setTimeout(() => {
        // 因为milo那边抽奖如果iRet<0，不会调用fail流程，所以此处需要一个超时逻辑
        this.amsLock = false;
        loadingStatus.close();
      }, 10000);
      logger.info(`抽奖模块：抽奖请求(${userLogid})`, this.activityId, this.htmlInfo.lotteryAction, this.activityInfo.gameServiceType, this.postId, this.bindRoleInfo);
      return lotteryApi.getLotteryResult({
        activityId: this.activityId,
        flowId: this.htmlInfo.lotteryAction,
        sData: {
          ...this.bindRoleInfo,
          sServiceType: this.activityInfo.gameServiceType,
          gameId: this.activityInfo.gameServiceType,
        },
        postId: this.postId,
        gameId: this.activityInfo.gameServiceType,
      }).then((res) => {
        // if (!res.sPackageName) {
        //   this.$toast(res.sMsg);
        //   return false;
        // }
        logger.info(`抽奖模块：抽奖结果(${userLogid})`, res);
        const lotteryType = res.sPackageRealFlag ? Number(res.sPackageRealFlag) : 4;
        const result = {
          type: lotteryType,
        };
        // 礼包信息
        if (!res.iPackageId) {
          result.type = 4;
        } else {
          this.realGiftInfo.some((v) => {
            if (Number(v.id) === Number(res.iPackageId)) {
              if (Number(v.isQB)) {
                result.type = 3;
              }
              result.info = v;
              return true;
            }
            return false;
          });
          if (res.sPackageCDkey || res.sPackageOtherInfo) {
            // cdkey
            result.type = 2;
            result.info.cdkey = res.sPackageCDkey || res.sPackageOtherInfo;
          }
        }

        this.rollingResult = result;
        Object.keys(this.rollingResultClass).forEach((v) => {
          this.dialogResetClass[this.rollingResultClass[v]] = Number(v) === Number(result.type);
        });
        logger.info(`抽奖模块：抽奖判断类型及动画(${userLogid})`, this.rollingResult, this.dialogResetClass);
        return true;
      })
        .catch((e) => {
          logger.info(`抽奖模块：抽奖失败(${userLogid})`, e.message, e);
          this.lotteryFailStatus = true;
          this.lotteryFailText = e.message || '服务器繁忙，请稍后重试';
          this.$toast(e.message || '服务器繁忙，请稍后重试');
          this.amsLock = false;
          loadingStatus.close();
          return false;
        })
        .finally(() => {
          this.amsLock = false;
          loadingStatus.close();
        });
    },

    // 获取用户近期活跃角色
    getUserNearRole() {
      return getUserRoleList({
        gameCode: this.activityInfo.gameServiceType,
      }).then((res) => {
        logger.info(`抽奖模块：查询用户角色(${userLogid})`, res.roles);
        if (res.roles && res.roles.length > 0) {
          return res.roles.map(item => ({
            id: item.roleId,
            roleName: decode(item.roleName),
            partitionName: decode(item.partitionName),
            data: item,
          }));
        }
        return [];
      })
        .catch((err) => {
          logger.info(`抽奖模块：查询用户角色失败(${userLogid})`, err.message, err);
          if (err && err.data && err.data.msg) {
            this.$toast(err.data.msg);
          } else {
            this.$toast('获取最近游戏角色失败');
          }
          return [];
        });
    },
    // 查询用户绑定qq状态
    getBindQQStatus() {
      // 临时调试
      const loginType = Number(this.$store.state.app.headers.acct_type);

      logger.info(`抽奖模块：查询用户绑定qq态(${userLogid})`, loginType, this.htmlInfo.needShowQQInput);
      if (this.htmlInfo.needShowQQInput && loginType !== 2) {
        return userApi.getUserLoginInfo({
          uid: this.$store.state.user.userInfo.uid,
        }).then((result) => {
          logger.info(`抽奖模块：查询用户绑定qq态结果(${userLogid})`, result.userInfo.hasQq);
          this.htmlInfo.needShowQQInput = Boolean(this.htmlInfo.needShowQQInput && !result.userInfo.hasQq);
        });
      }
      this.htmlInfo.needShowQQInput = false;
      return Promise.resolve();
    },
    // 打开抽奖规则
    openLotteryRule() {
      this.lotteryReportData({
        eventtype: '03',
        extendid: '03',
      }, '抽奖模块 规则说明入口点击');
      this.lotteryRuleStatus = true;
    },
    // 抽奖相关二次封装的上报
    lotteryReportData(params = {}, desc) {
      // console.log(params, desc);
      this.reportData({
        pageid: '1502',
        moduleid: '00054',
        ext1: this.lotteryType === '0' ? '1' : '2',
        ext2: this.env.isApp ? '1' : '2',
        ext8: this.activityId,
        ...params,
      }, desc);
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
