/**
 * 礼品API
 * */
import { gameplusApi } from '@/util/http';

// const giftsMockData = {
//   ret: 0,
//   data: {
//     packageId: '123',
//     packageTitle: '我的礼包',
//     giftStatus: 0,
//     btnDefaultText: '领取',
//     btnUnavailableText: '未领取',
//     rule: '评论100条',
//     giftItems: [
//       {
//         title: '钻石123',
//         url: 'https://via.placeholder.com/150',
//       }, {
//         title: '金币299',
//         url: 'https://via.placeholder.com/150',
//       }, {
//         title: '道具xxx',
//         url: 'https://via.placeholder.com/150',
//       }, {
//         title: '钻石123',
//         url: 'https://via.placeholder.com/150',
//       }, {
//         title: '金币299',
//         url: 'https://via.placeholder.com/150',
//       }, {
//         title: '道具xxx',
//         url: 'https://via.placeholder.com/150',
//       }, {
//         title: '道具xxx',
//         url: 'https://via.placeholder.com/150',
//       }, {
//         title: '道具xxx',
//         url: 'https://via.placeholder.com/150',
//       },
//     ],
//   },
// };

export default {
  /**
   * 拉取礼包列表
   * @param { Object } params
   * @param { Number | String } params.gameCode 游戏标识code
   * @param { String[] } params.packageIds 指定礼包玩法标识组拉取礼包
   * @return { Object } 返回获取结果
   * */
  getGifts(params) {
    return gameplusApi.request({
      url: '/community.GiftSrv/GetGifts',
      data: {
        gameCode: params.gameCode,
        packageIds: params.packageIds,
      },
      options: {
        errorHandler: false,
        excludeRet: [90404], // 90404被下架了，不可见
      },
    });
  },
  /**
   * 领取礼包
   * @param { Object } params
   * @param { Number | String } params.gameCode 游戏标识code
   * @param { String[] } params.packageId 指定礼包玩法标识组拉取礼包
   * @param { Object } params.role
   * @return { Object } 返回获取结果
   * */
  receiveGift({ gameCode, packageId, role }) {
    return gameplusApi.request({
      url: '/community.GiftSrv/ReceiveGift',
      data: {
        gameCode,
        packageId,
        role,
      },
      options: {
        errorHandler: false,
      },
    });
  },

  /**
   * 判断领取礼包资格
   * @description 判断领取礼包资格，接口与receiveGift一致
   * @param { Object } params
   * @param { Number | String } params.gameCode 游戏标识code
   * @param { String[] } params.packageId 指定礼包玩法标识组拉取礼包
   * @param { Object } params.role
   * @return { Object } 返回获取结果
   * */
  checkGift({ gameCode, packageId, role }) {
    return gameplusApi.request({
      url: '/community.GiftSrv/CheckGift',
      data: {
        gameCode,
        packageId,
        role,
      },
      options: {
        errorHandler: false,
      },
    });
  },
};
