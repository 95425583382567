<template>
  <div
    @click="$emit('click')"
    class="pc-pill-button"
  >
    <div class="pc-pill-button__left">
      {{ leftText }}
    </div>
    <div class="pc-pill-button__right">
      {{ rightText }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'PcPillButton',
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    leftText: {
      type: String,
      default: '',
    },
    rightText: {
      type: String,
      default: '',
    },
  },
};
</script>

<style lang="scss">
@import "~@/ui/style/variable.scss";

.pc-pill-button {
  height: 30px;
  display: flex;
  flex-direction: row;
  border-radius: 6px;
  align-items: center;
  text-align: center;
  font-size: 12px;
  font-weight: bold;
  overflow: hidden;
  &__left {
    background-color: $color-bg-gray2;
    padding: 0 2px;
    box-sizing: border-box;
    min-width: 70px;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: $color-text;
  }
  &__right {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $color-bg-yellow;
    min-width: 40px;
    height: 100%;
    color: $color-title;
  }
}
</style>
