/* eslint-disable */
import postApi from '@/api/post';
import { formatPreData } from '@/util/webbundle/predata-formater';
import PageLocalStore from '@/util/webbundle/page-local-store';
import { logger } from '@/util/logger';
import { getGroupOwnerType } from '@/business/post/post';

export const PAGE_STORE_MODULE_NAME = 'post-detail';

const pageLocalStore = new PageLocalStore({
  moduleName: 'detail',
});

const createState = () => ({
  pageData: null,
  prePageData: null,
  postInfo: null,
  postAction: null,
  creatorInfo: null,
  groupInfo: null,
  topicInfo: null,
  // 福利站信息
  welfareInfo: {},
  // 当前圈子福利数量
  giftNumber: 0,
  postLoading: true,
  dataSource: null, // 数据来源：preData=预渲染数据,local=本地缓存,cgi=接口数据
});

export const pageStore = {
  namespaced: true,
  state: createState,
  mutations: {
    updatePostInfo (state, postInfo) {
      state.postInfo = { ...state.postInfo, ...postInfo };
      state.postLoading = false;
    },
    updatePostAction (state, postAction) {
      state.postAction = { ...state.postAction, ...postAction };
    },
    updateGroupInfo (state, groupInfo) {
      state.groupInfo = { ...state.groupInfo, ...groupInfo };
    },
    updateWelfareInfo (state, welfareInfo) {
      state.welfareInfo = { ...state.welfareInfo, ...welfareInfo };
    },
    updateGiftNumber (state, giftNumber) {
      state.giftNumber = giftNumber;
    },
    updateTopicInfo (state, topicInfo) {
      state.topicInfo = { ...state.topicInfo, ...topicInfo };
    },
    updateCreatorInfo (state, {creatorInfo, groupInfo}) {
      creatorInfo.groupOwnerType = getGroupOwnerType(creatorInfo, groupInfo);
      state.creatorInfo = { ...state.creatorInfo, ...creatorInfo };
    },
    setCreatorInfo (state, creatorInfo) {
      state.creatorInfo = { ...state.creatorInfo, ...creatorInfo };
    },
    setPostLoading (state, val) {
      state.postLoading = val;
    },
    setDataSource (state, val) {
      if (['preloadData', 'preData', 'local', 'cgi'].indexOf(val) === -1) {
        console.error('setDataSource 参数错误', val);
      }
      state.dataSource = val;
    },
    setPreloadData (state, {
      postInfo, groupInfo, topicInfo, postAction, creatorInfo,
    }) {
      state.postInfo = postInfo;
      state.groupInfo = groupInfo;
      state.topicInfo = topicInfo;
      state.creatorInfo = creatorInfo;
      state.postAction = postAction;
      state.dataSource = 'preload';
      state.postLoading = false;
    },
    resetState (state) {
      Object.assign(state, createState());
    },
    setPreData (state, preData) {
      const data = formatPreData(preData);
      if (!data.postInfo) {
        return;
      }
      const postAction = data.postInfo.extent?.userActionWithPost ?? null;
      const topicInfo =  data.postInfo.extent?.topic ?? null;
      state.postInfo = data.postInfo;
      state.groupInfo = data.groupInfo;
      state.topicInfo = data.topicInfo;
      state.creatorInfo = data.creator;
      state.postAction = postAction;
      state.dataSource = 'preData';
      state.topicInfo = topicInfo;
      state.postLoading = false;
    },
  },
  actions: {
    initPageData ({ commit, state, rootState }, {
      params,
      preData,
      preloadData,
    }) {
      if (preloadData) {
        const data = formatPreData(preloadData);
        if (!data.postInfo || !data.creator) {
          return Promise.reject(data);
        }
        const postAction = data.postInfo.extent?.userActionWithPost ?? null;
        const topicInfo =  data.postInfo.extent?.topic ?? null;
        // commit('updatePostInfo', data.postInfo);
        // commit('updateCreatorInfo', data.creator);
        // commit('updateGroupInfo', data.groupInfo);
        // commit('updateTopicInfo', topicInfo);
        // commit('updatePostAction', postAction);
        // commit('setDataSource', 'preloadData');
        commit('setPreloadData', {
          postInfo: data.postInfo,
          groupInfo: data.groupInfo,
          topicInfo,
          postAction,
          creatorInfo: data.creator,
        });
        return Promise.resolve(data);
      }
      if (preData && state.dataSource !== 'cgi') {
        try {
          const data = formatPreData(preData);
          // console.log('this.initPageData preData', data);
          if (!data.postInfo) {
            return Promise.reject(data);
          }
          // console.log('getPreData', data, data.postInfo.extent.userActionWithPost);
          const postAction = data.postInfo.extent?.userActionWithPost ?? null;
          const topicInfo =  data.postInfo.extent?.topic ?? null;
          commit('updatePostInfo', data.postInfo);
          commit('updateCreatorInfo', {creatorInfo: data.creator, groupInfo: data.groupInfo});
          commit('updateGroupInfo', data.groupInfo);
          commit('updateTopicInfo', topicInfo);
          commit('updatePostAction', postAction);
          commit('setDataSource', 'preData');
          return Promise.resolve(true);
        } catch (error) {
          console.error(error);
          return Promise.reject(error);
        }
      }
      const { postId } = params;
      const localData = pageLocalStore.find(postId);
      if (localData && state.dataSource !== 'preData' && state.dataSource !== 'cgi') {
        logger.info('use localData');
        commit('updatePostInfo', localData.postInfo);
        commit('updateCreatorInfo', {creatorInfo: localData.creator, groupInfo: localData.groupInfo});
        commit('updateGroupInfo', localData.groupInfo);
        commit('updatePostAction', localData.postAction);
        commit('setDataSource', 'local');
        if (preData) return Promise.resolve(true);
      }
      if (!state.postInfo) {
        commit('setPostLoading', true);
      }
      return postApi.getPostInfoByPostId(params).then((res) => {
        if (rootState.app.isInBackground) return Promise.stop();
        if (res && res.info && res.info[0]) {
          const data = res.info[0];
          const postAction =  data.postInfo.extent?.userActionWithPost ?? null;
          const topicInfo =  data.postInfo.extent?.topic ?? null;
          // 新加圈子工具栏字段，查找福利站
          const toolBars = Array.isArray(res.toolBars?.items) ? res.toolBars.items : []
          const welfareInfo = toolBars.filter(item => item.type === 1)[0];
          if (welfareInfo) commit('updateWelfareInfo', welfareInfo);
          const giftNum = Number(res.giftNum);
          if (giftNum > 0) commit('updateGiftNumber', giftNum)
          commit('updatePostInfo', data.postInfo);
          commit('updateCreatorInfo', {creatorInfo: data.creator, groupInfo: data.groupInfo});
          commit('updateGroupInfo', data.groupInfo);
          commit('updateTopicInfo', topicInfo);
          commit('updatePostAction', postAction);
          commit('setDataSource', 'cgi');
          pageLocalStore.add(postId, data);
        }
        return Promise.resolve(res);
      }).catch((err) => {
        if (rootState.app.isInBackground) return Promise.stop();
        return Promise.reject(err);
      });
    },
    saveStateToLocal ({ state }) {
      const postId = state.postInfo.id;
      const data = {
        postInfo: state.postInfo,
        creator: state.creatorInfo,
        postAction: state.postAction,
        groupInfo: state.groupInfo,
      };
      if (state.dataSource === 'cgi' && postId) {
        pageLocalStore.add(postId, data);
      }
    },
  },
};

export const pageMixin = {
  beforeCreate () {
    if (!this.$store.state[PAGE_STORE_MODULE_NAME]) {
      this.$store.registerModule(PAGE_STORE_MODULE_NAME, pageStore);
    }
    // document.addEventListener('visibilitychange', (event) => {
    //   console.log('pageMixin visibilitychange', event);
    //   if (document.hidden) {
    //     // 隐藏的时候储存数据到本地
    //     this.$store.dispatch(`${PAGE_STORE_MODULE_NAME}/saveStateToLocal`);
    //   }
    // }, false);
  },
  beforeDestroy () {
    if (this.$store.state[PAGE_STORE_MODULE_NAME]) {
      this.$store.commit(`${PAGE_STORE_MODULE_NAME}/resetState`);
    }
  },
  computed: {
    pageLoading: {
      get () {
        return this.$store.state[PAGE_STORE_MODULE_NAME].postLoading;
      },
      set (val) {
        this.$store.commit(`${PAGE_STORE_MODULE_NAME}/setPostLoading`, val);
      },
    },
    postInfo: {
      get () {
        return this.$store.state[PAGE_STORE_MODULE_NAME].postInfo;
      },
      set (val) {
        this.$store.commit(`${PAGE_STORE_MODULE_NAME}/updatePostInfo`, val);
      },
    },
    creatorInfo: {
      get () {
        return this.$store.state[PAGE_STORE_MODULE_NAME].creatorInfo;
      },
      set (val) {
        this.$store.commit(`${PAGE_STORE_MODULE_NAME}/setCreatorInfo`, val);
      },
    },
    groupInfo: {
      get () {
        return this.$store.state[PAGE_STORE_MODULE_NAME].groupInfo;
      },
      set (val) {
        this.$store.commit(`${PAGE_STORE_MODULE_NAME}/updateGroupInfo`, val);
      },
    },
    welfareInfo: {
      get () {
        return this.$store.state[PAGE_STORE_MODULE_NAME].welfareInfo;
      },
      set (val) {
        this.$store.commit(`${PAGE_STORE_MODULE_NAME}/updateWelfareInfo`, val);
      },
    },
    giftNumber: {
      get () {
        return this.$store.state[PAGE_STORE_MODULE_NAME].giftNumber;
      },
      set (val) {
        this.$store.commit(`${PAGE_STORE_MODULE_NAME}/updateGiftNumber`, val);
      },
    },
    topicInfo: {
      get () {
        return this.$store.state[PAGE_STORE_MODULE_NAME].topicInfo;
      },
      set (val) {
        this.$store.commit(`${PAGE_STORE_MODULE_NAME}/updateTopicInfo`, val);
      },
    },
    postAction: {
      get () {
        return this.$store.state[PAGE_STORE_MODULE_NAME].postAction;
      },
      set (val) {
        this.$store.commit(`${PAGE_STORE_MODULE_NAME}/updatePostAction`, val);
      },
    },
    voteInfo: {
      get () {
        const voteInfo = this.postInfo?.extent?.voteInfos?.[0] || null;
        if (voteInfo) {
          // 用于数据上报使用
          voteInfo.reportData = {
            groupId: this.postInfo?.group?.id || this.postInfo?.groupId, // 圈子id
            contenttype: this.postInfo?.type, // 上报帖子id
            contentid: this.postInfo?.id, // 帖子类型
          };
        }
        return voteInfo;
      },
      set (val) {
        if (!this.voteInfo) {
          return;
        }

        this.postInfo.extent.voteInfos[0] = val;
        this.$store.commit(`${PAGE_STORE_MODULE_NAME}/updatePostInfo`, this.postInfo);
      },
    },
    dataSource () {
      return this.$store.state[PAGE_STORE_MODULE_NAME].dataSource;
    },
  },
};
