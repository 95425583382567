<template>
  <select
    v-model="compValue"
    class="ui-select"
  >
    <option
      v-if="placeholder"
      :value="PLACEHOLDER_VALUE"
      disabled
      selected
      hidden
    >
      {{ placeholder }}
    </option>
    <option
      v-for="(item, index) in list"
      :key="index"
      :value="item[keyName]"
    >
      {{ item[valueName] }}
    </option>
  </select>
</template>

<script>
const PLACEHOLDER_VALUE = '$__SELECT_PLACEHOLDER';

export default {
  name: 'UiSelect',
  model: {
    prop: 'value',
    event: 'change',
  },
  props: {
    value: {
      type: [String, Number],
      default: '',
    },
    list: {
      type: Array,
      default: () => [],
    },
    keyName: {
      type: String,
      default: '',
    },
    valueName: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      PLACEHOLDER_VALUE,
    };
  },
  computed: {
    compValue: {
      get() {
        return this.value || PLACEHOLDER_VALUE;
      },
      set(val) {
        if (val !== PLACEHOLDER_VALUE) {
          this.$emit('change', val);
        }
      },
    },
  },
  watch: {
    //
  },
  methods: {
    //
  },
};
</script>

<style lang="scss">
.ui-select {
  border:none;
  appearance: none;
  background-color: transparent;
}
</style>
