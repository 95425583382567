<!-- eslint-disable vue/require-component-is -->
<template>
  <div class="com-lottery-button-group">
    <!-- app外打开，时段未开始，均与用户状态无关，因此放在这里全局处理。-->
    <lottery-button-base
      v-if="!env.isApp || !hasLogin()"
      @click.native="jumpToOpenApp"
      type="primary"
    >
      参与抽奖
    </lottery-button-base>
    <lottery-button-base
      v-else-if="!isActivityStart()"
      disabled
    >
      活动未开始
    </lottery-button-base>
    <component
      v-else
      :is="buttonStatusArr[lotteryType]"
      @openRollingDialog="openRollingDialog"
    />
  </div>
</template>
<script>
import { checkEnv } from '@/util/browser-env';
import launchApp from '@/util/launchApp';
import LotteryButtonGroupOnce from '../lottery-button-group-once/index.vue';
import LotteryButtonGroupDaily from '../lottery-button-group-daily/index.vue';
import LotteryButtonBase from '../lottery-button-base/index.vue';

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    LotteryButtonGroupOnce,
    // eslint-disable-next-line vue/no-unused-components
    LotteryButtonGroupDaily,
    LotteryButtonBase,
  },
  props: {
    lotteryType: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      env: checkEnv(),
      buttonStatusArr: {
        O: 'LotteryButtonGroupOnce',
        D: 'LotteryButtonGroupDaily',
        I: 'LotteryButtonGroupDaily', // 临时测试
      },
    };
  },
  inject: ['buttonStatus'],
  methods: {
    hasLogin() {
      return Object.keys(this.$store.state.user.userInfo || {}).length;
    },
    // 此处用方法，不用computed, 确保每次计算的状态是正确的
    isActivityStart() {
      const status = this.buttonStatus;
      return status.activityStartStatus;
    },
    isActivityEnd() {
      const status = this.buttonStatus;
      return status.activityStartStatus && status.activityEndStatus;
    },
    openRollingDialog(...arg) {
      this.$emit('openRollingDialog', ...arg);
    },
    jumpToOpenApp() {
      if (!this.env.isApp) {
        launchApp({
          scheme: `tgc://browser?url=${encodeURIComponent(`${window.location.href}${window.location.href.indexOf('?') !== -1 ? '&' : '?'}_gcwv=2`)}`,
        });
      // }
      } else if (!this.hasLogin()) {
        this.$store.dispatch('user/login').then(() => {
          window.location.reload();
        });
      }
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
