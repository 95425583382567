<template>
  <div class="com-black-post-item">
    <!-- eslint-disable -->
  <div class="post-title"
    v-html="compTitle"
  />
  <PostAuthor
    ref="postAuthorRef"
    class="post-author"
    :creator-info="creatorInfo"
    :uid="uid"
    :avatar="avatar"
    :nickname="nickname"
    :view-num="viewNum"
    :user-level="userLevel"
    :follow-status.sync="followStatus"
    :account-type="accountType"
    :vip-type="vipType"
    :publish-time="publishTime"
    :current-time="currentTime"
    :show-online-status="false"
    @changeUserFollowStatus="onChangeUserFollowStatusHandler"
  />
  <div class="post-content-wrapper">
    <div class="post-content-line" />
    <div
      class="post-content"
      v-html="compText"
    />
  </div>
</div>
</template>

<script>

import { filterXSS } from 'xss';
import PostAuthor from '@/component/post-author/index.vue';
// import PostText from '@/component/post-text/index.vue';
// import TextView from '@/ui/text-view/index.vue';

export default {
  name: 'ComBlackPostItem',
  components: {
    PostAuthor,
  },
  props: {
    creatorInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    uid: {
      type: [String, Number],
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    avatar: {
      type: String,
      default: '',
    },
    nickname: {
      type: String,
      default: '',
    },
    viewNum: {
      type: [String, Number],
      default: 0,
    },
    userLevel: {
      type: Number,
      default: 1,
    },
    followStatus: {
      type: Number,
      default: 0,
    },
    accountType: {
      type: Number,
      default: 0,
    },
    vipType: {
      type: Number,
      default: 0,
    },
    publishTime: {
      type: Number,
      default: 0,
    },
    currentTime: {
      type: Number,
      default: 0,
    },
    content: {
      type: String,
      default: '',
    },
    postInfo: {
      type: Object,
      default() {
        return null;
      },
    },
  },
  computed: {
    compTitle() {
      let str = filterXSS(this.title);
      // str = str.replace(/(禁言\d+天)/g, (x) => `<em>${x}</em>`);
      let index = str.lastIndexOf('，');
      if (index === -1) {
        index = str.lastIndexOf(',');
      }
      if (index > 0) {
        str = `${str.slice(0, index + 1)}<em>${str.slice(index + 1)}</em>`;
      }
      return str;
    },
    compText() {
      const str = filterXSS(this.content);
      return str;
    },
  },
  methods: {
    onChangeUserFollowStatusHandler(payload) {
      this.$emit('changeUserFollowStatus', payload);
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
