// import conversionCookie from '@tencent/game-community-js-sdk/dist/conversionCookies';
import Cookie from 'js-cookie';
import axios from 'axios';
import { checkEnv } from '@/util/browser-env';
import { isServerRender } from '@/util/env';
import { logger } from '@/util/logger';

let conversionCookie = () => Promise.reject(new Error('no conversionCookie function'));
if (!isServerRender) {
  const conversionCookiePromise = import('@tencent/game-community-js-sdk/dist/conversionCookies');
  conversionCookie = () => conversionCookiePromise.then(({ default: fn }) => {
    fn();
  }).catch((err) => {
    logger.error('conversionCookie', err);
    throw err;
  });
}

export default {
  // 惰性加载js
  jsLoader(src) {
    return new Promise((resolve, reject) => {
      const headTag = document.querySelector('head');
      const scriptTag = document.createElement('script');
      scriptTag.type = 'text/javascript';
      scriptTag.onload = () => {
        resolve(true);
      };
      scriptTag.onerror = (e) => {
        logger.err('jsLoader err', e);
        reject(new Error('loading script failed'));
      };
      scriptTag.src = src;
      headTag.appendChild(scriptTag);
    });
  },

  // 惰性加载milojs milojs加载情况并校验用户登录态 本组件默认仅限app内使用，因此暂不提供登录与登出功能哦
  checkMiloLoadAndLogin() {
    const hasLoadMilo = [];
    if (!window.$) {
      hasLoadMilo.push(this.jsLoader('//ossweb-img.qq.com/images/js/mobile_bundle/util/zepto.js'));
    }
    if (!window.milo) {
      hasLoadMilo.push(this.jsLoader('//ossweb-img.qq.com/images/js/mobile_bundle/milo.js'));
    }

    return Promise.all(hasLoadMilo).then(() => new Promise((resolve, reject) => {
      conversionCookie().then(() => {
        if (checkEnv().isApp) {
          // 修改加载 act.desc.js url
          const originalMiloLoadScript = window.milo.loadScript;
          const reg = new RegExp(`^((https?:)?\\/\\/)${window.location.host.replace(/\./g, '\\.')}(\\/.*\\/act\\.desc\\.js)$`, 'i');
          window.milo.loadScript = (url, ...args) => {
            let replaceUrl = url;
            if (reg.test(url)) {
              replaceUrl = `${RegExp.$1}ossweb-img.qq.com${RegExp.$3}`;
            }
            originalMiloLoadScript.apply(window.milo, [].concat(replaceUrl, args));
          };
          // 修改同步 AME 登录态
          // eslint-disable-next-line no-multi-assign
          window.milo.loginStatus.syncToAME = window.milo.loginStatus.clearAME = (callback) => {
            // eslint-disable-next-line no-unused-expressions
            typeof callback === 'function' && callback();
          };
          window.iUseQQConnect = Number(Cookie.get('loginType')) === 2 ? 1 : 0;
          const miloConfig = {
            loginBtn: '',
            wxloginBtn: '',
            logoutBtn: '',
            loginedDiv: '',
            unloginDiv: '',
            userinfoSpan: '',
            appConfig: {
              WxAppId: 'wxd35a5b0c66e8b683',
              scope: 'snsapi_userinfo',
            },
            oQQConnectParams: {
              appId: '101821651',
              scope: 'get_user_info',
              state: 'STATE',
            },
            iUseQQConnect: window.iUseQQConnect || 0,
          };
          window.need('biz.login', (LoginManager) => {
            LoginManager.checkLogin(resolve, () => {
              resolve('no_check_env');
            }, miloConfig);
          });
        } else {
          reject(new Error('env not allow'));
        }
      });
    }));
  },

  // 获取流程ID
  getFlowIdByName(nameText, arr = []) {
    let filterResult = null;
    arr.some((v) => {
      if (v.flowName === nameText) {
        filterResult = v;
        return true;
      }
      return false;
    });
    return filterResult;
  },

  // 拉取活动的配置文件
  getLotteryConfig(activityId) {
    // 优先去正式环境拉配置文件，拉不到再回测试环境拉
    const proUrl = `https://p.shanxian.qq.com/ceiba/c/prod/${activityId}/act_config.json?v=${new Date().valueOf()}`;
    const preUrl = `https://p.shanxian.qq.com/ceiba/c/pre/${activityId}/act_config.json?v=${new Date().valueOf()}`;
    return axios({
      method: 'head',
      url: proUrl,
      timeout: 20000,
    }).then(res => res.status === 200 && !/image\/*/.test(res.headers['content-type']))
      .catch(() => false)
      .then(result => axios({
        method: 'get',
        url: result ? proUrl : preUrl,
        timeout: 50000,
      }))
      .then(response => ({
        isProd: /\/prod\//i.test(response.request.responseURL),
        ...response.data,
      }));
  },

  // 填写地址信息
  saveGiftAddress({
    activityId, flowId, packageInfo, name, mobile, address, packageId,
  }) {
    return new Promise((resolve, reject) => {
      conversionCookie().then(() => {
        window[`amsCfg_${flowId}`] = {
          iActivityId: activityId,
          flowId,
          sData: {
            iShow: 0,
            sName: name,
            iPackageId: packageId,
            sPackageInfo: packageInfo,
            sMobile: mobile,
            sAddress: address,
            sProvince: 'xxx',
            sCity: 'xxx',
            sIdentity: 365202198702123205, // 需求要求不用填写身份证号，此处随便传一个值供ams校验
          },
          _everyRead: true,
          success: (res) => {
            if (Number(res.iRet) === 0 && res.sMsg === '添加成功') {
              resolve(res);
            } else {
              reject(new Error(res.sMsg));
            }
          },
          error: (res) => {
            logger.err('conversionCookie  err', reject(new Error(res.sMsg)));
          },
          fFlowProssFailed: (res) => {
            reject(new Error(res.sMsg));
          },
        };
        window.amsSubmit(activityId, flowId);
      });
    });
  },

  // 查询地址信息
  getGiftAddress({
    activityId,
    flowId,
  }) {
    return new Promise((resolve, reject) => {
      conversionCookie().then(() => {
        window[`amsCfg_${flowId}`] = {
          iActivityId: activityId,
          flowId,
          sData: {
            iShow: 1,
          },
          _everyRead: true,
          sNeedSubmitPopDiv: false,
          success: (res) => {
            if (Number(res.iRet) === 0) {
              resolve(res);
            } else {
              reject(new Error(res.sMsg));
            }
          },
          fail: (res) => {
            reject(res);
          },
          fFlowProssFailed: (res) => {
            reject(new Error(res.sMsg));
          },
        };
        window.amsSubmit(activityId, flowId);
      });
    });
  },

  // 拉取用户中奖记录
  getLotteryHistory({
    activityId,
    flowId,
    gameCode,
    page = 1,
    size = 10,
  }) {
    return new Promise((resolve, reject) => {
      conversionCookie().then(() => {
        window[`amsCfg_${flowId}`] = {
          _everyRead: true,
          iAMSActivityId: activityId,
          iLotteryFlowId: flowId,
          activityId: '339834',
          sNeedSubmitPopDiv: false,
          sData: {
            sServiceType: gameCode,
            gameId: gameCode,
          },
          gameId: gameCode,
          isForce: true,
          pageSize: size,
          pageNow: page,
          getData: (list, res) => {
            if (Number(res.iRet) !== 0) {
              reject(new Error(res.msg));
              return;
            }
            resolve({
              list: res.myGiftList,
              pageNow: Number(res.pageNow),
              pageTotal: Number(res.pageTotal),
            });
          },
          error: (res) => {
            reject(new Error(res.sMsg));
          },
          fail: (res) => {
            reject(new Error(res.sMsg));
          },
        };
        window.amsSubmit(activityId, flowId);
      });
    });
  },

  // 拉取用户参与状态与资格
  getUserLotteryStatus({
    activityId,
    flowId,
    gameCode,
  }) {
    return new Promise((resolve, reject) => {
      conversionCookie().then(() => {
        window[`amsCfg_${flowId}`] = {
          _everyRead: true,
          iActivityId: activityId,
          iFlowId: flowId,
          gameId: gameCode,
          sData: {
            sServiceType: gameCode,
            gameId: gameCode,
          },
          sNeedSubmitPopDiv: false,
          fFlowSubmitEnd: (res) => {
            if (Number(res.iRet) !== 0) {
              reject(new Error(res.msg));
              return;
            }
            resolve(res);
          },
          fFlowSubmitFailed: (res) => {
            reject(new Error(res.sMsg));
          },
          fFlowProssFailed: (res) => {
            reject(new Error(res.sMsg));
          },
        };
        window.amsSubmit(activityId, flowId);
      });
    });
  },

  // 绑定用户角色大区
  bindGameAreaRole({
    activityId,
    flowId,
    sData = {},
  }) {
    return new Promise((resolve, reject) => {
      conversionCookie().then(() => {
        window[`amsCfg_${flowId}`] = {
          sData,
          _everyRead: true,
          iActivityId: activityId,
          iFlowId: flowId,
          sNeedSubmitPopDiv: false,
          type: '',
          fFlowSubmitEnd: (res) => {
            if (Number(res.iRet) !== 0) {
              reject(new Error(res.msg));
              return;
            }
            resolve(res);
          },
          fFlowSubmitFailed: (res) => {
            reject(new Error(res.sMsg));
          },
          fFlowProssFailed: (res) => {
            reject(new Error(res.sMsg));
          },
        };
        window.amsSubmit(activityId, flowId);
      });
    });
  },

  // 获取用户绑定角色
  getBindGameRole({
    activityId,
    flowId,
    sData = {},
  }) {
    return new Promise((resolve, reject) => {
      conversionCookie().then(() => {
        window[`amsCfg_${flowId}`] = {
          sData,
          _everyRead: true,
          iActivityId: activityId,
          iFlowId: flowId,
          sNeedSubmitPopDiv: false,
          fFlowSubmitEnd: (res) => {
            if (Number(res.iRet) !== 0) {
              reject(new Error(res.msg));
              return;
            }
            resolve(res);
          },
          fFlowSubmitFailed: (res) => {
            reject(new Error(res.sMsg));
          },
          fFlowProssFailed: (res) => {
            reject(new Error(res.sMsg));
          },
        };
        window.amsSubmit(activityId, flowId);
      });
    });
  },

  // 拉取游戏大区基础信息
  getGameAreaInfo(gameCode) {
    let gameAreaVar = `${gameCode.toUpperCase()}ServerSelect`;
    const areaSelectUrl = `//gameact.qq.com/comm-htdocs/js/game_area/utf8verson/${gameCode.toLowerCase()}_server_select_utf8.js`;
    let gameAreaVarPromise = true;
    if (!window[gameAreaVar]) {
      gameAreaVarPromise = this.jsLoader(areaSelectUrl);
    }
    return Promise.resolve(gameAreaVarPromise).then(() => {
      gameAreaVar = window[gameAreaVar] || {};
      const systemList = (gameAreaVar.STD_SYSTEM_DATA || []).map(v => ({
        id: v.v,
        name: v.t,
      }));
      const channelList = (gameAreaVar.STD_CHANNEL_DATA || []).map(v => ({
        id: v.v,
        name: v.t,
      }));
      const areaList = []; // 一级大区
      const partitionList = []; // 二级大区 （端游才有2级大区）
      // let hasSecondArea = false;
      // (gameAreaVar.STD_DATA || []).some((v) => {
      //   if (v.opt_data_array && v.opt_data_array.length) {
      //     hasSecondArea = true;
      //     return true;
      //   }
      //   return false;
      // });
      (gameAreaVar.STD_DATA || []).forEach((v) => {
        areaList.push({
          id: v.v,
          name: v.t,
          status: v.status === '0' ? 0 : 1,
          display: v.display === '0' ? 0 : 1,
          system: v.s || '',
          channel: v.c || '',
        });
        if (v.opt_data_array && v.opt_data_array.length) {
          v.opt_data_array.forEach((v1) => {
            partitionList.push({
              id: v1.v,
              name: v1.t,
              status: v.status === '0' ? 0 : 1,
              display: v.display === '0' ? 0 : 1,
              areaId: v.v,
              system: v.s || '',
              channel: v.c || '',
            });
          });
        }
      });
      return {
        areaList,
        partitionList,
        systemList,
        channelList,
      };
    });
  },

  // 拉取ams游戏大区选择器
  getGameAreaSelectConfig() {
    return new Promise((resolve) => {
      window.need(['biz.roleselector'], resolve);
    });
  },

  getGameRoleInfo({
    gameCode,
    areaId,
    partitionId,
    platId,
  }) {
    return this.getGameAreaSelectConfig().then(RoleSelector => new Promise((resolve, reject) => {
      conversionCookie().then(() => {
        RoleSelector.queryRoleInfoByParams(
          {
            game: gameCode,
            area: areaId,
            partition: partitionId,
            platid: platId,
          },
          (roleData) => {
            resolve(roleData);
          },
          (err) => {
            logger.err('conversionCookie err', err);
            reject(new Error(err));
          },
        );
      });
    }));
  },

  // 查询用户绑定大区角色
  getBindGameRoleInfo({
    activityId,
    flowId,
  }) {
    return new Promise((resolve, reject) => {
      conversionCookie().then(() => {
        window[`amsCfg_${flowId}`] = {
          _everyRead: true,
          iActivityId: activityId,
          iFlowId: flowId,
          sNeedSubmitPopDiv: false,
          fFlowSubmitEnd: (res) => {
            if (Number(res.iRet) !== 0) {
              reject(new Error(res.msg));
              return;
            }
            resolve(res);
          },
          fFlowSubmitFailed: (res) => {
            reject(new Error(res.sMsg));
          },
          fFlowProssFailed: (res) => {
            reject(new Error(res.sMsg));
          },
        };
        window.amsSubmit(activityId, flowId);
      });
    });
  },

  // 参与抽奖
  getLotteryResult({
    activityId,
    flowId,
    sData = {},
    postId,
  }) {
    return new Promise((resolve, reject) => {
      conversionCookie().then(() => {
        window[`amsCfg_${flowId}`] = {
          iAMSActivityId: activityId,
          iLotteryFlowId: flowId,
          activityId: '339834',
          sData: {
            ...sData,
            sExtend4: postId,
          },
          gameId: sData.gameId,
          _everyRead: true,
          sNeedSubmitPopDiv: false,
          onGetGiftSuccessEvent: (res, failedRet) => {
            resolve(res, failedRet);
          },
          onGetGiftFailureEvent: (res) => {
            const errCode = Number(res.iRet);
            let errMsg = res.sMsg;
            if (errCode === 900 || errCode === 901) {
              errMsg = '该账号异常，请尝试更换账号参与抽奖';
            }
            reject(new Error(errMsg));
          },
          fFlowProssFailed: (res) => {
            reject(new Error(res.sMsg));
          },
        };
        window.amsSubmit(activityId, flowId);
      });
    });
  },

};
