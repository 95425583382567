<template>
  <div
    :class="`size${size}`"
    class="com-post-author"
  >
    <Avatar
      v-if="avatar"
      :size="size"
      :avatar="avatar"
      :account-type="accountType"
      :vip-type="vipType"
      @click.native="$checkAppDeco(onAvatarClickHandler);onAvatarClickReport();"
      class="com-post-author-avatar"
    />
    <div
      v-else
      class="com-post-author-avatar-loading"
    />
    <section class="com-post-author-main">
      <div
        @click="$checkAppDeco($linkToPersonal, uid)"
        class="com-post-author-main-nickname"
      >
        <div class="com-post-author-main-nickname-text ui-nowrap">
          {{ nickname }}
        </div>
        <GroupOwner
          v-if="creatorInfo && creatorInfo.groupOwnerType"
          :group-owner-type="creatorInfo.groupOwnerType"
          class="com-post-author-main-nickname-label"
        />
        <LabelAuthorRank
          v-if="isShowLabelAuthorRank"
          :level="Number(userLevel)"
          class="com-post-author-main-nickname-label"
        />
        <!-- 勋章 -->
        <badge-icon
          v-if="creatorInfo && creatorInfo.userBadge && !!creatorInfo.userBadge.isShow"
          :user-info="creatorInfo"
          class="com-post-author-main-nickname-label"
        />
        <!-- 声望图标 -->
        <PrestigeIcon
          v-if="creatorInfo && creatorInfo.renownInfo && creatorInfo.renownInfo.iconUrl"
          :icon-url="creatorInfo.renownInfo.iconUrl"
          class="com-post-author-main-nickname-label"
        />
        <!-- 在线状态 -->
        <OnlineStatus
          v-if="creatorInfo && showOnlineStatus"
          :status="creatorInfo.onlineStatus"
          :game-code="creatorInfo.extend && creatorInfo.extend.teamGameCode"
          class="com-post-author-main-nickname-label"
        />
      </div>
      <span class="com-post-author-main-info ui-nowrap">
        <template v-if="creatorInfo && creatorInfo.identification && Number(vipType) === 1">
          <span>{{ creatorInfo.identification }}</span>
          <span class="dot">·</span>
        </template>
        <span>{{ relativeTime }}</span>
        <span
          v-if="Number(viewNum) > 0"
          class="dot"
        >·</span>
        <span
          v-if="Number(viewNum) > 0"
          class="ui-nowrap"
        >{{ viewNum | viewNumToText }} 浏览</span>
      </span>
    </section>
    <Button
      v-if="!isSamePerson && showFollowButton"
      :size="'m'"
      :type="followBtnType"
      :disabled="followStatus !== 0"
      @click="$checkAuthDeco(onFollowClickFn);onFollowClickFnReport()"
      clickable
    >
      {{ followBtnText }}
    </Button>
    <Dialog
      v-model="cancelFollowDialogStatus"
      :confirm="true"
      @confirm="onCancelFollowHandler"
    >
      确认取消关注？
    </Dialog>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import LabelAuthorRank from '@/ui/label/author-rank.vue';
import Avatar from '@/ui/avatar/index.vue';
import Button from '@/ui/button/index.vue';
import Dialog from '@/ui/dialog/index.vue';
import userApi from '@/api/user';
import PrestigeIcon from '@/ui/label/prestige-icon.vue';
import OnlineStatus from '@/ui/label/online-status.vue';
import BadgeIcon from '@/ui/label/badge-icon.vue';
import GroupOwner from '@/ui/label/group-owner.vue';
import { getRelativeTime } from '@/util/util';
import {
  send as noticeSend, sendGlobalNotification, receiveGlobalNotification,
} from '@/jsbridge';
import { logger } from '@/util/logger';
import compareVersion from '@/util/compareVersion';

export default {
  name: 'ComPostAuthor',
  components: {
    BadgeIcon,
    Avatar,
    LabelAuthorRank,
    Button,
    Dialog,
    PrestigeIcon,
    OnlineStatus,
    GroupOwner,
  },
  inject: ['reportData'],
  props: {
    creatorInfo: {
      type: Object,
      default() {
        return null;
      },
    },
    size: {
      type: String,
      default: 'm',
    },
    uid: {
      type: [String, Number],
      default: '',
    },
    avatar: {
      type: String,
      default: '',
    },
    nickname: {
      type: [String, Number],
      default: '',
    },
    userLevel: {
      type: Number,
      default: 1,
    },
    followStatus: { // 0: 未关注 1: 已关注 2: 互相关注
      type: Number,
      default: 0,
    },
    accountType: {
      type: Number,
      default: 0,
    },
    vipType: {
      type: Number,
      default: 0,
    },
    viewNum: {
      type: [String, Number],
      default: '',
    },
    publishTime: {
      type: Number,
      default: 0,
    },
    currentTime: {
      type: Number,
      default: 0,
    },
    desc: {
      type: String,
      default: '',
    },
    followBtnType: {
      type: String,
      default: 'primary',
    },
    showOnlineStatus: {
      type: Boolean,
      default: true,
    },
    dataSource: { // 数据来源
      type: String,
      default: 'cgi', // preData, local, cgi
    },
  },
  data() {
    return {
      cancelFollowDialogStatus: false,
      showFollowButton: false,
    };
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp,
      userInfo: state => state.user.userInfo,
    }),
    relativeTime() {
      return getRelativeTime(this.publishTime, this.currentTime);
    },
    // followBtnType () {
    //   return this.followStatus === 0 ? 'primary' : 'disabled';
    // },
    followBtnText() {
      return {
        0: '关注',
        1: '已关注',
        2: '已关注', // 其实是相互关注 详情页只显示关注即可
      }[this.followStatus] || '';
    },
    isSamePerson() {
      return this.userInfo && this.userInfo.uid && this.uid && (Number(this.userInfo.uid) === Number(this.uid));
    },
    isShowLabelAuthorRank() {
      const { creatorInfo, accountType, vipType, userLevel } = this;
      const condition1 = ((Number(accountType) > 0 && Number(vipType) !== 1) || Number(accountType) <= 0);
      const condition2 = (!creatorInfo.userBadge || !creatorInfo.userBadge.isShow);
      return creatorInfo && condition1 && Number(userLevel) > 0 && condition2;
    },
  },
  watch: {
    dataSource(newVal) {
      // 来自后端数据，且未关注的时候，才展示关注按钮
      if (newVal === 'cgi' && this.followStatus === 0) {
        this.showFollowButton = true;
      }
    },
    followStatus(newVal) {
      if (this.dataSource === 'cgi' && newVal === 0) {
        this.showFollowButton = true;
      }
    },
  },
  mounted() {
    if (this.dataSource === 'cgi' && this.followStatus === 0) {
      this.showFollowButton = true;
    } else {
      this.showFollowButton = false;
    }
    if (this.isApp) {
      receiveGlobalNotification('g_followStatus', (params) => {
        logger.info('receive GlobalNotification success', params);
        // this.$toast(`receive GlobalNotification success${JSON.stringify(params)}`);
        if (Number(params.userId) === Number(this.uid)) {
          this.$emit('changeUserFollowStatus', params.followStatus);
          logger.info('receive GlobalNotification success', params);
        }
      });
    }
  },
  beforeDestroy() {
    this.resetData();
  },
  methods: {
    resetData() {
      this.cancelFollowDialogStatus = false;
      this.showFollowButton = false;
    },
    onFollowClickFnReport() {
      if (this.reportData) {
        if ([1, 2].indexOf(this.followStatus) > -1) {
          // 点击关注按钮
          this.reportData({
            moduleid: 6,
            eventtype: 3,
            extendid: 2,
          }, '内容详情页取关按钮点击');
        } else {
          // 点击关注按钮
          this.reportData({
            moduleid: 6,
            eventtype: 3,
            extendid: 1,
          }, '内容详情页关注按钮点击');
        }
      }
    },
    onFollowClickFn() {
      if (this.lockFollowBtn) return false;
      if ([1, 2].indexOf(this.followStatus) > -1) {
        this.cancelFollowDialogStatus = true;
      } else {
        this.lockFollowBtn = true;
        userApi.followUser({
          uid: this.uid,
        })
          .then(() => {
            this.$emit('changeUserFollowStatus', 1);
            this.$toast('关注成功');
            if (this.reportData) {
              this.reportData({
                moduleid: 6,
                eventtype: 6,
                extendid: 1,
              }, '详情页关注成功');
            }
            this.sendNotice(1);
          })
          .catch((error) => {
            this.$toast(error?.data.msg || '关注失败');
          })
          .finally(() => {
            this.lockFollowBtn = false;
          });
      }
      return true;
    },
    onCancelFollowHandler() {
      this.lockFollowBtn = true;
      userApi.cancelFollowUser({
        uid: this.uid,
      })
        .then(() => {
          this.$emit('changeUserFollowStatus', 0);
          this.$toast('取消关注成功');
          if (this.reportData) {
            this.reportData({
              moduleid: 6,
              eventtype: 6,
              extendid: 2,
            }, '详情页关注成功');
          }
          this.sendNotice(0);
        })
        .catch(() => {
          this.$toast('取消关注失败');
        })
        .finally(() => {
          this.lockFollowBtn = false;
        });
    },
    onAvatarClickHandler() {
      this.$linkToPersonal(this.uid);
    },
    onAvatarClickReport() {
      if (this.reportData) {
        this.reportData({
          moduleid: 4,
          eventtype: 3,
          extendid: 1,
        }, '详情页作者头像点击');
      }
    },
    sendNotice(followStatus) {
      if (compareVersion('1.3') >= 0) {
        // 1.3版本支持全局通信
        sendGlobalNotification('g_followStatus', {
          followStatus: 1,
          userId: Number(this.uid),
        });
        logger.info('sendGlobalNotification success', { followStatus, userId: Number(this.uid) });
      } else {
        // 通知hippy页面
        noticeSend({
          noticeId: 'HippyNotification',
          noticeEvent: 'g_followStatus',
          noticeParams: {
            followStatus,
            userId: Number(this.uid),
          },
        }).then((res) => {
          logger.info('发送hippy通知成功', res);
        });
      }
    },
  },
};
</script>
<style lang="scss" src="./scss/index.scss"></style>
