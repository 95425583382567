<template>
  <div class="lottery-dialog-role">
    <h2 class="lottery-dialog-role__title">
      {{ (containsGame && nearRoleShowStatus) ? '最近活跃角色' : roleDialogTitle[`${containsGame}-${needShowQqInput}`] }}
    </h2>
    <span
      @click="closeGameInfoInput"
      class="lottery-dialog-role__close"
    />
    <div
      v-if="containsGame"
      class="lottery-dialog-role__select-box"
    >
      <div
        v-if="!nearRoleShowStatus"
        class="lottery-dialog-role__select"
      >
        <!-- area 级大区 -->
        <select
          v-model="activePartition"
          :class="activePartition.id ? '' : 'placeholder'"
          class="lottery-dialog-role__select-area"
        >
          <option
            :value="{}"
            disabled
            selected
            style="display:none"
          >
            {{ partitionError || '请选择游戏大区' }}
          </option>
          <option
            v-for="item in partitionList"
            :key="item.id"
            :value="item"
          >
            {{ item.name }}
          </option>
        </select>
        <!-- role -->
        <select
          v-if="containsGame"
          v-model="activeRole"
          :class="activeRole.roleId ? '' : 'placeholder'"
          class="lottery-dialog-role__select-role"
        >
          <option
            :value="{}"
            disabled
            selected
            style="display:none"
          >
            {{ roleError || '请选择角色' }}
          </option>
          <option
            v-for="item in roleList"
            :key="item.id"
            :value="item.data"
          >
            {{ item.name }}
          </option>
        </select>
      </div>

      <div
        v-else
        class="lottery-dialog-role__near"
      >
        <div
          @click="closeNearRoleShow"
          class="lottery-dialog-role__near-switch"
        >
          选择其它
        </div>
        <ul class="lottery-dialog-role__near-list">
          <li
            v-for="item in nearRoleList"
            :key="item.id"
            :class="activeRole.roleId===item.id ? 'active' : ''"
            @click="activeRole=item.data"
          >
            <div class="lottery-dialog-role__near-box">
              <div class="lottery-dialog-role__near-list-name">
                <span>角色：</span>{{ item.roleName }}
              </div>
              <div class="lottery-dialog-role__near-list-area">
                {{ item.partitionName }}
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <label
      v-if="needShowQqInput"
      class="lottery-dialog-role__input-qq"
    >
      <input
        v-model.trim="bindQQInput"
        placeholder="请填写QQ号"
        type="number"
      >
    </label>
    <p
      v-if="needShowQqInput"
      class="lottery-dialog-role__tips"
    >
      该QQ号用于Q币礼品发放，一旦绑定暂时不支持修改与解绑，请务必确认填写无误
      <span id="btn_cancel" />
    </p>
    <div class="lottery-dialog-role__button">
      <lottery-button-base
        :disabled="!hasSetRoleAndQQ"
        @click.native="jumpToLottery"
        type="primary"
      >
        提交开始抽奖
      </lottery-button-base>
    </div>
  </div>
</template>

<script>
import cookie from 'js-cookie';
import {
  getPartitionRoleList,
  getPartitionList,
} from '@/util/http/ams-api';
import lotteryApi from '@/api/lottery';
import userApi from '@/api/user';
import { decode } from '@/util/base64';
import { logger } from '@/util/logger';
import LotteryButtonBase from '../lottery-button-base/index.vue';

const userLogid = cookie.get('uid');

export default {
  components: {
    LotteryButtonBase,
  },
  props: {
    containsGame: {
      type: Boolean,
      default: false,
    },
    needShowQqInput: {
      type: Boolean,
      default: false,
    },
    gameCode: {
      type: String,
      default: '',
    },
    nearRoleList: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      roleDialogTitle: {
        'true-true': '完善抽奖信息，开始抽奖',
        'true-false': '请填写游戏信息',
        'false-true': '请填写QQ号',
        'false-false': '请填写相关信息',
      },
      partitionList: [],
      roleList: [],
      activePartition: {},
      activeRole: {},
      partitionError: false,
      roleError: false,
      bindQQInput: '',
      hasBindRole: {},
      nearRoleShowStatus: true,

      amsLock: false,
    };
  },
  inject: ['flowInfo', 'lotteryReportData'],
  computed: {
    hasSetRoleAndQQ() {
      const qqFlag = this.needShowQqInput ? this.bindQQInput : true;
      const roleFlag = this.containsGame ? this.activeRole.roleId : true;
      return qqFlag && roleFlag;
    },
  },
  watch: {
    activePartition() {
      this.getRoleList();
    },

  },
  created() {
    logger.info(`抽奖模块：游戏信息初始化(${userLogid})`, this.containsGame, this.nearRoleList.length, this.needShowQqInput, this.gameCode);
    if (this.containsGame) {
      if (!this.nearRoleList.length) {
        this.closeNearRoleShow();
      } else {
        this.activeRole = this.nearRoleList[0].data;
      }
      this.getBindRole();
    }
  },
  methods: {
    getPartitionList() {
      this.partitionError = null;
      return getPartitionList({
        gameCode: this.gameCode,
      }).then((res) => {
        if (res.partitionList && res.partitionList.length > 0) {
          this.partitionList = res.partitionList.map(item => ({
            id: item.id,
            name: decode(item.name),
          }));
        } else {
          this.partitionError = '未查到大区';
        }
      })
        .catch((err) => {
          if (err && err.data && err.data.msg) {
            this.$toast(err.data.msg);
          } else {
            this.$toast('获取游戏大区失败');
          }
        });
    },
    getRoleList() {
      this.roleError = null;
      return getPartitionRoleList({
        gameCode: this.gameCode,
        partitionId: this.activePartition.id,
      }).then((res) => {
        if (res.roles && res.roles.length > 0) {
          this.roleList = res.roles.map(item => ({
            id: item.roleId,
            name: decode(item.roleName),
            data: item,
          }));
        } else {
          this.roleError = '未查到角色';
        }
      })
        .catch(() => {
          this.roleError = true;
        });
    },

    postBindQQ() {
      return userApi.bindUserQQ({
        uid: this.$store.state.user.userInfo.uid,
        qq: this.bindQQInput,
      }).then((result) => {
        logger.info(`抽奖模块：绑定qq调用成功(${userLogid})`, this.bindQQInput, result);
        if (result.ret === 0) {
          this.lotteryReportData({
            eventtype: '06',
            extendid: '04',
          }, '成功绑定QQ号上报');
          return true;
        }
        this.$toast(result.msg || '绑定QQ失败，请稍后重试');
        return false;
      })
        .catch((e) => {
          logger.info(`抽奖模块：绑定qq失败(${userLogid})`, e);
          this.$toast(e.data.msg || '绑定QQ失败，请稍后重试');
          return false;
        });
    },
    postBindRole() {
      if (!this.activeRole.roleId) {
        this.$toast('请选择角色');
        return false;
      }
      const flowInfo = lotteryApi.getFlowIdByName('绑定换绑角色', this.flowInfo());
      const bindRoleInfo = {
        sArea: this.activeRole.areaId,
        sPartition: this.activeRole.partitionId,
        sPlatId: this.activeRole.platId,
        sRoleId: this.activeRole.roleId,
        sAreaName: decode(this.activeRole.partitionName),
        sRoleName: decode(this.activeRole.roleName),
        sServiceType: this.gameCode,
      };
      logger.info(`抽奖模块：绑定角色流程(${userLogid})`, flowInfo.flowId, bindRoleInfo);
      return lotteryApi.bindGameAreaRole({
        activityId: flowInfo.activityId,
        flowId: flowInfo.flowId,
        sData: bindRoleInfo,
      }).then((res) => {
        logger.info(`抽奖模块：绑定角色成功(${userLogid})`, res);
        if (res.jData && res.jData.result === 0) {
          return bindRoleInfo;
        }
        this.$toast(res.sMsg);
        return false;
      })
        .catch((e) => {
          logger.info(`抽奖模块：绑定角色失败(${userLogid})`, e);
          this.$toast(e.message || '绑定角色失败，请稍后重试');
          return false;
        });
    },
    getBindRole() {
      const flowInfo = lotteryApi.getFlowIdByName('查询绑定角色', this.flowInfo());
      logger.info(`抽奖模块：查询绑定角色流程(${userLogid})`, flowInfo.flowId);
      return lotteryApi.getBindGameRole({
        activityId: flowInfo.activityId,
        flowId: flowInfo.flowId,
        sData: {
          sServiceType: this.gameCode,
          // sPlatId: sPlatId
        },
      }).then((res) => {
        logger.info(`抽奖模块：查询绑定角色结果(${userLogid})`, res);
        if (res.iRet === 0 && res.jData && res.jData.roles) {
          this.hasBindRole = res.jData.roles;
        } else {
          this.hasBindRole = {};
          // console.log(res);
          // this.$toast('服务器繁忙，请稍后重试');
        }
      })
        .catch((e) => {
          logger.info(`抽奖模块：查询绑定角色失败(${userLogid})`, e);
          this.$toast(e.message || '服务器繁忙，请稍后重试');
        });
    },
    closeNearRoleShow() {
      this.nearRoleShowStatus = false;
      this.activeRole = {};
      if (!this.partitionList.length && this.containsGame) {
        this.getPartitionList();
      }
    },
    jumpToLottery() {
      if (this.amsLock) {
        return;
      }
      let postBindQQ = true;
      let postBindRole = true;
      this.amsLock = true;

      logger.info(`抽奖模块：开始抽奖前游戏信息保存(${userLogid})`, this.nearRoleShowStatus, this.activeRole.roleId, this.hasBindRole.roleId, this.needShowQqInput, this.bindQQInput);
      if (this.containsGame) {
        if (!this.activeRole.roleId) {
          this.$toast('请填写游戏信息');
          this.amsLock = false;
          return;
        }
        if (this.activeRole.roleId !== this.hasBindRole.roleId) {
          postBindRole = this.postBindRole();
        }
      }
      if (this.needShowQqInput) {
        if (!this.bindQQInput || !/^[1-9]\d{4,10}$/.test(this.bindQQInput)) {
          this.$toast('请填写正确格式的QQ号');
          this.amsLock = false;
          return;
        }
        postBindQQ = this.postBindQQ();
      }
      Promise.all([
        postBindQQ,
        postBindRole,
      ]).then((result) => {
        logger.info(`抽奖模块：游戏信息保存成功(${userLogid})`, result);
        let bindRoleInfo = result[1];
        if (result[0] && bindRoleInfo) {
          if (Object.prototype.toString.call(bindRoleInfo) !== '[object Object]') {
            // 不需要重新绑定，则将历史绑定角色进行转换传递
            bindRoleInfo = {
              sArea: this.hasBindRole.areaId,
              sPartition: this.hasBindRole.partitionId,
              sPlatId: this.hasBindRole.platId,
              sRoleId: this.hasBindRole.roleId,
              sAreaName: decodeURIComponent(this.hasBindRole.partitionName),
              sRoleName: decodeURIComponent(this.hasBindRole.roleName),
              sServiceType: this.gameCode,
            };
          }

          this.$emit('setBindRoleInfo', bindRoleInfo || {});
          this.$emit('openRollingDialog', true);
        }
      })
        .catch((e) => {
          logger.info(`抽奖模块：游戏信息保存失败(${userLogid})`, e.message, e);
          this.$toast('服务器繁忙，请稍后重试');
        })
        .finally(() => {
          this.amsLock = false;
        });
    },
    closeGameInfoInput() {
      this.lotteryReportData({
        eventtype: 3,
        extendid: 4,
      }, '退出游戏信息填写点击');
      this.$emit('closeDialog');
    },
  },
};
</script>
<style lang="scss" src="./scss/index.scss"></style>
