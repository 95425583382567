<template>
  <div
    @click="onGoPostPage(hotInfo.post.extent.postDetailUrl)"
    class="new-game-hot"
  >
    <template v-for="(flagIndex, index) in currentFlagList">
      <span
        :key="index"
        :class="`title-flag-${flagIndex}`"
        class="new-game-hot-icon"
      />
    </template>

    <span
      v-if="showGameName"
      class="new-game-hot-tip"
    >[{{ hotInfo.gameName }}]</span>
    <span class="new-game-hot-content">{{ hotInfo.post.title }}</span>
  </div>
</template>

<script>
import { openView } from '@/jsbridge';
import { isBitTrue } from '../../util/util';

export default {
  name: 'NewGameHot',
  props: {
    hotInfo: {
      type: Object,
      required: true,
    },
    showGameName: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      allFlagList: [1, 3, 5, 13], // 1-官，3-精，5-顶，13-奖 其他都是新 标记14
    };
  },
  computed: {
    currentFlagList() {
      const flag = Number(this.hotInfo.post.flag);
      const list = []
        .concat(this.allFlagList)
        .sort((a, b) => b - a)
        .filter(index => isBitTrue(flag, index));

      return list.length ? list : [14];
    },
  },
  methods: {
    onGoPostPage(url) {
      openView({
        options: url,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.new-game-hot {
  display: flex;
  height: 18px;
  flex-direction: row;
  align-items: center;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  color: #303133;

  &-icon {
    width: 20px;
    height: 20px;
    border-radius: 4px;
    margin-right: 4px;

    &.title-flag-1 {
      background: url("./img/icon-official.png") center no-repeat;
      background-size: contain;
    }

    &.title-flag-3 {
      background: url("./img/icon-marrow.png") center no-repeat;
      background-size: contain;
    }

    &.title-flag-5 {
      background: url("./img/icon-sticky.png") center no-repeat;
      background-size: contain;
    }

    &.title-flag-13 {
      background: url("./img/icon-lottery.png") center no-repeat;
      background-size: contain;
    }

    &.title-flag-14 {
      background: url("./img/icon-new.png") center no-repeat;
      background-size: contain;
    }
  }

  &-tip {
    margin-right: 4px;
    line-height: 20px;
  }

  &-content {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    line-height: 20px;
  }
}
</style>
