/**
 * 新游介绍详情
 * */
import { gameplusApi } from '@/util/http';
export function getNewGameList() {
  return gameplusApi.request({
    url: '/community.SubscribeNewGameSrv/GetNewGameList',
    data: {
    },
    options: {
      errorHandler: false,
    },
  });
}

export function getNewGameDetail(gameCode) {
  return gameplusApi.request({
    url: '/community.SubscribeNewGameSrv/GetNewGameDetail',
    data: {
      gameCode,
    },
    options: {
      errorHandler: false,
    },
  });
}

export function subscribeNewGame(gameCode) {
  return gameplusApi.request({
    url: '/community.SubscribeNewGameSrv/SubscribeNewGame',
    data: {
      gameCode,
    },
    options: {
      errorHandler: false,
    },
  });
}

export function cancelSubscribeNewGame(gameCode) {
  return gameplusApi.request({
    url: '/community.SubscribeNewGameSrv/CancelSubscribeNewGame',
    data: {
      gameCode,
    },
    options: {
      errorHandler: false,
    },
  });
}

export function getGameSubscribeInfo(gameCodes, uid) {
  return gameplusApi.request({
    url: '/community.SubscribeNewGameSrv/GetGameSubscribeInfo',
    data: {
      gameCodes,
      uid,
    },
    options: {
      errorHandler: false,
    },
  });
}

export function getGameSubscribeStats(gameCodes, uid) {
  return gameplusApi.request({
    url: '/community.SubscribeNewGameSrv/GetGameSubscribeStats',
    data: {
      gameCodes,
      uid,
    },
    options: {
      errorHandler: false,
    },
  });
}

export function getNewGameBanner(gameCode) {
  return gameplusApi.request({
    url: '/community.GCHomePage/GetNewGameBanner',
    data: {
      gameCode,
    },
    options: {
      errorHandler: false,
    },
  });
}

export default {
  getNewGameList,
  getNewGameDetail,
  subscribeNewGame,
  cancelSubscribeNewGame,
  getGameSubscribeInfo,
  getGameSubscribeStats,
  getNewGameBanner,
};
