<template>
  <DefaultLayout title="闪现一下">
    <PostLoading
      v-if="pageLoading"
    />
    <PostError
      v-else-if="postError"
      :custom-header="true"
      :text="postError"
      :status="postErrorStatus"
    />
    <div
      ref="postDetailRef"
      v-else
      class="page-post-detail"
    >
      <GroupOrGameHeader
        ref="postDetailHeaderRef"
        v-if="isApp"
        :group-info="groupInfo"
        :is-from-group="isFromGroup"
        :show-new-game-header="gameStage"
        :show-group-header="showGroupHeader"
        @menuClick="setShareData();setShareDataReport()"
        title-content="新游早知道"
      >
        <div
          v-if="isFromGroup"
          :style="headerStyle"
          class="page-post-detail-header"
        >
          <Avatar
            :avatar="creatorInfo.iconUrl"
            :account-type="creatorInfo.accountType"
            :vip-type="creatorInfo.vipType"
            class="page-post-detail-header-avatar"
            size="xs"
          />
          <p class="page-post-detail-header-main ui-nowrap">
            {{ creatorInfo.nickName }}
          </p>
          <Button
            v-show="!isUserEqualToAuthor && showFollowButton"
            :type="followBtnConfig.type"
            :size="'s'"
            @click.native="$checkAuthDeco(onFollowClickFn);onFollowClickFnReport();"
            width="53px"
          >
            {{ followBtnConfig.text }}
          </Button>
        </div>
      </GroupOrGameHeader>

      <NoticeBar
        v-if="Number(postInfo.status) === 2"
        type="warning"
      >
        帖子审核中，仅自己可见，稍后刷新页面查看
      </NoticeBar>
      <NoticeBar
        v-if="Number(postInfo.status) === 4"
        type="error"
      >
        帖子审核未通过
      </NoticeBar>
      <NoticeBar
        v-if="Number(postInfo.status) === 7"
        type="error"
      >
        帖子被管理员下架
      </NoticeBar>
      <BlackPostItem
        ref="postDetailItemRef"
        v-if="postInfo.type===11"
        :creator-info="creatorInfo"
        :uid="creatorInfo.uid"
        :avatar="creatorInfo.iconUrl"
        :nickname="creatorInfo.nickName"
        :user-level="creatorInfo.level"
        :follow-status="creatorInfo.followStatus"
        :account-type="creatorInfo.accountType"
        :vip-type="creatorInfo.vipType"
        :title="postInfo.title"
        :view-num="postInfo.readCnt"
        :content="postInfo.content || postInfo.summary"
        :publish-time="Number(postInfo.createTime)"
        :current-time="parseInt(new Date().getTime() / 1000)"
        :post-info="postInfo"
        @changeUserFollowStatus="onChangeUserFollowStatusHandler"
        class="post-item"
      />
      <PostItem
        ref="postDetailItemRef"
        v-else
        :creator-info="creatorInfo"
        :uid="creatorInfo.uid"
        :avatar="creatorInfo.iconUrl"
        :nickname="creatorInfo.nickName"
        :user-level="creatorInfo.level"
        :follow-status="creatorInfo.followStatus"
        :account-type="creatorInfo.accountType"
        :vip-type="creatorInfo.vipType"
        :title="postInfo.title"
        :view-num="postInfo.readCnt"
        :content="postInfo.content"
        :publish-time="Number(postInfo.createTime)"
        :current-time="parseInt(new Date().getTime() / 1000)"
        :post-info="postInfo"
        :data-source="dataSource"
        :post-main="'picture'"
        :vote-info="voteInfo"
        :group-info="groupInfo"
        :topic-info="topicInfo"
        @changeUserFollowStatus="onChangeUserFollowStatusHandler"
        @clickVoteOption="handleClickVoteOption"
        @clickCommentButton="handleClickCommentButton"
        class="post-item"
      />
      <!-- 排序 异步渲染组件 -->
      <template v-for="item in moduleOrder">
        <PostWelfareV2
          v-if="!isApp && item===1"
          :key="item"
          :game-code="groupInfo.gameCode"
        />
        <PostComment
          ref="postCommentRef"
          v-else-if="(dataSource === 'cgi' || (postInfo.extent && postInfo.extent.ifComplete === true)) && item===2 "
          :key="item"
          :anchor-watcher="[postInfo]"
          :post-main="'picture'"
          :post-id="Number(postId)"
          :comment-total-number="postInfo.commentCnt"
          :author-info="creatorInfo"
          :report-content-type="reportContentType"
          :show-online-status="postInfo.type!==11"
          :group-info="groupInfo"
          @updateCommentAmount="onUpdateCommentAmountHandler"
          class="post-comment"
        />
        <PostRcmd
          v-else-if="!isApp && item===3"
          :key="item"
          :post-id="postInfo.id"
          :group-id="groupInfo.id"
          :group-name="groupInfo.name"
          :kk-gid="groupInfo.kkGid"
        />
        <PostActivity
          v-else-if="!isApp && item===4"
          :key="item"
        />
      </template>

      <!-- 预约组件 下滑消失 滑到底部的时候出现，上滑的时候出现-->
      <transition name="slide">
        <div
          v-show="showNewGameCard"
          class="page-post-detail-game"
        >
          <NewGameInfo
            v-if="newGameInfo"
            :game-info="newGameInfo"
            :bg-color="groupInfo.colorValue"
            :is-show-sort-tags="true"
            :is-show-enter-group="false"
          />
        </div>
      </transition>

      <!-- 底部展示 -->
      <div
        v-show="gameStage"
        class="page-post-detail-seat"
      />
      <PostFooter
        ref="postFooterRef"
        v-if="isApp"
        :post-info="postInfo"
        :creator-info="creatorInfo"
        :post-action="postAction"
        @postComment="onPostCommentHandler"
        @getPostAction="onGetPostAction"
        @update="onUpdatePostFooterHandler"
        @commentIconClick="onCommentIconClick"
        @inputTipsShow="onInputTipsShow"
        @inputTipsClick="onInputTipsClick"
        class="post-footer ui-border-t"
      />
      <Dialog
        v-model="deleteDialogStatus"
        :confirm="true"
        :confirm-text-style="{color: '#303133'}"
        :confirm-text="'确认删除'"
        @confirm="onDeleteDialogConfirmHandler"
      >
        确认要删除吗？
      </Dialog>
      <PostAccuse
        v-model="accusePopupStatus"
        :post-main="'picture'"
        :post-info="postInfo"
      />
      <ShareDownload
        v-if="!isApp"
        :channel="'sxnrtuwen'"
      />
      <ManageDialog
        v-model="isShowManageDialog"
        :post-info="postInfo"
        :creator-info="creatorInfo"
        :group-info="groupInfo"
        @update="onManageUpdate"
      />
      <GameSubscribeDialog
        v-model="dialogShow"
        :group-id="newGameInfoGroupId"
        :is-join-group="true"
        content="新游开放下载后会通知你哟，现在可以前往游戏圈子，了解更多游戏信息"
        left-btn-content="知道了"
        right-btn-content="进入圈子"
      />
    </div>
  </DefaultLayout>
</template>

<script>
import { mapState } from 'vuex';
import DefaultLayout from '@/layouts/default/index.vue';
import PostItem from '@/component/post-item/index.vue';
import GroupOrGameHeader from '@/component/group-or-game-header/index.vue';
import PostFooter from '@/component/post-footer/index.vue';
import PostLoading from '@/component/post-loading/index.vue';
import PostError from '@/component/post-error/index.vue';
import ShareDownload from '@/component/share-download/index.vue';
import NoticeBar from '@/ui/notice-bar/index.vue';
import Dialog from '@/ui/dialog/index.vue';
import Avatar from '@/ui/avatar/index.vue';
import Button from '@/ui/button/index.vue';
import PostAccuse from '@/component/post-accuse/index.vue';
import postApi from '@/api/post';
import postDetail from '@/plugin/mixin/postDetail';
import postReportData from '@/plugin/mixin/postReportData';
import BlackPostItem from '@/component/black-post-item/index.vue';
import {
  postComment, setRightButton, showShareDialog, showShareDialogV2, popBack,
  send as noticeSend, openView, sendGlobalNotification, receiveGlobalNotification,
} from '@/jsbridge';
import { throttle, debounce, screenYAxisScroll } from '@/util/util';
import { reportEnum } from '@/api/report';
import { logger } from '@/util/logger';
import { checkEnv } from '@/util/browser-env';
import { getShareUrl } from '@/util/get-base-url';
import { setH5Share } from '@/util/h5-share';
import compareVersion from '@/util/compareVersion';
import aegis from '@/util/aegis';
import unicodeString from '@/util/unicode-string';
import { serverDocument as document, isServerRender } from '@/util/env';
import ManageDialog from '@/component/post-manage/manage-dialog/index.vue';
import NewGameInfo from '@/component/new-game-info/index.vue';
import { pageStore, PAGE_STORE_MODULE_NAME, pageMixin } from './store';

const PostComment = () => ({
  component: import('@/component/post-comment/index.vue'),
});
const PostActivity = () => ({
  component: import('@/component/post-activity/index.vue'),
});
const PostRcmd = () => ({
  component: import('@/component/post-rcmd/index.vue'),
});
const PostWelfareV2 = () => ({
  component: import('@/component/post-welfare-v2/index.vue'),
});
const GameSubscribeDialog = () => import ('@/component/game-subscribe-dialog/index.vue');

export default {
  name: 'PagePostDetail',
  components: {
    PostItem,
    PostComment,
    PostFooter,
    PostLoading,
    PostError,
    PostActivity,
    PostRcmd,
    PostWelfareV2,
    NoticeBar,
    PostAccuse,
    Dialog,
    Avatar,
    Button,
    ShareDownload,
    BlackPostItem,
    DefaultLayout,
    GroupOrGameHeader,
    ManageDialog,
    NewGameInfo,
    GameSubscribeDialog,
  },
  mixins: [postDetail, postReportData, pageMixin],
  data() {
    return {
      headerStyle: {},
      pageError: false,
      postId: '',
      postType: 1,
      // postInfo: null,
      // creatorInfo: null,
      commentList: null,
      // groupInfo: null,
      // postAction: null,
      bottomDistance: 60,
      // 删除帖子
      deletePostLoading: false,
      deleteDialogStatus: false,
      // 举报帖子
      accusePopupStatus: false,
      isShowManageDialog: false, // 是否展示管理弹窗
      contentType: '', // 默认是帖子类型，新增加了 blackHouse 小黑屋帖子
      reportPageId: reportEnum.pageid.POST_DETAIL,
      reportContentType: reportEnum.contenttype.POST_DETAIL,
      retryTimer: null,
      retryCount: 0,
      maxRetryCount: 0,
      pageErrorText: '系统繁忙',
      postErrorStatus: 'fail',
      pageLoaded: false,
      showFollowButton: false, // 是否展示关注状态
      postReadHeigh: isServerRender ? 0 : document && document.body.offsetHeight, // 帖子已阅读的高度
      postContentHeight: 0, // 帖子内容的高度
      postLimitFlag: false, // 当当前打开完全读完后，页面再怎么滚动也不上报了
      isFromGroup: false, // 是否从圈子打开, http://tapd.oa.com/GamingCommunity/prong/stories/view/1020419237859949769
      // 福利站文字样式
      welfareFontStyle: {},
      prevId: '', // 上级来源页ID
      defaultModuleOrder: [2], // 默认只有评论模块
      moduleOrder: this.defaultModuleOrder, // 模块顺序  1 礼包，2 评论，3 相关内容，4 活动
      dialogShow: false,
      newGameInfoShow: false,
      lastScrollPosition: 0,
      newGameInfo: null,
      groupId: null,
      gameStage: false,
      showGroupHeader: false,
    };
  },
  computed: {
    ...mapState({
      isApp: state => state.isApp,
      userInfo: state => state.user.userInfo,
      hasPerm: state => state.manager?.hasPerm,
    }),
    followBtnConfig() {
      let type = '';
      let text = '';
      if (this.creatorInfo) {
        type = this.creatorInfo.followStatus === 0 ? 'primary' : 'disabled';
        text = {
          0: '关注',
          1: '已关注',
          2: '已关注', // 其实是相互关注 详情页只显示关注即可
        }[this.creatorInfo.followStatus] || '';
      }
      return {
        type,
        text,
      };
    },
    isUserEqualToAuthor() {
      const isHave = this.userInfo && this.userInfo.uid && this.postInfo;
      return isHave && (Number(this.userInfo.uid) === Number(this.postInfo.creatorId));
    },
    postError() {
      if (this.pageError || !this.postInfo) {
        return this.pageErrorText;
      }
      if (this.postInfo && this.postInfo.status === 2 && !this.isUserEqualToAuthor) {
        return '内容正在审核';
      }
      if (this.postInfo && this.postInfo.status === 4 && !this.isUserEqualToAuthor) {
        return '帖子审核未通过';
      }
      if (this.postInfo && this.postInfo.status === 7 && !this.isUserEqualToAuthor) {
        return '帖子被管理员下架';
      }
      if (this.postInfo && this.postInfo.status === 9) {
        return '帖子已被删除';
      }
      if ([2, 4, 6, 7, 9].indexOf(Number(this.postInfo.status)) === -1) {
        return `状态异常: ${this.postInfo.status}`;
      }
      return false;
    },
    showNewGameCard() {
      return this.newGameInfoShow && this.gameStage;
    },
    isHasGroupInfo() {
      return this.$store[PAGE_STORE_MODULE_NAME].groupInfo;
    },
    newGameInfoGroupId() {
      return this.newGameInfo?.groupId ?? '0';
    },
  },
  watch: {
    userInfo(newVal, oldVal) {
      if (this.pageLoaded) {
        if (newVal && !oldVal) {
          // 从无到有就是登录
          this.initPageData({
            silent: true,
          });
        }
      }
    },
    dataSource(newVal) {
      // 来自后端数据，且未关注的时候，才展示关注按钮
      if (newVal === 'cgi' && this.creatorInfo && this.creatorInfo.followStatus === 0) {
        this.showFollowButton = true;
      }
    },
    groupId(val) {
      if (!this.isApp && val) this.getContentShareModuleOrder();
    },
  },
  storeName: PAGE_STORE_MODULE_NAME,
  storeModules: {
    [PAGE_STORE_MODULE_NAME]: pageStore,
  },
  asyncData({ store, route }, preData) {
    if (!store.state[PAGE_STORE_MODULE_NAME]) {
      store.registerModule(PAGE_STORE_MODULE_NAME, pageStore);
    }
    const { postId } = route.query;
    return store.dispatch(`${PAGE_STORE_MODULE_NAME}/initPageData`, {
      params: {
        postId,
        postType: 1,
      },
      preData,
    });
  },
  beforeDestroy() {
    this.unbindScrollHandler();
    clearTimeout(this.retryTimer);
    this.pageError = false;
    this.resetData();
    this.reportPostReadPercent();
  },
  mounted() {
    this.resetData();
    this.prevId = this.$route.query.prev_id || '';
    this.postId = this.$route.query.postId;
    this.isFromGroup = this.$route.query.pageFrom === '1306';
    this.postType = 1;
    this.contentType = this.$route.query.contentType || '';
    if (this.postId) {
      if (this.isApp) {
        this.registerEvent();
        this.$store.dispatch('user/getUserInfo')
          .then(() => {
            if (this.userInfo) {
              this.initPageData();
            } else {
              this.initPageDataNotLogin();
            }
          })
          .catch((err) => {
            logger.err('getUserInfo err', err);
          });
      } else {
        this.reportPageId = reportEnum.pageid.POST_DETAIL_H5;
        this.initPageDataH5();
      }
    // eslint-disable-next-line no-unreachable
    } else {
      this.pageError = true;
    }
    const scrollTarget = this.getScrollEventTarget(this.$el);
    const onePageHeigh = document && document.body.offsetHeight; // 预防服务端渲染document报错
    this.postContentHeight = 0;
    document.addEventListener('visibilitychange', () => {
      this.reportPostReadPercent();
    }, false);

    this.scrollHandler = throttle(() => {
      if (checkEnv().isApp && this.$refs.postDetailHeaderRef) {
        const h = this.$refs.postDetailHeaderRef.$el.offsetHeight;
        const t = this.$refs.postDetailItemRef.$refs.postAuthorRef.$el.getBoundingClientRect().top;
        let rate = 0;
        if (t > h) {
          rate = 0;
        } else if (t < h) {
          if (t < 0) {
            rate = 1;
          } else {
            rate = Math.round(((h - t) / h) * 100) / 100;
          }
        }
        this.headerStyle = {
          transform: `translateY(${`${40 + rate * -40}%`})`,
          opacity: rate,
          pointerEvents: rate > 0.1 ? 'auto' : 'none',
        };
      }
      // 滚动纪录最大滚动值
      const scrollTop = scrollTarget.scrollTop || document.documentElement.scrollTop || document.body.scrollTop;
      const postDetailHeight = this.$refs.postDetailRef?.scrollHeight - 5;
      // 向上滑动 + 滑到底部 新游卡片出现
      if (scrollTop === 0 || (scrollTop < this.lastScrollPosition) || (scrollTop + onePageHeigh >= postDetailHeight)) {
        this.newGameInfoShow = true;
      } else {
        // 向下滑动 新游卡片消失
        this.newGameInfoShow = false;
      }
      this.lastScrollPosition = scrollTop;

      const currentPostReadHeigh = scrollTop  + onePageHeigh;
      if (currentPostReadHeigh > this.postReadHeigh) {
        this.scrollReportHandler();
        this.postReadHeigh = currentPostReadHeigh;
      }
      if (!this.postContentHeight) {
        this.postContentHeight = this.$refs.postDetailItemRef?.$el?.offsetHeight;
      }
      if (this.postContentHeight && (this.postReadHeigh >= this.postContentHeight)) {
        this.reportPostReadPercent();
      }
      return true;
    }, 17);
    this.scrollReportHandler = debounce(() => {
      this.reportPostReadPercent();
    }, 400);
  },
  provide() {
    return {
      onIsWantHandler: (...arg) => this.onIsWantHandler(...arg),
      dialogShowHandler: (...arg) => this.dialogShowHandler(...arg),
    };
  },
  methods: {
    resetData() {
      this.headerStyle = {};
      this.pageError = false;
      this.postId = '';
      this.postType = 1;
      this.commentList = null;
      this.bottomDistance = 60;
      this.deletePostLoading = false;
      this.deleteDialogStatus = false;
      this.accusePopupStatus = false;
      this.contentType = '';
      this.retryTimer = null;
      this.retryCount = 0;
      this.maxRetryCount = 0;
      this.pageErrorText = '系统繁忙';
      this.postErrorStatus = 'fail';
      this.pageLoaded = false;
      this.showFollowButton = false;
      this.moduleOrder = this.defaultModuleOrder;
      this.dialogShow = false;
      this.newGameInfoShow = false;
      this.lastScrollPosition = 0;
      this.newGameInfo = null;
      this.groupId = null;
      this.gameStage = false;
      this.showGroupHeader = false;
    },
    registerEvent() {
      receiveGlobalNotification('g_followStatus', (params) => {
        // console.log('receive GlobalNotification success', params);
        if (!this.creatorInfo) return;
        // this.$toast(`receive GlobalNotification success${JSON.stringify(params)}`);
        if (Number(params.userId) === Number(this.creatorInfo.uid)) {
          logger.info('receive GlobalNotification success', params);
          this.creatorInfo = {
            ...this.creatorInfo,
            followStatus: params.followStatus,
          };
        }
      });
    },
    reportPostReadPercent() {
      // 目前仅图文帖子上报阅读进度
      if (this.postInfo && Number(this.postInfo.type) === 1 && Number(this.postInfo.subType) === 1) {
        if (!this.postContentHeight || this.postLimitFlag) {
          return;
        }
        if (this.postReadHeigh >= this.postContentHeight) {
          this.postLimitFlag = true;
        }
        this.reportData({
          pageid: '1502',
          moduleid: '00001',
          eventtype: '06',
          extendid: '02',
          completionrate: parseInt(Math.min(1, this.postReadHeigh / this.postContentHeight) * 100, 10),
          ext2: Math.min(this.postReadHeigh, this.postContentHeight),
          ext3: this.postContentHeight,
        }, '帖子详情页阅读完成率');
      }
    },
    onManageUpdate() {
      this.getPostInfo({
        params: {
          flag: 2,
        },
      });
    },
    initPageData({ silent = false } = {}) {
      Promise.all([this.getPostInfo()])
        .then(([res1]) => {
          this.pageLoaded = true;
          if (res1 !== 'error'/* && this.retryCount === 2 */) {
            this.pageLoading = false;
            this.readPostCallback({ postInfo: this.postInfo });
            this.syncPostInfo({ postInfo: this.postInfo });
            // pv上报
            this.reportData({
              moduleid: 1,
              eventtype: 1,
              extendid: 1,
              prev_id: this.prevId,
            }, '帖子详情页曝光');
            this.reportData({
              operid: '1501000010101',
              ext10: this.postInfo?.extent?.voteInfos?.[0]?.id || '',
            }, '所有内容详情页页面曝光');
            this.$nextTick(() => {
              this.bindScrollHandler();
              this.initPageDom();
            });
            this.setRightButton();
            this.pageError = false;
          } else if (this.retryCount < this.maxRetryCount) {
            this.retryCount += 1;
            logger.info(`retry initPageData: count ${this.retryCount}`);
            clearTimeout(this.retryTimer);
            this.retryTimer = setTimeout(() => {
              this.initPageData();
            }, 500 * (this.retryCount));
          } else {
            this.pageLoading = false;
            if (!silent) {
              this.pageError = true;
              aegis.report('详情页打开异常');
            }
          }
          return true;
        });
    },
    initPageDataNotLogin() {
      Promise.all([this.getPostInfo()])
        .then(([res1]) => {
          this.pageLoaded = true;
          if (res1 !== 'error') {
            this.pageLoading = false;
            this.readPostCallback({ postInfo: this.postInfo });
            this.syncPostInfo({ postInfo: this.postInfo });
            // pv上报
            this.reportData({
              moduleid: 1,
              eventtype: 1,
              extendid: 1,
              prev_id: this.prevId,
            }, '帖子详情页曝光');
            this.reportData({
              operid: '1501000010101',
              ext10: this.postInfo?.extent?.voteInfos?.[0]?.id || '',
            }, '所有内容详情页页面曝光');
            this.$nextTick(() => {
              this.bindScrollHandler();
              this.initPageDom();
            }, 0);
            this.setRightButton();
            this.pageError = false;
          } else if (this.retryCount < this.maxRetryCount) {
            this.retryCount += 1;
            logger.info(`retry initPageData: count ${this.retryCount}`);
            clearTimeout(this.retryTimer);
            this.retryTimer = setTimeout(() => {
              this.initPageDataNotLogin();
            }, 500 * (this.retryCount));
          } else {
            this.pageLoading = false;
            this.pageError = true;
            aegis.report('详情页打开异常');
          }
          return true;
        });
    },
    initPageDataH5() {
      Promise.all([this.getPostInfo({
        params: {
          flag: 2,
        },
      })])
        .then(([res1]) => {
          this.pageLoaded = true;
          if (res1 !== 'error') {
            this.pageLoading = false;
            this.reportData({
              moduleid: 1,
              eventtype: 1,
              extendid: 1,
              prev_id: this.prevId,
            }, '帖子详情页曝光');
            this.reportData({
              pageid: '2000',
              moduleid: 1,
              eventtype: 1,
              extendid: 1,
            }, '所有内容详情页页面曝光');
            setH5Share({
              shareTitle: this.postInfo.title || '闪现一下',
              // eslint-disable-next-line import/no-named-as-default-member
              shareDesc: unicodeString.slice(this.postInfo.summary, 200) || '来自闪现一下，来闪现，从不落单~',
              shareLink: getShareUrl(),
              shareImgUrl: this.getPostCoverUrl(this.postInfo) || 'https://static.gameplus.qq.com/business/Share/iOSAPPicon100.png',
            });
            this.$nextTick(() => {
              this.bindScrollHandler();
              this.initPageDom();
            }, 0);
          } else if (this.retryCount < this.maxRetryCount) {
            this.retryCount += 1;
            logger.info(`retry initPageData: count ${this.retryCount}`);
            clearTimeout(this.retryTimer);
            this.retryTimer = setTimeout(() => {
              this.initPageDataH5();
            }, 500 * (this.retryCount));
          } else {
            this.pageLoading = false;
            this.pageError = true;
            aegis.report('详情页打开异常');
          }
          return true;
        });
    },
    getScrollEventTarget(element) {
      let currentNode = element;
      // bugfix, see http://w3help.org/zh-cn/causes/SD9013 and http://stackoverflow.com/questions/17016740/onscroll-function-is-not-working-for-chrome
      while (currentNode && currentNode.tagName !== 'HTML' && currentNode.tagName !== 'BODY' && currentNode.nodeType === 1) {
        const { overflowY } = getComputedStyle(currentNode);
        if (overflowY === 'scroll' || overflowY === 'auto') {
          return currentNode;
        }
        currentNode = currentNode.parentNode;
      }
      return window;
    },
    bindScrollHandler() {
      const scrollTarget = this.getScrollEventTarget(this.$el);
      scrollTarget.addEventListener('scroll', this.scrollHandler);
    },
    unbindScrollHandler() {
      const scrollTarget = this.getScrollEventTarget(this.$el);
      scrollTarget.removeEventListener('scroll', this.scrollHandler);
    },
    getPostInfo({ params = {} } = {}) {
      const { postId } = this.$route.query;
      return new Promise((resolve) => {
        this.$store.dispatch(`${PAGE_STORE_MODULE_NAME}/initPageData`, {
          params: {
            postId,
            postType: 1,
            userId: this.userInfo ? this.userInfo.uid : 0,
            ...params,
          },
        }).then((res) => {
          if (res.info && res.info[0] && res.info[0].postInfo) {
            this.newGameInfo  = res.newGameInfo;
            this.gameStage = res.newGameInfo?.gameInfo?.gameStage === 1;
            this.newGameInfoShow =  this.gameStage;
            this.showGroupHeader = !this.gameStage;
            this.groupId = res.info[0].groupInfo.id;
            return resolve(res);
          }
          logger.error('getPostInfo error', res);
          // this.pageErrorText = '系统繁忙(null)';
          return resolve('error');
        }, (err) => {
          logger.error('getPostInfo error', err);
          this.postErrorStatus = 'fail';
          if (err.status === 200 && err.data) {
            this.pageErrorText = '系统繁忙';
          } else if (err.response) {
            this.pageErrorText = '系统繁忙';
          } else if (err.request) {
            this.pageErrorText = '网络竟然崩溃了，换个姿势刷新试试';
            this.postErrorStatus = 'network';
          } else {
            this.pageErrorText = '系统繁忙';
          }
          return resolve('error');
        });
      });
    },
    getPostAction() {
      return new Promise((resolve) => {
        postApi.getPostAction({
          userId: this.userInfo.uid,
          postId: this.postId,
        }).then(res => resolve(res), () => resolve('error'));
      });
    },
    onGetPostAction() {
      this.getPostAction()
        .then((res) => {
          if (res !== 'error') this.postAction = res;
        });
    },
    onFollowClickFnReport() {
      this.reportData({
        moduleid: 6,
        eventtype: 3,
        extendid: 1,
      });
    },
    onFollowClickFn() {
      try {
        this.$refs.postDetailItemRef.$refs.postAuthorRef.onFollowClickFn();
      } catch (e) {
        logger.error('onFollowClickFn', e);
      }
    },
    setRightButton() {
      if (this.postError) return false;
      setRightButton({
        callback: () => {
          this.setShareData();
        },
      });
      return true;
    },
    setShareDataReport() {
      this.reportData({
        pageid: '1501',
        moduleid: 1,
        eventtype: 3,
        extendid: 1,
      }, '帖子详情页点击右上角按钮');
    },
    setShareData() {
      // if (this.$store.state.isAndroid) {
      //   if (!this.userInfo) {
      //     this.$checkAuthDeco(() => {
      //       this.onGetPostAction();
      //     });
      //     return false;
      //   }
      //   if ((!this.postAction || Object.keys(this.postAction).length === 0)) {
      //     this.onGetPostAction();
      //     this.$toast('系统繁忙，请稍候再试');
      //     return false;
      //   }
      // }
      let action = [
        {
          type: 'follow',
          name: (this.postAction && this.postAction.ifFollow) ? '已收藏' : '收藏',
          native: 0,
        },
      ];
      if (this.userInfo && Number(this.userInfo.uid) === Number(this.creatorInfo.uid)) {
        action.push({
          type: 'delete',
          name: '删除',
          native: 0,
        });
      } else {
        action.push({
          type: 'accuse',
          name: '举报',
          native: 0,
        });
        action.push({
          type: 'block',
          name: '屏蔽',
          native: 0,
        });
      }
      action.push({
        type: 'copyLink',
        name: '复制链接',
        native: 1,
      });

      // https://static.gameplus.qq.com/business/iconh5/manage/icon_94_manege.png
      let showShareDialogFunc = showShareDialog;
      if (this.$compareVersion('1.8.2') >= 0) {
        showShareDialogFunc = showShareDialogV2;
        action = [{
          type: 'tgFriend',
        }].concat(action);
        if (this.hasPerm) { // todo 管理员判断
          action.push({
            type: 'custom',
            title: '管理内容',
            icon: decodeURIComponent('https://static.gameplus.qq.com/business/iconh5/manage/icon_94_manege.png'),
            id: 1,
            native: 0,
          });
          this.reportData({
            operid: '1501000830201',
          }, '内容详情页操作面板管理按钮曝光');
        }
      }
      const shareContent = {
        shareVisible: this.postInfo.status === 6 ? 1 : 0, // 0 分享不可见， 1 可见
        title: this.postInfo.title || '闪现一下',
        // eslint-disable-next-line import/no-named-as-default-member
        summary: unicodeString.slice(this.postInfo.summary, 200) || '来自闪现一下，来闪现，从不落单~',
        targetUrl: getShareUrl(),
        thumbUrl: this.getPostCoverUrl(this.postInfo) || 'https://static.gameplus.qq.com/business/Share/iOSAPPicon100.png',
        action,
        postInfo: {
          postId: this.postId,
          postType: this.postType,
          postCreatorId: this.postInfo.creatorId,
          groupId: this.postInfo.groupId,
          action: (this.postAction && this.postAction.ifFollow) ? 'follow' : 'unfollow',
          userId: (this.userInfo && this.userInfo.uid) || 0,
        },
      };
      logger.info('showShareDialog', shareContent);

      showShareDialogFunc(shareContent)
        .then((res2) => {
          // console.log(`showShareDialog: ${JSON.stringify(res2)}`);
          if (res2.result === 0) {
            switch (res2.data.type) {
              case 'follow':
                this.$checkAuthDeco(() => {
                  this.$refs.postFooterRef.onCollectHandler();
                });
                break;
              case 'delete':
                this.$checkAuthDeco(() => {
                  this.deleteDialogStatus = true;
                });
                break;
              case 'accuse':
                if (checkEnv().isSwitchOn) {
                  this.accusePopupStatus = true;
                } else {
                  this.$checkAuthDeco(() => {
                    this.accusePopupStatus = true;
                  });
                }
                break;
              case 'block':
                postApi.postAction({
                  postId: this.postInfo.id,
                  postType: this.postInfo.type,
                  postCreatorId: this.postInfo.creatorId,
                  userId: (this.userInfo && this.userInfo.uid) || 0,
                  groupId: this.postInfo.groupId,
                  action: 'block',
                }).then(() => {
                  this.$toast('将为你减少此内容推荐');
                })
                  .catch((err) => {
                    logger.err('postAction err', err);
                    this.$toast('将为你减少此内容推荐');
                  });
                break;
              case 'WXSession':
                this.sendNotice({
                  operate: 'share',
                  data: 'WXSession',
                });
                break;
              case 'WXTimeline':
                this.sendNotice({
                  operate: 'share',
                  data: 'WXTimeline',
                });
                break;
              case 'QQFriend':
                this.sendNotice({
                  operate: 'share',
                  data: 'QQFriend',
                });
                break;
              case 'QQZone':
                this.sendNotice({
                  operate: 'share',
                  data: 'QQZone',
                });
                break;
              case 'custom':
                if (parseInt(res2.data.id, 10) === 1) {
                  this.isShowManageDialog = true;
                }
                break;
              default:
                break;
            }
          }
          // 处理上报
          if (res2.data.type) {
            switch (res2.data.type) {
              case 'follow':
                break;
              case 'delete':
                this.reportData({
                  pageid: '1501',
                  moduleid: 32,
                  eventtype: 3,
                  extendid: 1,
                }, '帖子详情页点击删除按钮');
                break;
              case 'accuse':
                this.reportData({
                  pageid: '1501',
                  moduleid: 12,
                  eventtype: 3,
                  extendid: 1,
                }, '帖子详情页点击举报');
                break;
              case 'WXSession':
                this.reportData({
                  pageid: '1501',
                  moduleid: 25,
                  eventtype: 3,
                  extendid: 2,
                }, '分享到微信点击');
                break;
              case 'WXTimeline':
                this.reportData({
                  pageid: '1501',
                  moduleid: 25,
                  eventtype: 3,
                  extendid: 3,
                }, '分享到微信朋友圈点击');
                break;

              case 'QQFriend':
                this.reportData({
                  pageid: '1501',
                  moduleid: 25,
                  eventtype: 3,
                  extendid: 4,
                }, '分享到QQ好友点击');
                break;
              case 'QQZone':
                this.reportData({
                  pageid: '1501',
                  moduleid: 25,
                  eventtype: 3,
                  extendid: 5,
                }, '分享到QQ空间点击');
                break;
              case 'custom':
                if (parseInt(res2.data.id, 10) === 1) {
                  this.reportData({
                    operid: '1501000830301',
                  }, '内容详情页操作面板管理按钮点击');
                }
                break;
              default:
                break;
            }
          }
          return true;
        });
      return true;
    },
    // 获取帖子的封面图片
    getPostCoverUrl(postInfo) {
      return (postInfo && postInfo.pics && postInfo.pics.info && postInfo.pics.info[0] && postInfo.pics.info[0].url) || '';
    },
    // 计算底部 iphonex 适配高度
    initPageDom() {
      // console.log(this.$refs.postFooterRef);
      this.bottomDistance = this.$refs.postFooterRef ? this.$refs.postFooterRef.$el.offsetHeight : 0;
    },
    /**
     * 点击底部添加评论
     * @param {String} commentPrefix 评论前缀
     */
    onPostCommentHandler(commentPrefix = '') {
      let attribute; // 1:普通，2:作者，3:官方，4:置顶
      // accountType:0 为普通用户，1 官方一级 2 官方二级 3 官方个人 4 媒体官方 5 媒体个人
      if (Number(this.userInfo.accountType) > 0 && Number(this.userInfo.accountType) < 4) {
        attribute = 3;
      } else if (Number(this.userInfo.uid) === Number(this.creatorInfo.uid)) {
        attribute = 2;
      } else {
        attribute = 1;
      }
      let { postCommentRef } = this.$refs;
      if (Array.isArray(postCommentRef)) [postCommentRef] = postCommentRef;
      postComment({
        type: 2,
        parentId: this.postId,
        rootId: this.postId,
        commentatorId: this.creatorInfo.uid,
        attribute,
        content: '', // 一级评论不传内容
        nickname: this.creatorInfo.nickName,
        groupId: this.postInfo.groupId,
        commentPrefix,
      }).then((commentData) => {
        logger.info('postCommentData', commentData);
        // 终端弹窗
        postCommentRef.addCommentItem({
          commentData,
        });
        this.reportData({
          moduleid: 15,
          eventtype: 6,
          extendid: 1,
          ext1: 1,
        }, '帖子详情页评论成功');
        this.reportData({
          pageid: '1501',
          moduleid: 15,
          eventtype: 6,
          extendid: 1,
          ext1: 1,
        }, '所有详情页评论成功');
      });
    },
    onUpdateCommentAmountHandler({ num } = {}) {
      this.postInfo = {
        ...this.postInfo,
        commentCnt: this.postInfo.commentCnt + Number(num),
      };
      this.sendNotice({
        operate: 'updateCommentAmount',
        data: this.postInfo.commentCnt,
      });
      this.sendNotice({
        operate: 'commentCnt',
        data: this.postInfo.commentCnt,
      });
    },
    onChangeUserFollowStatusHandler(payload) {
      this.creatorInfo = {
        ...this.creatorInfo,
        followStatus: payload,
      };
    },
    // 更新底部点赞收藏内容
    onUpdatePostFooterHandler(action) {
      this.postAction = action;
    },
    // 点击底部评论按钮
    onCommentIconClick() {
      let { postCommentRef } = this.$refs;
      if (Array.isArray(postCommentRef)) [postCommentRef] = postCommentRef;
      const commentBCR = postCommentRef.$el.getBoundingClientRect();
      const commentTop = commentBCR.top || 0;
      const headerHeight = this.$refs.postDetailHeaderRef.$el.offsetHeight;
      const footerHeight = this.$refs.postFooterRef.$el.offsetHeight;
      const footerOffset = 40; // 底部距离偏差，防止”评论“只露出一点点的情况，没有滑动
      const clientHeight = document.documentElement.clientHeight || document.body.clientHeight;
      const isCommentInView = commentTop <= (clientHeight - footerHeight - footerOffset);

      // 1.如果评论数为0 或 已经在评论处，点击评论按钮，拉起输入框
      if (!this.postInfo.commentCnt || isCommentInView) {
        this.$checkAuthDeco(this.onPostCommentHandler);
      } else {
        // 2. 如果未在评论处，点击评论按钮快速定位评论处
        const scrollDistance = commentTop - headerHeight - footerHeight;
        screenYAxisScroll({ scrollDistance });
      }
      this.onCommentIconClickReport();
    },
    onCommentIconClickReport() {
      this.reportData({
        pageid: 1502,
        moduleid: 15,
        eventtype: 3,
        extendid: 5,
      }, '帖子详情页底部评论点击');
    },
    // 删除帖子
    onDeleteDialogConfirmHandler() {
      if (this.deletePostLoading) return false;
      this.deletePostLoading = true;
      postApi.deletePost({
        id: this.postId,
      }).then(() => {
        this.$toast('删除成功');
        this.sendNotice({
          operate: 'delete',
          data: '',
        });
        popBack();
      })
        .catch(() => {
          this.$toast('删除失败');
        })
        .finally(() => {
          this.deletePostLoading = false;
        });
      return true;
    },
    // 通知其他页面
    sendNotice({
      operate, // 操作，delete postComment postAnswer postAction
      data = '',
    }) {
      if (compareVersion('1.3') >= 0) {
        // 1.3版本支持全局通信
        sendGlobalNotification('g_updatePost', {
          operate,
          id: this.postId,
          data,
        });
      } else {
        // 通知hippy页面
        noticeSend({
          noticeId: 'HippyNotification',
          noticeEvent: 'webUpdatePost',
          noticeParams: {
            operate,
            id: this.postId,
            data,
          },
        }).then(() => {
          // console.log('发送hippy通知成功', res);
        });
      }
    },
    onGroupClickHandlerReport() {
      this.reportData({
        moduleid: 33,
        eventtype: 3,
        extendid: 1,
        ext2: 3,
      }, '帖子详情页所属圈子点击');
    },
    onGroupClickHandler() {
      this.$linkToGroup(this.groupInfo.id);
    },
    onTopicClickHandler(topicId) {
      openView({
        options: `tgc://native?moduleName=topic&topicId=${topicId}`,
      });
    },
    onTopicLabelLayout() {
      this.reportData({
        pageid: 1501,
        moduleid: 52,
        eventtype: 2,
        extendid: 1,
      }, '所有内容详情页话题入口曝光');
      this.reportData({
        moduleid: 52,
        eventtype: 2,
        extendid: 1,
      }, '图文详情页话题入口曝光');
    },
    onTopicClickHandlerReport() {
      this.reportData({
        pageid: 1501,
        moduleid: 52,
        eventtype: 3,
        extendid: 2,
      }, '所有内容详情页话题入口点击');
      this.reportData({
        moduleid: 52,
        eventtype: 3,
        extendid: 2,
      }, '图文详情页话题入口点击');
    },
    onInputTipsShow() {
      this.reportData({
        pageid: 1502,
        moduleid: 15,
        eventtype: 2,
        extendid: 6,
      }, '帖子详情页底部评论引导气泡曝光');
    },
    onInputTipsClick() {
      this.reportData({
        pageid: 1502,
        moduleid: 15,
        eventtype: 3,
        extendid: 6,
      }, '帖子详情页底部评论引导气泡点击');
    },
    handleClickCommentButton(commentPrefix, needDelayPostComment) {
      if (needDelayPostComment) {
        // 页面初始化调起评论框时，延迟500毫秒调起，防止键盘发生错位
        setTimeout(() => {
          this.$checkAuthDeco(this.onPostCommentHandler, commentPrefix);
        }, 500);
      } else {
        this.$checkAuthDeco(this.onPostCommentHandler, commentPrefix);
      }
    },
    handleClickVoteOption(voteInfo) {
      this.voteInfo = voteInfo;
      this.sendUpdatePostGlobalNotification({
        id: this.postInfo.id,
        operate: 'voteInfo',
        data: voteInfo,
      });
    },
    onGroupLabelLayout() {
      this.reportData({
        moduleid: 33,
        eventtype: 2,
        extendid: 1,
        ext2: 3,
      }, '帖子详情页所属圈子曝光');
    },
    getContentShareModuleOrder() {
      postApi.getContentShareModuleOrder(this.groupId)
        .then((res) => {
          if (res.ret === 0 && res.moduleOrder?.length) {
            this.moduleOrder = res.moduleOrder;
          } else {
            // 只有评论
            this.moduleOrder = this.defaultModuleOrder;
          }
        })
        .catch((err) => {
          logger.err('getContentShareModuleOrder err', err);
          this.moduleOrder = this.isApp ? this.defaultModuleOrder : [1, 2, 3, 4];
        });
    },
    dialogShowHandler(isShow) {
      this.dialogShow = isShow;
    },
    onIsWantHandler() {
      this.newGameInfo.subscribeStats.selfSubscribe = !this.newGameInfo.subscribeStats.selfSubscribe;
      // 通知新游早知道页更新页面
      sendGlobalNotification('g_subscribe_stats', {
      });
    },
  },
};
</script>

<style lang="scss" src="./scss/index.scss"></style>
