<template>
  <div :class="[buttonStatus.hasWinPrize ? 'com-lottery-button__col2' : '']">
    <lottery-button-base
      v-if="buttonStatus.hasWinPrize"
      @click.native="jumpToLotteryRecord"
    >
      查看中奖记录
    </lottery-button-base>
    <lottery-button-base
      :class="buttonStatusActive.className"
      :disabled="(buttonStatusActive.className || []).includes('disabled')"
      @click.native="checkToLottery"
      type="primary"
    >
      {{ buttonStatusActive.text }}
    </lottery-button-base>
  </div>
</template>
<script>
import { openUrl } from '@/jsbridge';
import LotteryButtonBase from '../lottery-button-base/index.vue';

export default {
  components: {
    LotteryButtonBase,
  },
  inject: ['buttonStatus', 'flowInfo', 'lotteryReportData'],
  data() {
    return {
      // 是否已过期-是否已参与本次抽奖-本活动是否中过奖-是否需要游戏角色信息
      buttonText: {
        '0-0-0-1': {
          className: ['primary'],
          text: '填写信息，参与今日抽奖',
        },
        '0-0-1-1': {
          className: ['primary'],
          text: '参与今日抽奖',
        },
        '0-0-*-0': {
          className: ['primary'],
          text: '参与今日抽奖',
        },
        '0-1-*-*': {
          className: ['disabled'],
          text: '明天再来',
        },
        '1-*-*-*': {
          className: ['disabled'],
          text: '已结束',
        },
      },
    };
  },
  computed: {
    buttonStatusActive() {
      let result = {};
      const status = this.buttonStatus;
      const activeKey = `${status.activityEndStatus}-${status.hasJoinLottery}-${status.hasWinPrize}-${status.needGameOrQQInfo}`;
      Object.keys(this.buttonText).some((v) => {
        const reg = new RegExp(v.replace(/\*/g, '(0|1)'));
        if (reg.test(activeKey)) {
          result = this.buttonText[v];
          return true;
        }
        return false;
      });
      return result;
    },
  },
  mounted() {
    if (!this.buttonStatus.hasJoinLottery) {
      this.lotteryReportData({
        eventtype: '02',
        extendid: '02',
      }, '抽奖模块抽奖按钮曝光');
    } else if (this.buttonStatus.hasWinPrize) {
      this.lotteryReportData({
        eventtype: '02',
        extendid: '03',
      }, '抽奖模块中奖记录按钮曝光');
    }
  },
  methods: {
    jumpToLotteryRecord() {
      this.lotteryReportData({
        eventtype: '03',
        extendid: '02',
      }, '抽奖模块中奖记录按钮点击');
      openUrl({
        url: `${window.location.origin}/page-lottery/lottery-record/?act_id=${this.flowInfo()[0].activityId}`,
        target: 1,
      });
    },
    checkToLottery() {
      if (this.buttonStatus.hasJoinLottery === 0) {
        this.lotteryReportData({
          eventtype: '03',
          extendid: '01',
        }, '抽奖模块抽奖按钮点击');
        this.$emit('openRollingDialog');
      }
    },
  },
};
</script>
<style lang="scss" src="./scss/index.scss"></style>
