/* eslint-disable no-restricted-properties */
/**
 * @file 颜色相关方法
 */


/**
 * rgb颜色变化为xyz
 *
 * 算法来自安卓代码
 * Convert RGB components to its CIE XYZ representative components.
 * The resulting XYZ representation will use the D65 illuminant and the CIE2° Standard Observer (1931).
 *
 * @param {Number} r red，0-255
 * @param {Number} g green，0-255
 * @param {Number} b blue，0-255
 * @returns {Array.<Number>} XYZ值
 */
export function RGBToXYZ([r, g, b]) {
  let sr = r / 255.0;
  sr = sr < 0.04045 ? sr / 12.92 : Math.pow((sr + 0.055) / 1.055, 2.4);
  let sg = g / 255.0;
  sg = sg < 0.04045 ? sg / 12.92 : Math.pow((sg + 0.055) / 1.055, 2.4);
  let sb = b / 255.0;
  sb = sb < 0.04045 ? sb / 12.92 : Math.pow((sb + 0.055) / 1.055, 2.4);

  const outXyz = [0, 0, 0];
  outXyz[0] = 100 * (sr * 0.4124 + sg * 0.3576 + sb * 0.1805);
  outXyz[1] = 100 * (sr * 0.2126 + sg * 0.7152 + sb * 0.0722);
  outXyz[2] = 100 * (sr * 0.0193 + sg * 0.1192 + sb * 0.9505);

  return outXyz;
}


/**
 * 转换十六进制的rgb格式为十进制色值
 * @param {String} color 颜色值，rgb格式，例如：#667788, #678
 * @returns {Array.<Number>} 返回[r,g,b]格式数组，数组范围 0-255
 */
export function getRGBValue(color) {
  if (!/^#([0-9A-Fa-f]{3}|[0-9A-Fa-f]{6})$/.test(color)) {
    throw TypeError(`getRGBValue 参数 color 不是色值: ${color}`);
  }

  const outRGB = [0, 0, 0];
  let r;
  let g;
  let b;
  if (color.length === 4) {
    r = color.slice(1, 2);
    g = color.slice(2, 3);
    b = color.slice(3, 4);
    r += r;
    g += g;
    b += b;
  } else if (color.length === 7) {
    r = color.slice(1, 3);
    g = color.slice(3, 5);
    b = color.slice(5, 7);
  }
  outRGB[0] = parseInt(r, 16);
  outRGB[1] = parseInt(g, 16);
  outRGB[2] = parseInt(b, 16);
  return outRGB;
}


/**
 * 计算颜色亮度
 *
 * 算法来自安卓代码 calculateLuminance
 * Returns the luminance of a color as a float between 0 and 1.
 * Defined as the Y component in the XYZ representation of color
 *
 * @param {String} color rgb格式色值，#887766
 * @returns {Number} 亮度值，值在0-1之间
 */
export function calculateLuminance(color) {
  const [r, g, b] = getRGBValue(color);
  const [, y] = RGBToXYZ([r, g, b]);
  // Y分量就是亮度
  return y / 100;
}


export default {
  RGBToXYZ, calculateLuminance, getRGBValue,
};
