<template>
  <div class="com-post-footer">
    <div class="com-post-footer-inner">
      <Avatar
        :avatar="userInfo && userInfo.iconUrl"
        :account-type="userInfo && userInfo.accountType"
        :vip-type="userInfo && userInfo.vipType"
        @click.native="$checkAuthDeco($linkToPersonal, userInfo && userInfo.uid)"
        class="ui-avatar"
        size="s"
      />
      <div
        @click="$checkAuthDeco(onCommentHandler);onCommentHandlerReport();"
        class="fake-input"
      >
        <div
          v-if="showInputTips"
          @click.stop="onInputTipsClick"
          class="input-tips"
        >
          <span class="icon-triangle" />
          我来说两句
        </div>
        <p class="ui-nowrap">
          添加评论...
        </p>
      </div>
      <ul
        v-if="type==='default'"
        class="operate-list"
      >
        <li @click="onCommentIconClick">
          <span class="icon-comment" />
          <span class="text">{{ postInfo.commentCnt || '评论' }}</span>
        </li>
        <li
          :class="postAction && postAction.ifFollow !== 0 ? 'active' : ''"
          @click="$checkAuthDeco(onCollectHandler);onCollectHandlerReport()"
        >
          <Icon
            :active="!!(postAction && postAction.ifFollow)"
            class="icon-collect"
            name="favor"
            size="l"
          />
          <span
            v-if="postInfo && postInfo.followCnt > 0 "
            class="text"
          >{{ postInfo.followCnt | translateNumToText }}</span>
          <span
            v-else
            class="text"
          >收藏</span>
        </li>
        <li
          :class="postAction && postAction.ifLike !== 0 ? 'active' : ''"
          @click="$checkAuthDeco(onLikeHandler);onLikeHandlerReport()"
        >
          <ApplaudAni
            v-if="showApplaudAniTime"
            size="m"
          />
          <Icon
            v-else
            :active="!!(postAction && postAction.ifLike)"
            class="icon-collect"
            name="applaud"
            size="l"
          />
          <span
            v-if="postInfo && postInfo.likeCnt > 0"
            class="text"
          >{{ postInfo.likeCnt | translateNumToText }}</span>
          <span
            v-else
            class="text"
          >鼓掌</span>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
import Avatar from '@/ui/avatar/index.vue';
import Icon from '@/ui/icon';
import postApi from '@/api/post';
import ApplaudAni from '@/ui/applaud-ani/index.vue';
import missionApi from '@/api/mission';
import {
  send as noticeSend,
  curdData as sendCurdData,
  showTaskToast,
  sendGlobalNotification,
} from '@/jsbridge';
import compareVersion from '@/util/compareVersion';

export default {
  name: 'ComPostFooter',
  components: {
    Avatar, Icon, ApplaudAni,
  },
  inject: ['reportData'],
  props: {
    type: {
      type: String,
      default: 'default',
    },
    postInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    creatorInfo: {
      type: Object,
      default() {
        return {};
      },
    },
    postAction: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      ifLike: 0,
      ifFollow: 0,
      likeMissionConfig: null,
      showInputTips: false,
      inputTipsTimerId: null,
      showApplaudAniTime: 0,
    };
  },
  computed: mapState({
    isApp: state => state.isApp,
    isAndroid: state => state.isAndroid,
    userInfo: state => state.user.userInfo,
  }),
  mounted() {
    this.getLikeMissionConfig();
    document.addEventListener('visibilitychange', this.onVisibilitychange);
    this.setInputTipsShowTimer();
  },
  beforeDestroy() {
    document.removeEventListener('visibilitychange', this.onVisibilitychange);
    document.removeEventListener('click', this.handleInputTipsHide, true);
    this.clearInputTipsShowTimer();
  },
  methods: {
    onVisibilitychange() {
      this.clearInputTipsShowTimer();
      this.setInputTipsShowTimer();
    },
    onAction({ action } = {}) {
      return postApi.postAction({
        postId: this.postInfo.id,
        postType: this.postInfo.type,
        postCreatorId: this.creatorInfo.uid,
        userId: this.userInfo.uid,
        groupId: this.postInfo.groupId,
        action,
      });
    },
    onCommentIconClick() {
      this.$emit('commentIconClick');
    },
    onCollectHandlerReport() {
      if (this.reportData) {
        this.reportData({
          moduleid: 13,
          eventtype: 3,
          extendid: 1,
        }, '内容详情页底部收藏按钮点击');
      }
    },
    onCollectHandler() {
      if (!this.postAction || Object.keys(this.postAction).length === 0) {
        this.$emit('getPostAction');
        this.$toast('系统繁忙，请稍候再试');
        return false;
      }
      if (this.isCollectLoading) return false;
      this.isCollectLoading = true;
      if (this.postAction.ifFollow === 0) {
        this.onAction({
          action: 'follow',
        })
          .then(() => {
            this.updateAction({
              ifFollow: 1,
            });
            this.$toast('收藏成功');
            this.postInfo.followCnt += 1;
            this.sendPostActionNotice({
              operate: 'postAction',
              data: 'follow',
            });
            this.sendPostActionNotice({
              operate: 'followCnt',
              data: this.postInfo.followCnt,
            });
          }, (err) => {
            this.$logger.err('操作失败err', err);
            if (err.data && err.data.ret === 10013) {
              this.$toast('操作太频繁了');
            } else if (err.data && err.data.ret === 10015) {
              //
            } else {
              this.$toast('操作失败');
            }
          })
          .finally(() => {
            this.isCollectLoading = false;
          });
      } else {
        this.onAction({
          action: 'unfollow',
        })
          .then(() => {
            this.updateAction({
              ifFollow: 0,
            });
            this.sendPostActionNotice({
              operate: 'postAction',
              data: 'unfollow',
            });
            this.postInfo.followCnt = this.postInfo.followCnt > 0 ? this.postInfo.followCnt - 1 : 0;
            this.sendPostActionNotice({
              operate: 'followCnt',
              data: this.postInfo.followCnt,
            });
          }, (err) => {
            if (err.data && err.data.ret === 10013) {
              this.$toast('操作太频繁了');
            } else if (err.data && err.data.ret === 10015) {
              //
            } else {
              this.$toast('操作失败');
            }
          })
          .finally(() => {
            this.isCollectLoading = false;
          });
      }
      return true;
    },
    onCommentHandlerReport() {
      if (this.reportData) {
        this.reportData({
          moduleid: 15,
          eventtype: 3,
          extendid: 2,
        }, '详情页底部评论框点击');
        this.reportData({
          pageid: '1501',
          moduleid: 15,
          eventtype: 3,
          extendid: 1,
        }, '所有详情页通用评论点击');
      }
    },
    onCommentHandler() {
      this.$emit('postComment');
      return true;
    },
    onInputTipsClick() {
      this.$checkAuthDeco(this.onCommentHandler);
      this.$emit('inputTipsClick');
    },
    handleInputTipsHide() {
      if (this.showInputTips) {
        this.showInputTips = false;
        document.removeEventListener('click', this.handleInputTipsHide, true);
      }
    },
    onLikeHandlerReport() {
      if (this.reportData) {
        if (this.postAction.ifLike === 0) {
          this.reportData({
            moduleid: 14,
            eventtype: 3,
            extendid: 1,
            ext1: 1,
            ext2: 1,
          }, '内容详情页底部鼓掌点击');
          this.reportData({
            pageid: '1501',
            moduleid: 14,
            eventtype: 3,
            extendid: 1,
            ext1: 1,
            ext2: 1,
          }, '所有详情页底部点击鼓掌');
        } else {
          this.reportData({
            moduleid: 14,
            eventtype: 3,
            extendid: 3,
          }, '内容详情页底部取消鼓掌点击');
        }
      }
    },
    onLikeHandler() {
      if (!this.postAction || Object.keys(this.postAction).length === 0) {
        this.$emit('getPostAction');
        this.$toast('系统繁忙，请稍候再试');
        return false;
      }
      if (this.isLikeLoading) return false;
      this.isLikeLoading = true;
      if (this.postAction.ifLike === 0) {
        this.showApplaudAniTime = Date.now();
        this.onAction({
          action: 'like',
        })
          .then(() => {
            this.updateAction({
              ifLike: 1,
            });
            this.sendCurdData('like');
            this.sendPostActionNotice({
              operate: 'postAction',
              data: 'like',
            });
            this.postInfo.likeCnt += 1;
            this.sendPostActionNotice({
              operate: 'likeCnt',
              data: this.postInfo.likeCnt,
            });
            if (Date.now() - this.showApplaudAniTime >= 500) { // 动画已结束，直接隐藏动画
              this.showApplaudAniTime = 0;
            } else {
              setTimeout(() => { // 动画未结束，等播放完再隐藏动画
                this.showApplaudAniTime = 0;
              }, Date.now() - this.showApplaudAniTime);
            }
          }, (err) => {
            if (err.data && err.data.msg) {
              this.$toast(err.data.msg);
            } else {
              this.$toast('操作失败');
            }
            this.showApplaudAniTime = 0;
          })
          .finally(() => {
            this.isLikeLoading = false;
          });
      } else {
        this.onAction({
          action: 'unlike',
        })
          .then(() => {
            this.updateAction({
              ifLike: 0,
            });
            this.sendCurdData('unlike');
            this.sendPostActionNotice('unlike');
            this.postInfo.likeCnt = this.postInfo.likeCnt > 0 ? this.postInfo.likeCnt - 1 : 0;
            this.sendPostActionNotice({
              operate: 'postAction',
              data: 'unlike',
            });
            this.sendPostActionNotice({
              operate: 'likeCnt',
              data: this.postInfo.likeCnt,
            });
          }, (err) => {
            if (err.data && err.data.msg) {
              this.$toast(err.data.msg);
            } else {
              this.$toast('操作失败');
            }
          })
          .finally(() => {
            this.isLikeLoading = false;
          });
      }
      return true;
    },
    sendCurdData(postAction) {
      // user_related是否用户相关，0不相关，1表示相关
      // action操作行为，1为查询，2位删除，3为存储
      const time = new Date().setHours(0, 0, 0, 0);
      const curdData = { key: 'day_like_times', user_related: 1, action: 1 };
      sendCurdData(curdData)
        .then((res) => {
          const { data } = res;
          // console.log('get curdData data', data);
          // console.log(`typeof data : ${typeof data}`);
          let realValue = {
            time_stamp: time,
            post_id_list: [],
          };
          try {
            realValue = JSON.parse(data);
          } catch (e) {
            //
          }
          if (postAction === 'like') {
            if (realValue.time_stamp === time) {
              // console.log(realValue.post_id_list.length);
              if (realValue.post_id_list.length === 4 && !(this.postInfo.id in realValue.post_id_list)) {
                realValue.post_id_list.push(this.postInfo.id); // 弹出任务，写入列表，完成任务，id列表长度为5
                this.getLikeMissionConfig()
                  .then(() => {
                    this.reportData({
                      pageid: '1407',
                      moduleid: '00027',
                      eventtype: 2,
                      extendid: 3,
                    }, '鼓掌任务完成提示弹窗曝光');
                    showTaskToast({
                      title: '完成5次鼓掌',
                      integral: this.likeMissionConfig.point,
                      experience: this.likeMissionConfig.exp,
                      addition: '',
                    });
                  });
              } else if (realValue.post_id_list.length < 4 && !(this.postInfo.id in realValue.post_id_list)) {
                // id列表小于4个才写入列表，一旦点赞过5个帖子，今天就完成任务，再点赞或者取消点赞都不对数组进行操作
                realValue.post_id_list.push(this.postInfo.id);
              }
            } else {
              realValue.time_stamp = time;
              realValue.post_id_list = [this.postInfo.id];
            }
          } else if (postAction === 'unlike') {
            if (realValue.time_stamp === time && realValue.post_id_list.length < 5) {
              // 当天id列表长度小于五的时候取消点赞才要记录，大于等于5就认为今天完成任务了，不需要再记录了
              for (let i = 0; i < realValue.post_id_list.length; i++) {
                if (realValue.post_id_list[i] === this.postInfo.id) {
                  realValue.post_id_list.splice(i, 1);
                  break;
                }
              }
            }
          }
          // curdData.post_id_list = [];
          curdData.value = JSON.stringify(realValue);
          curdData.action = 3;
          // console.log('set curdData ', curdData);
          sendCurdData(curdData);
        });
    },
    updateAction(action = {}) {
      this.$emit('update', {
        ...this.postAction,
        ...action,
      });
    },
    // 通知其他页面
    sendPostActionNotice({ operate, data }) {
      if (compareVersion('1.3') >= 0) {
        // 1.3版本支持全局通信
        sendGlobalNotification('g_updatePost', {
          operate,
          id: this.postInfo.id,
          data,
        });
      } else {
        // 通知hippy页面
        noticeSend({
          noticeId: 'HippyNotification',
          noticeEvent: 'webUpdatePost',
          noticeParams: {
            operate,
            id: this.postInfo.id,
            data,
          },
        }).then((res) => {
          this.$logger.info('发送hippy通知成功', res);
        });
      }
    },
    // 获取鼓掌管理端配置
    getLikeMissionConfig() {
      if (this.likeMissionConfig && Object.keys(this.likeMissionConfig) > 0) {
        return Promise.resolve();
      }
      return missionApi.getMissionConfig({
        type: 1,
      })
        .then((res) => {
          // console.log(`getMissionConfig: ${JSON.stringify(res)}`);
          this.likeMissionConfig = res.content;
        });
    },
    setInputTipsShowTimer() {
      const key = 'communityInputTips';
      const defaultData = { lastShowTime: 0 };
      const data = localStorage.getItem(key);
      const { lastShowTime } = (data && JSON.parse(data)) || defaultData;
      const dayTime = 1000 * 60 * 60 * 24;
      const isInHiddenTime = (Date.now() - lastShowTime) <= dayTime * 3;
      if (isInHiddenTime) {
        return;
      }

      this.inputTipsTimerId = setTimeout(() => {
        this.showInputTips = true;
        this.$emit('inputTipsShow');
        localStorage.setItem(key, JSON.stringify({
          lastShowTime: Date.now(),
        }));
        // 添加监听，点击页面任意地方，气泡消失
        document.addEventListener('click', this.handleInputTipsHide, true);
      }, 20 * 1000);
    },
    clearInputTipsShowTimer() {
      if (this.inputTipsTimerId) {
        clearTimeout(this.inputTipsTimerId);
      }
    },
  },
};
</script>
<style lang="scss" src="./scss/index.scss"></style>
